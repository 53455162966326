import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromFuncSpecStructure from './func-spec-structure-state.reducer';
import * as FuncSpecStructureSelectors from './func-spec-structure-state.selectors';

@Injectable()
export class FuncSpecStructureFacade {
  loaded$ = this.store.pipe(
    select(FuncSpecStructureSelectors.getFuncSpecStructureLoaded)
  );
  structureCanvasModel$ = this.store.pipe(
    select(FuncSpecStructureSelectors.getStructureCanvasModel)
  );

  getRootItemId$ = this.store.pipe(
    select(FuncSpecStructureSelectors.getRootItem)
  );

  selectedStructureItemInCanvas$ = this.store.pipe(
    select(FuncSpecStructureSelectors.getSelectedStructureItemInCanvas)
  );

  selectedStructureItemInCanvasItem$ = this.store.pipe(
    select(FuncSpecStructureSelectors.getSelectedStructureItemInCanvasItem)
  );

  selectedStructureItemInAddMode$ = this.store.pipe(
    select(FuncSpecStructureSelectors.getSelectedStructureItemInAddMode)
  );

  selectedStructureItemInAddModeItem$ = this.store.pipe(
    select(FuncSpecStructureSelectors.getSelectedStructureItemInAddModeItem)
  );

  selectedStructureItemInNavigation$ = this.store.pipe(
    select(FuncSpecStructureSelectors.getSelectedStructureItemInNavigation)
  );

  isAddingStructureToCanvas$ = this.store.pipe(
    select(FuncSpecStructureSelectors.getIsAddingStructureToCanvas)
  );

  highlightedItemInCanvasId$ = this.store.pipe(
    select(FuncSpecStructureSelectors.getHighlightedInCanvasItemId)
  );

  showProperties$ = this.store.pipe(
    select(FuncSpecStructureSelectors.getShowProperties)
  );

  pathFromSelectedToRoot$ = this.store.pipe(
    select(FuncSpecStructureSelectors.getPathFromSelectedToRoot)
  );

  isStructureRootItemExists$ = this.store.pipe(
    select(FuncSpecStructureSelectors.getIsStructureRootItemExists)
  );

  structureItem$ = (id: string) =>
    this.store.pipe(select(FuncSpecStructureSelectors.getStructureItem(id)));

  structureIsExpanded$ = (structureItemId: string) =>
    this.store.pipe(
      select(FuncSpecStructureSelectors.getStructureIsExpanded(structureItemId))
    );

  structureItemIsSelected$ = (structureItemId: string) =>
    this.store.pipe(
      select(
        FuncSpecStructureSelectors.getStructureItemIsSelected(structureItemId)
      )
    );

  structureItemSectionHighlighted$ = (structureItemId: string) =>
    this.store.pipe(
      select(
        FuncSpecStructureSelectors.getStructureItemSectionHighlighted(
          structureItemId
        )
      )
    );

  constructor(
    private store: Store<fromFuncSpecStructure.FuncSpecStructurePartialState>
  ) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }
}
