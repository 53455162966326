/* eslint-disable @typescript-eslint/naming-convention */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AuthConfig } from '@ama-studio/login';
import { EnvironmentConfig, IEnvironment } from '@ama-studio/shared';

fetch('./app.config.json')
  .then((response) => response.json())
  .then((config: IEnvironment) => {
    if (environment.production) {
      enableProdMode();
    }

    if (config.bannerMessage) {
      const bannerElement = document.createElement('div');
      bannerElement.classList.add('alert', 'alert-primary', 'mb-0');
      bannerElement.innerHTML = config.bannerMessage;
      document.body.prepend(bannerElement);
    }

    const authSettings = {
      scope: 'openid profile sb-ama-f userid sb-publicapi',
      client_id: 'amastudio',
      response_type: 'id_token token',
      silentRequestTimeout: 5000,
      automaticSilentRenew: true,

      authority: config.OIDC_AUTHORITY,
      redirect_uri: config.OIDC_REDIRECT_URI,
      post_logout_redirect_uri: config.OIDC_POST_LOGOUT_REDIRECT_URI,
      silent_redirect_uri: config.OIDC_SILENT_REDIRECT_URI,
    };

    platformBrowserDynamic([
      { provide: AuthConfig, useValue: authSettings },
      {
        provide: EnvironmentConfig,
        useValue: config.environmentConfig,
      },
    ])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  });
