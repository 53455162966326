<div class="user text-medium">
  <button
    class="btn dropdown-toggle d-flex align-items-center"
    type="button"
    data-bs-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    <ng-container
      [ngTemplateOutlet]="userDetails"
      [ngTemplateOutletContext]="{ displayEmail: false }"
    ></ng-container>
    <i class="material-icons fs-3">keyboard_arrow_down</i>
  </button>
  <div data-show class="card display-on-hover position-absolute">
    <div class="card-body">
      <span class="d-flex flex-row-reverse">
        {{ fullName$ | async }}
      </span>
      <span class="d-flex text-muted flex-row-reverse">
        {{ email$ | async }}
      </span>
    </div>
  </div>
  <ul class="dropdown-menu text-medium dropdown-menu-end w-auto pt-0">
    <ng-container
      [ngTemplateOutlet]="userDetails"
      [ngTemplateOutletContext]="{ displayEmail: true }"
    ></ng-container>
    <div class="dropdown-divider mx-3 h-25"></div>
      <li>
        <button
          class="dropdown-item ps-4"
          (click)="trackExport()"
          data-bs-toggle="modal"
          data-bs-target="#exportModal">
          Skapa PDF
        </button>
      </li>
      <div class="dropdown-divider mx-3 h-25"></div>
    <li>
      <a
        class="dropdown-item ps-4"
        href="https://byggtjanst.se/mina-sidor/installningar"
        >Mina uppgifter</a
      >
    </li>
    <li>
      <a
        class="dropdown-item ps-4"
        href="https://byggtjanst.se/mina-sidor/tj%C3%A4nster"
        >Mina tjänster</a
      >
    </li>
    <div class="dropdown-divider mx-3 mb-0"></div>
    <a class="dropdown-item text-center fw-bold pb-0" routerLink="/logout"
      >Logga ut</a
    >
  </ul>
</div>
<ng-template #userDetails let-displayEmail="displayEmail">
  <div
    class="d-flex flex-row"
    [ngClass]="{ ' mx-3 mt-4 mb-3': displayEmail }"
  >
    <div class="avatar">
      <div class="avatar-letters text-small">{{ initials$ | async }}</div>
    </div>
    <div class="d-flex flex-column ms-3 justify-content-center">
      <div>
        {{ fullName$ | async }}
      </div>
      <div
        *ngIf="displayEmail"
        class="text-muted text-small"
        [ngClass]="{ 'd-none': !displayEmail }"
      >
        {{ email$ | async }}
      </div>
    </div>
  </div>
</ng-template>
