import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { APINotifications } from '@ama-studio/shared';
import * as FuncSpecNotificationsActions from './func-spec-notifications.actions';

export const FUNCSPECNOTIFICATIONS_FEATURE_KEY = 'funcSpecNotifications';

export interface State extends EntityState<APINotifications.INotificationViewModel> {
  loaded: boolean;
  error?: string | null; // last known error (if any)
  unreadCount: number;
  selectedNotification: APINotifications.INotificationViewModel | null;
}

export interface FuncSpecNotificationsPartialState {
  readonly [FUNCSPECNOTIFICATIONS_FEATURE_KEY]: State;
}

export const funcSpecNotificationsAdapter: EntityAdapter<APINotifications.INotificationViewModel> =
  createEntityAdapter<APINotifications.INotificationViewModel>({
    sortComparer: (a, b) =>
      new Date(b.created).getTime() - new Date(a.created).getTime(),
  });

export const initialState: State = funcSpecNotificationsAdapter.getInitialState({
  loaded: false,
  error: null,
  unreadCount: 0,
  selectedNotification: null
});

const funcSpecNotificationsReducer = createReducer(
  initialState,
  on(FuncSpecNotificationsActions.clearState, () => initialState),
  on(FuncSpecNotificationsActions.loadUnreadCountSuccess, (state, { count }) => ({
    ...state,
    unreadCount: count
  })),
  on(FuncSpecNotificationsActions.loadUnreadCountFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(FuncSpecNotificationsActions.loadLatestNotificationsSuccess, (state, { notifications }) =>
    funcSpecNotificationsAdapter.upsertMany(notifications || [], {
      ...state,
      loaded: true,
      unreadCount: 0
    })
  ),
  on(FuncSpecNotificationsActions.loadLatestNotificationsFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(FuncSpecNotificationsActions.loadAllNotificationsSuccess, (state, { notifications }) =>
    funcSpecNotificationsAdapter.setAll(notifications || [], {
      ...state,
      loaded: true,
      unreadCount: 0
    })
  ),
  on(FuncSpecNotificationsActions.loadAllNotificationsFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(FuncSpecNotificationsActions.markNotificationsAsReadSuccess, (state) =>
    funcSpecNotificationsAdapter.map(n => ({ ...n, read: true }), {
      ...state,
      loaded: true
    })
  ),
  on(FuncSpecNotificationsActions.markNotificationsAsReadFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(FuncSpecNotificationsActions.selectNotification, (state, { notification }) => ({
    ...state,
    selectedNotification: notification
  })),
  on(FuncSpecNotificationsActions.clearSelectedNotification, (state) => ({
    ...state,
    selectedNotification: null
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return funcSpecNotificationsReducer(state, action);
}
