import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import {
  map,
  switchMap,
  withLatestFrom,
  filter,
  mergeMap,
} from 'rxjs/operators';

import * as fromFuncSpecCore from './func-spec-core.reducer';
import * as FuncSpecCoreActions from './func-spec-core.actions';
import { Store, select } from '@ngrx/store';
import {
  getFuncSpecCoreState,
  getFunctionalSpecificationId,
} from './func-spec-core.selectors';
import {
  selectChapter,
  loadChapters,
  loadChaptersSuccess,
} from '@ama-studio/func-spec-chapter-state';
import { LicenseService } from '@ama-studio/shared';

import { setFuncSpecId } from '@ama-studio/func-spec-invite';
import { FuncSpecCoreService } from '../func-spec-core.service';
import { fetch } from '@nrwl/angular';
import { setHasLicenseToRequirementTemplate } from './func-spec-core.actions';
import { HttpErrorResponse } from '@angular/common/http';
import {
  removeAllToastMessages,
} from '@ama-studio/toast-notifications';

@Injectable()
export class FuncSpecCoreEffects {
  loadDataOnNavigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecCoreActions.onNavigation),
      withLatestFrom(this.store.pipe(select(getFuncSpecCoreState))),
      // Only load if chapter or func spec changed.
      filter(
        ([action, state]) =>
          action.functionalSpecificationId !==
            state.functionalSpecificationId ||
          action.chapterId !== state.chapterId
      ),
      switchMap(([action, state]) => [
        FuncSpecCoreActions.setFuncSpec({
          functionalSpecificationId: action.functionalSpecificationId,
        }),
        FuncSpecCoreActions.setChapter({
          chapterId: action.chapterId,
          chapterNumber: state.chapterNumber,
          chapterType: action.chapterType,
        }),

        FuncSpecCoreActions.loadFuncSpec(),

        loadChapters({
          funcSpecId: action.functionalSpecificationId,
        }),

        selectChapter({
          chapterId: action.chapterId,
          chapterNumber: action.chapterNumber,
          funcSpecId: action.functionalSpecificationId,
          chapterType: action.chapterType,
        }),
        setFuncSpecId({ funcSpecId: action.functionalSpecificationId }),
      ])
    )
  );

  setFuncSpecIdOnChapterSload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadChapters),
      switchMap((action) => [
        FuncSpecCoreActions.setFuncSpec({
          functionalSpecificationId: action.funcSpecId,
        }),
      ])
    )
  );

  loadFuncSpec$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecCoreActions.loadFuncSpec),
      withLatestFrom(this.store.pipe(select(getFunctionalSpecificationId))),
      fetch({
        run: (action, funcSpecId: string) => {
          if (!funcSpecId) throw new Error('Argument cannot be null');

          return this.funcSpecCoreService.getFuncSpec(funcSpecId).pipe(
            switchMap((funcSpec) => [
              FuncSpecCoreActions.loadFuncSpecSuccess({
                funcSpec,
              }),
              FuncSpecCoreActions.setProjectId({
                projectId: funcSpec.projectId,
              }),
              FuncSpecCoreActions.loadProject({
                projectId: funcSpec.projectId,
              }),
            ])
          );
        },
        onError: (action, error: HttpErrorResponse | null) =>
          FuncSpecCoreActions.loadFuncSpecFailure({
            error: error?.message,
          }),
      })
    )
  );

  loadProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecCoreActions.loadProject),
      fetch({
        run: (action) =>
          this.funcSpecCoreService.getProject(action.projectId).pipe(
            switchMap((projectModel) => [
              FuncSpecCoreActions.loadProjectSuccess({
                projectModel,
              }),
            ])
          ),
        onError: (action, error: HttpErrorResponse | null) => {
          console.error('Error', error);
          return FuncSpecCoreActions.loadProjectFailure({
            error: error?.message,
          });
        },
      })
    )
  );

  setRequirementTemplateId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadChaptersSuccess),
      switchMap((action) => [
        FuncSpecCoreActions.setRequirementTemplate({
          requirementTemplateId: action.overviewModel.requirementTemplateId,
        }),
        FuncSpecCoreActions.setHasLicenseToRequirementTemplate({
          requirementTemplateId: action.overviewModel.requirementTemplateId,
        }),
      ])
    )
  );

  setHasLicenseToRequirementTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setHasLicenseToRequirementTemplate),
      fetch({
        run: (action) =>
          this.licenseService
            .hasRequirementTemplateLicens(action.requirementTemplateId)
            .pipe(
              map((hasLicenseToRequirementTemplate) =>
                FuncSpecCoreActions.setHasLicenseToRequirementTemplateSuccess({
                  hasLicenseToRequirementTemplate,
                })
              )
            ),
        onError: (action, error: HttpErrorResponse | null) =>
          FuncSpecCoreActions.setHasLicenseToRequirementTemplateFailure({
            error: error?.message,
          }),
      })
    )
  );

  leavingFuncSpecViewRemovesAllMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecCoreActions.leavingFuncSpecView),
      mergeMap(() => [removeAllToastMessages()])
    )
  );

  inFuncSpecViewRemovesAllMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecCoreActions.inFuncSpecView),
      mergeMap(() => [removeAllToastMessages()])
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<fromFuncSpecCore.FuncSpecCorePartialState>,
    private funcSpecCoreService: FuncSpecCoreService,
    private licenseService: LicenseService
  ) {}
}
