<div #dropdown>
  <div data-bs-toggle="dropdown">
    <ama-studio-func-spec-notifications-icon>
    </ama-studio-func-spec-notifications-icon>
  </div>
  <div class="dropdown-menu">
    <ama-studio-func-spec-notifications-list>
    </ama-studio-func-spec-notifications-list>
  </div>
</div>
