import { createReducer, on, Action } from '@ngrx/store';

import * as ToastActions from './toast.actions';
import { IToastErrorMessage, IToastMessage } from '../interfaces';

export const TOAST_NOTIFICATIONS_FEATURE_KEY = 'toast-notifications';

export interface State {
  message: IToastMessage | null;
  errorMessage: IToastErrorMessage | null;
}

export interface ToastNotificationsPartialState {
  readonly [TOAST_NOTIFICATIONS_FEATURE_KEY]: State;
}

export const initialState: State = {
  message: null,
  errorMessage: null,
};

const toastReducer = createReducer(
  initialState,
  on(ToastActions.showToastMessage, (state, { message }) => ({
    ...state,
    message,
  })),
  on(ToastActions.removeToastMessage, (state) => ({
    ...state,
    message: null,
  })),
  on(ToastActions.removeAllToastMessages, () => ({
    message: null,
    errorMessage: null,
  })),
  on(ToastActions.showToastError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  })),
  on(ToastActions.removeToastError, (state) => ({
    ...state,
    errorMessage: null,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return toastReducer(state, action);
}
