import { Injectable } from '@angular/core';
import {
  APIChapters,
  EnvironmentConfig,
  BaseHttpService,
} from '@ama-studio/shared';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ChaptersService extends BaseHttpService {
  constructor(http: HttpClient, envConfig: EnvironmentConfig) {
    super(http, envConfig);
  }

  apiUrl = `${this.envConfig.baseApiUrl}/functionalspecifications`;

  getChaptersOverviewModelUrl(funcSpecId: string) {
    return `${this.apiUrl}/${funcSpecId}/chapters/overview`;
  }

  public getChaptersOverviewModel(funcSpecId: string) {
    return this.http.get<APIChapters.IRequirementTemplateTextsOverviewViewModel>(
      this.getChaptersOverviewModelUrl(funcSpecId),
      this.headers
    );
  }
}
