<button
  type="button"
  data-bs-dismiss="modal"
  class="btn btn-outline-primary btn-submit fw-bold"
  [ngClass]="
    chapterType === enumChapterType.Structure
      ? 'ms-2.5 btn-structure-export'
      : 'mt-3'
  "
  [disabled]="chapterType === enumChapterType.Structure && !structureItemId"
  (click)="export()"
>
  Exportera
</button>
