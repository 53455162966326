<div
  [class.show]="isVisible ? 'show' : null"
  class="modal fade modal--feedback-form"
  id="feedback-form"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
  data-bs-backdrop="false"
>
  <div
    class="modal-dialog modal-dialog-bottom modal-dialog--feedback-form"
    #dialog
  >
    <div class="modal-content modal-content--feedback-form">
      <div class="modal-header modal-header--feedback-form">
        <div class="d-flex align-items-start">
          <button
            class="btn btn-link btn-link--feedback-back"
            type="button"
            (click)="handleClose($event)"
          >
            <span class="material-icons">keyboard_arrow_left</span>
          </button>
          <div class="d-flex flex-column">
            <h3 class="modal-title modal-title--feedback-form">AMA Funktion</h3>
            <p class="modal-header__text">
              Hjälp oss förbättra AMA Funktion genom att lämna dina synpunkter
            </p>
          </div>
        </div>
      </div>
      <div class="modal-body modal-body--feedback-form">
        <div class="feedback-created">
          <div
            *ngIf="feedbackState$ | async as feedbackState"
            class="feedback-submit-container"
          >
            <p *ngIf="feedbackState.feedback">
              {{ feedbackState.feedback.description }}
            </p>
            <div
              *ngIf="
                feedbackState.feedback &&
                !feedbackState?.error &&
                !feedbackState.showSpinner
              "
              class="feedback-submit-container__status status--success"
            >
              <span class="material-icons">check_circle_outline</span>
              <span className="feedback-submit-container__status__text">
                Skickat
              </span>
            </div>
            <div
              [style.display]="
                feedbackState?.error && !feedbackState.showSpinner
                  ? 'flex'
                  : 'none'
              "
              class="feedback-submit-container__status status--error"
            >
              <span class="material-icons">error_outline_circle</span>
              <span class="feedback-submit-container__status__text"
                >Kunde inte skickas,
                <button
                  class="btn btn-link btn-link--error"
                  type="button"
                  (click)="
                    onSubmit({ message: feedbackState?.feedback.description })
                  "
                >
                  Försök igen
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer modal-footer--feedback-form">
        <div class="feedback-form-container">
          <form #form="ngForm" (ngSubmit)="onSubmit(form.value)">
            <fieldset>
              <div class="form-group">
                <textarea
                  #textarea
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="3"
                  [(ngModel)]="message"
                  class="form-control form-control form-control--feedback-form"
                  rows="1"
                  name="message"
                  required
                >
                </textarea>
              </div>
            </fieldset>
            <div class="feedback-submit">
              <button
                class="btn btn-link btn-link--feedback-form"
                type="submit"
                [disabled]="form.invalid"
              >
                <span class="material-icons">send</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
