import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RelatedItemComponent } from './related-item/related-item.component';

@NgModule({
  imports: [CommonModule],
  declarations: [RelatedItemComponent],
  exports: [RelatedItemComponent],
})
export class RelatedItemModule {}
