import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromGlobalState from './global-state.reducer';
import * as GlobalStateSelectors from './global-state.selectors';

@Injectable()
export class GlobalStateFacade {
  mainTitle$ = this.store.pipe(select(GlobalStateSelectors.getMainTitle));
  activeSection$ = this.store.pipe(
    select(GlobalStateSelectors.getActiveSection)
  );
  sideMenuCollapsed$ = this.store.pipe(
    select(GlobalStateSelectors.getsideMenuCollapsed)
  );

  constructor(private store: Store<fromGlobalState.GlobalStatePartialState>) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }
}
