import { fetch } from '@nrwl/angular';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { map, switchMap, withLatestFrom } from 'rxjs/operators';

import * as AuthActions from './auth.actions';
import { LicenseService } from '@ama-studio/shared';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { select, Store } from '@ngrx/store';
import { FuncSpecCorePartialState, getFunctionalSpecificationId } from '@ama-studio/func-spec-core-state'

@Injectable()
export class AuthEffects {
  checkHasValidLicense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.checkHasValidLicense),
      fetch({
        run: (action) =>
          this.licenseService
            .hasLicense()
            .pipe(
              switchMap((hasValidLicense) => [
                AuthActions.setHasValidLicense({ hasValidLicense }),
                AuthActions.getCurrentParticipantRole()
              ])
            ),
        onError: (action, error: HttpErrorResponse | null) => {
          /**
           * Don't return anything on error.
           * LicenseGuard will show alert if user is missing license,
           * but that message should not be shown if the API fails for some reason.
           * So instead show an alert here.
           */
          alert(
            'Något har gått fel. Vänta en stund och prova igen, eller kontakta kundservice.'
          );
        },
      })
    )
  );

  getCurrentParticipantRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getCurrentParticipantRole),
      withLatestFrom(this.coreStore.pipe(select(getFunctionalSpecificationId))),
      fetch({
        run: (_, funcSpecId) =>
          this.authService
            .getCurrentParticipantRole(funcSpecId)
            .pipe(
              map(role => AuthActions.getCurrentParticipantRoleSuccess({ role }))
            ),
        onError: (_, error: HttpErrorResponse | null) => {
          console.error('Error', error);
          return AuthActions.getCurrentParticipantRoleFailure({
            error: error?.message || ""
          });
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private coreStore: Store<FuncSpecCorePartialState>,
    private licenseService: LicenseService,
    private authService: AuthService
  ) {}
}
