<li class="nav-item h-100">
  <a
    class="text-decoration-none flex-fill"
    [routerLink]="getRouteLink(chapter) | async"
    [class.selected]="chapterIdIsSelected$(chapter.id) | async"
    [title]="chapter.combinedTitle"
  >
    <ng-content></ng-content>
  </a>
</li>
