import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  LoginModule,
  loginRoutes,
  LoginGuard,
  LicenseGuard,
} from '@ama-studio/login';

import { StartComponent } from '@ama-studio/start';

import { FuncSpecActivateGuard } from './guards/funcspec-activate.guard';
import { FuncSpecDeactivateGuard } from './guards/funcspec-deactivate.guard';
import { MainComponent } from './layout/main.component';
import { ChaptersResolver } from '@ama-studio/func-spec-chapter-state';
import { MaintenanceModeGuard } from './guards/maintenance-mode.guard';

const routes: Routes = [
  ...loginRoutes,
  {
    path: '',
    component: StartComponent,
    canActivate: [LoginGuard, LicenseGuard],
  },
  {
    path: 'maintenance',
    loadChildren: () =>
      import('@ama-studio/maintenance-mode').then(
        (m) => m.MaintenanceModeModule
      )
  },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'projects/:projectId/create',
        canActivate: [LoginGuard, LicenseGuard, MaintenanceModeGuard],

        loadChildren: () =>
          import('@ama-studio/func-spec-create').then(
            (m) => m.FuncSpecCreateOldModule
          ),
      },
      {
        path: 'overview/:id',
        canActivate: [LoginGuard, LicenseGuard, MaintenanceModeGuard],
        resolve: { chapters: ChaptersResolver },

        loadChildren: () =>
          import('@ama-studio/func-spec-overview').then(
            (m) => m.FuncSpecOverviewModule
          ),
      },
      {
        path: 'functionalspecifications/:funcSpecId/chapters/:chapterNumber/:chapterId/:chapterType',
        canActivate: [LoginGuard, LicenseGuard, FuncSpecActivateGuard, MaintenanceModeGuard],
        canDeactivate: [FuncSpecDeactivateGuard],
        resolve: { chapters: ChaptersResolver },

        loadChildren: () =>
          import('@ama-studio/func-spec-container').then(
            (m) => m.FuncSpecContainerModule
          ),
      },
      {
        path: 'revision/:id',
        canActivate: [LoginGuard, LicenseGuard, FuncSpecActivateGuard, MaintenanceModeGuard],
        resolve: { chapters: ChaptersResolver },

        loadChildren: () =>
          import('@ama-studio/func-spec-revision').then(
            (m) => m.FuncSpecRevisionModule
          ),
      },
    ],
  },
  {
    path: 'projects/:projectId/create/new',
    canActivate: [LoginGuard, LicenseGuard, MaintenanceModeGuard],

    loadChildren: () =>
      import('@ama-studio/func-spec-create').then(
        (m) => m.FuncSpecCreateModule
      ),
  },
  {
    path: '**',
    component: StartComponent,
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes), LoginModule],
  exports: [RouterModule],
})

export class AppRoutingModule {}
