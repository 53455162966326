import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { map, switchMap, withLatestFrom, delay, filter } from 'rxjs/operators';

import * as FuncSpecNotificationsActions from './func-spec-notifications.actions';

import * as FuncSpecCore from '@ama-studio/func-spec-core-state';

import { select, Store } from '@ngrx/store'
import { FuncSpecNotificationsService } from '../../func-spec-notifications.service'
import { fetch } from '@nrwl/angular';
import { HttpErrorResponse } from '@angular/common/http';
import { selectChapter } from '@ama-studio/func-spec-chapter-state';
import { selectCommentItem } from '@ama-studio/func-spec-comments';
import { getChapterId, getFunctionalSpecificationId } from '@ama-studio/func-spec-core-state';
import { Router } from '@angular/router';
import { ChapterType } from '@ama-studio/shared';
import { getSelectedNotification } from './func-spec-notifications.selectors'
import { FuncSpecNotificationsPartialState } from './func-spec-notifications.reducer';
import { loadTextsSuccess } from '@ama-studio/func-spec-text-state';
import { loadStructureCanvasSuccess } from '@ama-studio/func-spec-structure-state';
import { toggleCommentPanel } from '@ama-studio/func-spec-comments';

@Injectable()
export class FuncSpecNotificationsEffects {

  onSelectChapter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectChapter),
      map(() => FuncSpecNotificationsActions.loadUnreadCount())
    )
  );

  loadUnreadCount$ = createEffect(() =>
  this.actions$.pipe(
    ofType(FuncSpecNotificationsActions.loadUnreadCount),
    withLatestFrom(
      this.coreStore.pipe(select(FuncSpecCore.getFunctionalSpecificationId))
    ),
    fetch({
      run: (_, funcSpecId) =>
        this.notificationsService
          .getUnreadNotificationsCount(funcSpecId)
          .pipe(
            map(response =>
              FuncSpecNotificationsActions.loadUnreadCountSuccess({
                count: response.total
              })
            )
          ),
      onError: (_, error: HttpErrorResponse | null) => {
        console.error('Error', error);
        FuncSpecNotificationsActions.loadUnreadCountFailure({
          error: error?.message || ""
        });
      },
    })
  )
);

  loadLatestNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecNotificationsActions.loadLatestNotifications),
      withLatestFrom(
        this.coreStore.pipe(select(FuncSpecCore.getFunctionalSpecificationId))
      ),
      fetch({
        run: (_, funcSpecId) =>
          this.notificationsService.getLatestNotifications(funcSpecId)
          .pipe(
            switchMap(response => [
              FuncSpecNotificationsActions.loadLatestNotificationsSuccess({
                notifications: response.notifications,
              }),
              FuncSpecNotificationsActions.markNotificationsAsRead()
            ])
          ),
          onError: (_, error: HttpErrorResponse | null) => {
            console.error('Error', error);
            FuncSpecNotificationsActions.loadLatestNotificationsFailure({
              error: error?.message || ""
            });
          },
      })
    )
  );

  loadAllNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecNotificationsActions.loadAllNotifications),
      withLatestFrom(
        this.coreStore.pipe(select(FuncSpecCore.getFunctionalSpecificationId))
      ),
      fetch({
        run: (_, funcSpecId) =>
          this.notificationsService.getAllNotifications(funcSpecId)
          .pipe(
            switchMap(response => [
              FuncSpecNotificationsActions.loadAllNotificationsSuccess({
                notifications: response.notifications,
              }),
              FuncSpecNotificationsActions.markNotificationsAsRead()
            ])
          ),
          onError: (_, error: HttpErrorResponse | null) => {
            console.error('Error', error);
            FuncSpecNotificationsActions.loadAllNotificationsFailure({
              error: error?.message || ""
            });
          },
      })
    )
  );

  markNotificationsAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecNotificationsActions.markNotificationsAsRead),
      withLatestFrom(
        this.coreStore.pipe(select(FuncSpecCore.getFunctionalSpecificationId))
      ),
      fetch({
        run: (_, funcSpecId) =>
          this.notificationsService.markNotificationsAsRead(funcSpecId)
          .pipe(
            delay(5000),
            map(() =>
              FuncSpecNotificationsActions.markNotificationsAsReadSuccess()
            )
          ),
          onError: (_, error: HttpErrorResponse | null) => {
            console.error('Error', error);
            FuncSpecNotificationsActions.markNotificationsAsReadFailure({
              error: error?.message || ""
            });
          }
      })
    )
  );

  selectNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecNotificationsActions.selectNotification),
      withLatestFrom(
        this.coreStore.pipe(select(getFunctionalSpecificationId)),
        this.coreStore.pipe(select(getChapterId))
      ),
      switchMap(([ { notification }, id, currentChapter ]) => {

        // Toggle comment if we are on same chapter
        if (notification.chapterId === currentChapter) {
          return [
            toggleCommentPanel({ collapsed: false }),
            selectCommentItem({ comment: notification })
          ];
        }

        // Otherwise reroute to correct chapter and use the next effect
        if (!notification.chapterId) {
          this.router.navigate(['overview', id]);
          // Clear notification if going to a non-chapter type
          return [
            FuncSpecNotificationsActions.clearSelectedNotification()
          ];
        } else {
          this.router.navigate([
            'functionalspecifications',
            id,
            'chapters',
            notification.chapterNumber,
            notification.chapterId,
            ChapterType[notification.chapterType].toLowerCase()
          ]);
          return [];
        }
      })
    )
  );

  openCommentOnLoadFuncSpecSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadStructureCanvasSuccess, loadTextsSuccess),
      withLatestFrom(
        this.store.pipe(select(getSelectedNotification))
      ),
      filter(([, notification]) => !!notification),
      switchMap(([, notification]) => [
        selectCommentItem({ comment: notification }),
        toggleCommentPanel({ collapsed: false }),
        FuncSpecNotificationsActions.clearSelectedNotification()
      ])
    )
  );

  constructor(
    private actions$: Actions,
    private notificationsService: FuncSpecNotificationsService,
    private store: Store<FuncSpecNotificationsPartialState>,
    private router: Router,
    private coreStore: Store<FuncSpecCore.FuncSpecCorePartialState>
  ) {}

}
