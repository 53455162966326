import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ToastFacade } from './+state/toast.facade';
import { showToastError, showToastMessage } from './+state/toast.actions';

@Injectable()
export class ToastNotificationsErrorInterceptor implements HttpInterceptor {
  constructor(public router: Router, public toastFacade: ToastFacade) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            case 401:
              this.toastFacade.dispatch(
                showToastMessage({
                  message: {
                    message: 'Behörighet saknas.',
                    type: 'warning',
                  },
                })
              );
              break;
            case 403:
              this.toastFacade.dispatch(
                showToastMessage({
                  message: {
                    message:
                      'Behörighet saknas, kontakta projektägaren för hjälp.',
                    type: 'warning',
                  },
                })
              );
              break;
            case 404:
            case 500:
              this.toastFacade.dispatch(
                showToastError({
                  errorMessage: {
                    message: 'Något gick fel. Vänligen försök igen.',
                    type: 'error',
                  },
                })
              );
              break;
          }
        }

        return throwError(err);
      })
    );
  }
}
