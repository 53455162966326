import { createAction, props } from '@ngrx/store';
import { APIStructureProperties } from '@ama-studio/shared';

export const clearState = createAction(
  '[FuncSpecStructureProperties] Clear State'
);

export const loadStructureProperties = createAction(
  '[FuncSpecStructureProperties] Load StructureProperties'
);

export const loadStructurePropertiesSuccess = createAction(
  '[FuncSpecStructureProperties] Load StructureProperties Success',
  props<{
    propertyModel: APIStructureProperties.PropertyContainerViewModel;
    propertiesId: string;
  }>()
);

export const loadStructurePropertiesFailure = createAction(
  '[FuncSpecStructureProperties] Load StructureProperties Failure',
  props<{ error: string | null }>()
);

export const togglePropertyActive = createAction(
  '[FuncSpecStructureProperties] Toggle Property Active',
  props<{
    property: APIStructureProperties.IPropertyViewModel;
    isActive: boolean;
  }>()
);

export const togglePropertyActiveSuccess = createAction(
  '[FuncSpecStructureProperties] Toggle Property Active Success',
  props<{
    updatedProperty: APIStructureProperties.IPropertyViewModel;
  }>()
);

export const togglePropertyActiveFailure = createAction(
  '[FuncSpecStructureProperties] Toggle Property Active Failure',
  props<{
    error: string | null;
    property: APIStructureProperties.IPropertyViewModel;
  }>()
);

export const selectedPropertyItem = createAction(
  '[FuncSpecStructureProperties] Selected Property Item',
  props<{
    selectedPropertyItem: APIStructureProperties.IPropertyViewModel;
  }>()
);

export const expandPropertyItem = createAction(
  '[FuncSpecStructureProperties] Expand Property Item',
  props<{
    expandedId: string;
  }>()
);

export const collapsePropertyItem = createAction(
  '[FuncSpecStructureProperties] Collapse Property Item'
);

export const addCommentToSelectedProperty = createAction(
  '[FuncSpecStructureProperties] Add Comment To Selected Property'
);

export const subtractCommentCount = createAction(
  '[FuncSpecStructureProperties] Subtract Comment Count',
  props<{
    propertyId: string;
  }>()
);

export const highlightPropertySection = createAction(
  '[FuncSpecStructureProperties] Highlight Property Section',
  props<{
    propertyId: string;
    section: APIStructureProperties.IPropertySection;
  }>()
);

export const unHighlightPropertySection = createAction(
  '[FuncSpecStructureProperties] UnHighlight Property Section',
  props<{
    propertyId: string;
  }>()
);

export const openRequirements = createAction(
  '[FuncSpecStructureProperties] Open Requirements',
  props<{ active: boolean }>()
);
