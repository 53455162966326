import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromFuncSpecStatus from './func-spec-status.reducer';
import * as FuncSpecStatusSelectors from './func-spec-status.selectors';

@Injectable()
export class FuncSpecStatusFacade {

  status$ = this.store.pipe(
    select(FuncSpecStatusSelectors.getFuncSpecStatus)
  );

  error$ = this.store.pipe(
    select(FuncSpecStatusSelectors.getFuncSpecStatusError)
  );

  readyForReviewResult$ = this.store.pipe(
    select(FuncSpecStatusSelectors.getReadyForReviewResult)
  );

  showBanner$ = this.store.pipe(
    select(FuncSpecStatusSelectors.getShowBanner)
  );

  isLocked$ = this.store.pipe(
    select(FuncSpecStatusSelectors.getIsLocked)
  );

  confirmationStatus$ = this.store.pipe(
    select(FuncSpecStatusSelectors.getConfirmationStatus)
  );

  isApproved$ = this.store.pipe(
    select(FuncSpecStatusSelectors.getIsApproved)
  );

  constructor(
    private store: Store<fromFuncSpecStatus.FuncSpecStatusPartialState>
  ) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }
}
