import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserMessageComponent } from './user-message-component/user-message.component';

@NgModule({
  declarations: [UserMessageComponent],
  imports: [CommonModule],
  exports: [UserMessageComponent],
})
export class UserMessageModule {}
