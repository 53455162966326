import { Injectable } from '@angular/core';
import {
  APIFunctionalSpecifications,
  EnvironmentConfig,
  BaseHttpService,
  APIProjects,
} from '@ama-studio/shared';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FuncSpecOverviewService extends BaseHttpService {
  constructor(http: HttpClient, envConfig: EnvironmentConfig) {
    super(http, envConfig);
  }

  private apiUrl = `${this.envConfig.baseApiUrl}/functionalspecifications`;
  private projectUrl = `${this.envConfig.baseApiUrl}/projects`;

  private getFuncSpecOverviewUrl(funcSpecId: string) {
    return `${this.apiUrl}/${funcSpecId}`;
  }

  private getParticipantsURL(fsId: string): string {
    return `${this.apiUrl}/${fsId}/participants/v2`;
  }

  private getUpdateFuncSpecUrl(fsId: string) {
    return `${this.apiUrl}/${fsId}`;
  }

  private getDeleteFuncSpecUrl(funcSpecId: string) {
    return `${this.apiUrl}/${funcSpecId}`;
  }

  private getProjectUrl(projectId: string) {
    return `${this.projectUrl}/${projectId}`;
  }

  public getFuncSpecOverview(funcSpecId: string) {
    return this.http.get<APIFunctionalSpecifications.IFunctionalSpecificationOverviewContainerViewModel>(
      this.getFuncSpecOverviewUrl(funcSpecId),
      this.headers
    );
  }

  public getParticipants(fsId: string) {
    return this.http.get<APIFunctionalSpecifications.IParticipantListViewModel>(
      this.getParticipantsURL(fsId),
      this.headers
    );
  }

  public updateFunctionSpecificationDetail(
    updateCommand: APIFunctionalSpecifications.IPatchFunctionalSpecificationCommand
  ) {
    return this.http.patch<APIFunctionalSpecifications.IFunctionalSpecificationOverviewContainerViewModel>(
      this.getUpdateFuncSpecUrl(updateCommand.id),
      { ...updateCommand },
      this.headers
    );
  }

  public getProject(projectId: string) {
    return this.http.get<APIProjects.IProjectViewModel>(
      this.getProjectUrl(projectId),
      this.headers
    );
  }

  public delete(
    deleteCommand: APIFunctionalSpecifications.IDeleteFunctionalSpecificationCommand
  ) {
    return this.http.delete<APIFunctionalSpecifications.IFunctionalSpecificationOverviewContainerViewModel>(
      this.getDeleteFuncSpecUrl(deleteCommand.id),
      this.headers
    );
  }
}
