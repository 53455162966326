import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserInitialsPipe } from './user-initials.pipe';

@NgModule({
  imports: [CommonModule],
  exports: [UserInitialsPipe],
  declarations: [UserInitialsPipe],
})
export class UserInitialsModule {}
