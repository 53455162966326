<div class="container text-center mt-4">
  <div class="m-auto">
    <p *ngIf="statusMsg" class="mt-8">{{ statusMsg }}</p>
    <p
      *ngIf="showLoginLink"
      class="text-center text-green-700 underline mt-8 text-lg"
    >
      <a routerLink="/login"> Logga in </a>
    </p>
  </div>
</div>
