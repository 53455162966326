import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as FuncSpecStatusActions from './func-spec-status.actions';
import { FuncSpecStatusService } from '../../func-spec-status.service';
import { HttpErrorResponse } from '@angular/common/http';
import { delay, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { fetch } from '@nrwl/angular';
import { select, Store } from '@ngrx/store';
import {
  FuncSpecCorePartialState,
  getFunctionalSpecificationId,
} from '@ama-studio/func-spec-core-state';
import { loadFuncSpecOverviewSuccess } from '@ama-studio/func-spec-overview-state';
import { FuncSpecStatusPartialState } from './func-spec-status.reducer';
import { APIStatus } from '@ama-studio/shared';
import { getFuncSpecStatus } from './func-spec-status.selectors';

@Injectable()
export class FuncSpecStatusEffects {
  onUpdateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecStatusActions.updateStatus),
      switchMap((action) =>
        action.showBanner ? [FuncSpecStatusActions.showBanner()] : []
      )
    )
  );

  onShowBanner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecStatusActions.showBanner),
      delay(5000),
      map(() => FuncSpecStatusActions.hideBanner())
    )
  );

  loadFuncSpecOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFuncSpecOverviewSuccess),
      withLatestFrom(this.store.pipe(select(getFuncSpecStatus))),
      switchMap(([result, status]) => [
        // Show banner only on first load and if status is not Under Arbete
        FuncSpecStatusActions.updateStatus({
          status: result.functionalSpecification.status,
          showBanner:
            result.functionalSpecification.status !==
              status &&
            result.functionalSpecification.status !==
              APIStatus.Status.InProgress &&
            result.functionalSpecification.status !==
              APIStatus.Status.RevisionInProgress,
        }),
      ])
    )
  );

  readyForReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecStatusActions.readyForReview),
      withLatestFrom(this.coreStore.pipe(select(getFunctionalSpecificationId))),
      fetch({
        run: (action, funcSpecId) =>
          this.statusService
            .readyForReview({ id: funcSpecId, userId: action.userId })
            .pipe(
              switchMap((status) => [
                FuncSpecStatusActions.updateStatus({
                  status,
                  showBanner: true,
                }),
                FuncSpecStatusActions.readyForReviewSuccess(),
              ])
            ),
        onError: (_, error: HttpErrorResponse | null) => {
          console.error('Error', error);
          FuncSpecStatusActions.readyForReviewFailure({
            error: error?.message || '',
          });
        },
      })
    )
  );

  approve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecStatusActions.approve),
      withLatestFrom(this.coreStore.pipe(select(getFunctionalSpecificationId))),
      fetch({
        run: (_, funcSpecId) =>
          this.statusService.approve(funcSpecId).pipe(
            switchMap((status) => [
              FuncSpecStatusActions.updateStatus({
                status,
                showBanner: true,
              }),
            ])
          ),
        onError: (_, error: HttpErrorResponse | null) => {
          console.error('Error', error);
          FuncSpecStatusActions.approveFailure({
            error: error?.message || '',
          });
        },
      })
    )
  );

  disapprove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecStatusActions.disapprove),
      withLatestFrom(this.coreStore.pipe(select(getFunctionalSpecificationId))),
      fetch({
        run: (_, funcSpecId) =>
          this.statusService.disapprove(funcSpecId).pipe(
            switchMap((status) => [
              FuncSpecStatusActions.updateStatus({
                status,
                showBanner: true,
              }),
            ])
          ),
        onError: (_, error: HttpErrorResponse | null) => {
          console.error('Error', error);
          FuncSpecStatusActions.disapproveFailure({
            error: error?.message || '',
          });
        },
      })
    )
  );

  inProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecStatusActions.inProgress),
      withLatestFrom(this.coreStore.pipe(select(getFunctionalSpecificationId))),
      fetch({
        run: (_, funcSpecId) =>
          this.statusService.inProgress(funcSpecId).pipe(
            switchMap((status) => [
              FuncSpecStatusActions.updateStatus({
                status,
                showBanner: false,
              }),
            ])
          ),
        onError: (_, error: HttpErrorResponse | null) => {
          console.error('Error', error);
          FuncSpecStatusActions.inProgressFailure({
            error: error?.message || '',
          });
        },
      })
    )
  );

  cancelApproval$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecStatusActions.cancelApproval),
      withLatestFrom(this.coreStore.pipe(select(getFunctionalSpecificationId))),
      fetch({
        run: (_, funcSpecId) =>
          this.statusService.cancelApproval(funcSpecId).pipe(
            switchMap((status) => [
              FuncSpecStatusActions.updateStatus({
                status,
                showBanner: false
              })
            ])
          ),
        onError: (_, error: HttpErrorResponse | null) => {
          console.error('Error', error);
          FuncSpecStatusActions.cancelApprovalFailure({
            error: error?.message || '',
          });
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private statusService: FuncSpecStatusService,
    private store: Store<FuncSpecStatusPartialState>,
    private coreStore: Store<FuncSpecCorePartialState>
  ) {}
}
