import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  GLOBALSTATE_FEATURE_KEY,
  State,
  GlobalStatePartialState,
} from './global-state.reducer';

// Lookup the 'GlobalState' feature state managed by NgRx
export const getGlobalStateState = createFeatureSelector<
  State
>(GLOBALSTATE_FEATURE_KEY);

export const getMainTitle = createSelector(
  getGlobalStateState,
  (state: State) => state.mainTitle
);

export const getActiveSection = createSelector(
  getGlobalStateState,
  (state: State) => state.activeSection
);

export const getsideMenuCollapsed = createSelector(
  getGlobalStateState,
  (state: State) => state.sideMenuCollapsed
);
