import { APIFunctionalSpecifications } from '@ama-studio/shared';
import { createAction, props } from '@ngrx/store';

export const clearState = createAction('[FuncSpecInvite] Clear State');

export const clearInvitation = createAction(
  '[FuncSpecInvite] Clear Invitation'
);

export const invitationFailed = createAction(
  '[FuncSpecInvite] Invitation Failed',
  props<{
    invitedStatus: APIFunctionalSpecifications.IInvitedParticipantStatus;
  }>()
);

export const setInvitationDuplicateError = createAction(
  '[FuncSpecInvite] Set Invitation Duplicate Error'
);

export const inviteParticipant = createAction(
  '[FuncSpecInvite] Invite Participant',
  props<{ email: string; roleId: string }>()
);

export const inviteParticipantError = createAction(
  '[FuncSpecInvite] Invite Participant Error',
  props<{ error?: string }>()
);

export const inviteParticipantSuccess = createAction(
  '[FuncSpecInvite] Invite Participant Success'
);

export const addParticipantToState = createAction(
  '[FuncSpecInvite] Add Participant To State',
  props<{
    participant: APIFunctionalSpecifications.ISpecificationParticipantViewModel;
  }>()
);

export const deleteParticipant = createAction(
  '[FuncSpecInvite] Delete Participant',
  props<{
    participant: APIFunctionalSpecifications.ISpecificationParticipantViewModel;
  }>()
);

export const deleteParticipantSuccess = createAction(
  '[FuncSpecInvite] Delete Participant Success',
  props<{ email: string }>()
);

export const deleteParticipantFailure = createAction(
  '[FuncSpecInvite] Delete Participant Failure',
  props<{ error?: string }>()
);

export const setFuncSpecId = createAction(
  '[FuncSpecInvite] Set FuncSpecId',
  props<{ funcSpecId: string }>()
);

export const loadAccessibleRoles = createAction(
  '[FuncSpecInvite] Load Accessible Roles',
  props<{
    projectId: string;
  }>()
);

export const loadAccessibleRolesSuccess = createAction(
  '[FuncSpecInvite] Load Accessible Roles Success',
  props<{
    accessibleRoles: APIFunctionalSpecifications.IAccessibleRolesViewModel[];
  }>()
);

export const loadAccessibleRolesFailure = createAction(
  '[FuncSpecInvite] Load Accessible Roles Failure',
  props<{ error?: string }>()
);

export const startEditParticipantRole = createAction(
  '[FuncSpecInvite] Start Edit Participant Role',
  props<{
    participant: APIFunctionalSpecifications.ISpecificationParticipantViewModel;
    projectId: string;
  }>()
);

export const stopEditParticipantRole = createAction(
  '[FuncSpecInvite] Stop Edit Participant Role'
);

export const editParticipantRole = createAction(
  '[FuncSpecInvite] Edit Participant Role',
  props<{ userId: number; roleId: string }>()
);

export const editParticipantRoleSuccess = createAction(
  '[FuncSpecInvite] Edit Participant Role Success',
  props<{
    participant: APIFunctionalSpecifications.ISpecificationParticipantViewModel;
  }>()
);

export const editParticipantRoleFailure = createAction(
  '[FuncSpecInvite] Edit Participant Role Failure',
  props<{ error?: string }>()
);

export const loadRoleDefinitions = createAction(
  '[FuncSpecInvite] Load Role Definitions'
);

export const loadRoleDefinitionsSuccess = createAction(
  '[FuncSpecInvite] Load Role Definitions Success',
  props<{
    roleDefinitions: APIFunctionalSpecifications.IRoleDefinitionViewModel[];
  }>()
);

export const loadRoleDefinitionsFailure = createAction(
  '[FuncSpecInvite] Load Role Definitions Failure',
  props<{ error?: string }>()
);
