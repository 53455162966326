<ng-container>
  <div class="modal fade" id="exportModal" #exportModal tabindex="-1">
    <div
      class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content shadow rounded">
        <div class="modal-header position-relative">
          <div class="d-flex flex-fill flex-column align-items-center mt-5">
            <span class="material-icons modal-icon text-light mt-4 mb-3">
              description
            </span>
            <div
              class="modal-title text-primary text-center mb-3 button-sized-font"
            >
              Skapa PDF
            </div>
            <div class="text-center description">
              Välj vilket kapitel du vill exportera.
            </div>
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <hr class="divider bg-gray-mid-light mx-4" />
        <div class="modal-body px-5 pb-5">
          <ng-container
            *ngIf="
              (status$ | async) !== statusEnum.Approved &&
              (status$ | async) !== statusEnum.RevisionApproved
            "
          >
            <div class="warning p-4 d-flex align-items-start fw-bold mb-2">
              <i class="material-icons info-icon">info</i>
              <div class="d-flex flex-column ms-3">
                <span>
                  Observera att Funktionsbeskriviningen inte är godkänd av
                  beställare så export av hela beskrivning är än inte möjligt.
                </span>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="chapters$ | async as chapters">
            <mat-radio-group [(ngModel)]="chapterIndex">
              <sbui-accordion
                class="chapter rounded shadow d-block mb-2 w-100"
                (expandedChange)="handleExpandedChange(-1)"
              >
                <div
                  header
                  #accordionItemClick
                  class="p-3 d-flex justify-content-between align-items-center cursor-pointer"
                >
                  <div class="d-flex align-items-center">
                    <i class="material-icons icon cursor-pointer me-3">
                      description
                    </i>
                    <span> AMA Funktionsbeskrivningen </span>
                  </div>
                  <mat-radio-button [value]="-1"></mat-radio-button>
                </div>
                <hr
                  divider
                  class="divider bg-gray-mid-light mx-3 my-0"
                  *ngIf="(expanded$ | async) === -1"
                />
                <div
                  body
                  class="p-3"
                  [class.d-none]="(expanded$ | async) !== -1"
                >
                  <div>
                    Vid export av <strong>hela</strong> funktionsbeskrivningen
                    skrivs ett indexering och försättsblad ut med information om
                    bland annat datum för godkännande, kontaktperson och
                    eventuellt revideringsdatum.
                  </div>
                  <ng-container
                    *ngIf="
                      (status$ | async) === statusEnum.Approved ||
                      (status$ | async) === statusEnum.RevisionApproved
                    "
                  >
                    <sbui-accordion
                      class="email d-block w-100 mt-3"
                      (expandedChange)="handleEmailInputExpandedChange($event)"
                    >
                      <div
                        header
                        #accordionItemClick
                        class="px-3 py-2 d-flex justify-content-between align-items-center cursor-pointer"
                      >
                        <strong>
                          Lägg till annan mejladress dit dokumentlänk ska
                          skickas
                        </strong>
                        <ng-container
                          *ngIf="emailInputExpanded$ | async; else closed"
                        >
                          <span class="material-icons icon-arrow">
                            keyboard_arrow_up
                          </span>
                        </ng-container>
                        <ng-template #closed>
                          <span class="material-icons icon-arrow">
                            keyboard_arrow_down
                          </span>
                        </ng-template>
                      </div>
                      <div
                        body
                        class="px-3 pb-3"
                        *ngIf="emailInputExpanded$ | async"
                      >
                        Obs. dokumentlänk är giltig i 24h efter att den skickats
                        <mat-form-field appearance="outline" class="w-100 mt-2">
                          <input
                            matInput
                            type="email"
                            autocomplete="email"
                            placeholder="E-postadress"
                            [formControl]="email"
                          />
                          <mat-error *ngIf="email.errors">
                            E-post måste vara i rätt förmat.
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </sbui-accordion>
                  </ng-container>
                  <div class="d-flex justify-content-end">
                    <button
                      type="button"
                      id="full_export"
                      data-bs-dismiss="modal"
                      class="btn btn-outline-primary btn-submit fw-bold mt-3"
                      [disabled]="
                        email.invalid ||
                        ((status$ | async) !== statusEnum.Approved &&
                          (status$ | async) !== statusEnum.RevisionApproved)
                      "
                      (click)="fullExport()"
                    >
                      Exportera
                    </button>
                  </div>
                </div>
              </sbui-accordion>
              <sbui-accordion
                *ngFor="let chapter of chapters; let i = index"
                class="chapter rounded shadow d-block mb-2 w-100"
                (expandedChange)="handleExpandedChange(i)"
              >
                <div
                  header
                  #accordionItemClick
                  class="p-3 d-flex justify-content-between align-items-center cursor-pointer"
                >
                  <div class="d-flex align-items-center">
                    <span
                      class="badge d-flex align-items-center justify-content-center text-medium me-3"
                    >
                      {{ chapter.chapterNumber }}
                    </span>
                    <span>
                      {{ chapter.title }}
                    </span>
                  </div>
                  <mat-radio-button
                    [value]="chapter.chapterNumber"
                  ></mat-radio-button>
                </div>
                <hr
                  divider
                  class="divider bg-gray-mid-light mx-3 my-0"
                  *ngIf="(expanded$ | async) === i"
                />
                <div
                  body
                  class="p-3"
                  [class.d-none]="(expanded$ | async) !== i"
                >
                  <ng-container [ngSwitch]="chapter.chapterType">
                    <div *ngSwitchCase="chapterType.Overview">
                      Vid export av kapitel {{ chapter.chapterNumber }}
                      <strong>{{ chapter.title }}</strong> skrivs ett
                      försättsblad ut med information om bland annat datum för
                      godkännande, kontaktperson och eventuellt
                      revideringsdatum.
                    </div>
                    <div *ngSwitchCase="chapterType.Text">
                      Vid export av kapitel {{ chapter.chapterNumber }}
                      <strong>{{ chapter.title }}</strong> finns valda rubriker
                      och inskriven text med.
                    </div>
                    <div *ngSwitchCase="chapterType.Structure">
                      Vid export av kapitel {{ chapter.chapterNumber }}
                      <strong>{{ chapter.title }}</strong> skrivs valda
                      byggstrukturer, så som till exempel byggnadsverk, utrymme,
                      funktionella system ut.
                    </div>
                  </ng-container>
                  <div class="d-flex justify-content-end">
                    <ng-container *ngIf="funcSpecId$ | async as funcSpecId">
                      <ng-container
                        *ngIf="
                          chapter.chapterType === chapterType.Structure;
                          else exportButton
                        "
                      >
                        <button
                          type="button"
                          id="navigate"
                          data-bs-dismiss="modal"
                          class="btn btn-outline-primary btn-submit fw-bold mt-3"
                          (click)="navigateToChapter(funcSpecId, chapter)"
                        >
                          Välj byggstruktur
                        </button>
                      </ng-container>
                      <ng-template #exportButton>
                        <ama-studio-func-spec-export-button
                          [chapter]="chapter"
                        ></ama-studio-func-spec-export-button>
                      </ng-template>
                    </ng-container>
                  </div>
                </div>
              </sbui-accordion>
            </mat-radio-group>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="exportSuccess"
    bsModal
    #exportSuccess="bs-modal"
    tabindex="-1"
    data-bs-backdrop="static"
    (onHidden)="closeExportSuccess()"
  >
    <div
      class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content shadow rounded">
        <div class="modal-header align-items-start border-0">
          <div
            class="d-flex flex-fill flex-column align-items-center justify-content-center mt-5 mb-2"
          >
            <span class="material-icons modal-icon blue mt-4 mb-3">
              get_app
            </span>
          </div>
          <button
            id="close-result"
            (click)="closeExportSuccess()"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center mb-5 pb-5 px-5"
        >
          <div class="result-text fw-bold text-center">
            Exporten av valt innehåll har påbörjats. En ny sida kommer öppnas
            snart, där förhandsvisning av PDF och nedladdning är möjlig.
          </div>
          <h5 class="description text-center">
            Öppnas inte en ny sida, kontrollera att webbläsaren som används
            tillåter en ny sida öppnas för nedladdning
          </h5>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="fullExportSuccess"
    bsModal
    #fullExportSuccess="bs-modal"
    tabindex="-1"
    data-bs-backdrop="static"
    (onHidden)="closeExportSuccess()"
  >
    <div
      class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content shadow rounded">
        <div class="modal-header align-items-start border-0">
          <div
            class="d-flex flex-fill flex-column align-items-center justify-content-center mt-5 mb-2"
          >
            <span class="material-icons modal-icon blue mt-4 mb-3">
              get_app
            </span>
          </div>
          <button
            id="close-result"
            (click)="closeExportSuccess()"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center mb-5 pb-5 px-5"
        >
          <div class="result-text fw-bold text-center">
            Exporten av valt innehåll har påbörjats.
          </div>
          <h5 class="description text-center">
            PDF-exporten kan ta tid, information var du kan ladda ner
            beskrivningen skickas via mail när exporten är klar.
          </h5>
          <hr class="divider bg-gray-mid-light mx-4 w-100" />
          <div class="warning p-4 d-flex align-items-start fw-bold mb-2 mt-3">
            <i class="material-icons info-icon">info</i>
            <div class="d-flex flex-column ms-3">
              <span>
                Observera att Funktionsbeskriviningen endast är tillgänglig i
                24h efter att den skickats till behörig mailadress. Ny export
                krävs i annat fall.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="exportFailed"
    bsModal
    #exportFailed="bs-modal"
    tabindex="-1"
    data-bs-backdrop="static"
    (onHidden)="closeExportFailed()"
  >
    <div
      class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content shadow rounded">
        <div class="modal-header align-items-start border-0">
          <div
            class="d-flex flex-fill flex-column align-items-center justify-content-center mt-5 mb-2"
          >
            <span class="material-icons modal-icon orange">error</span>
          </div>
          <button
            id="close-result"
            (click)="closeExportFailed()"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center mb-3 pb-5 px-5"
        >
          <div class="result-text fw-bold text-center">
            Något gick fel. Vänligen försök igen.
          </div>
          <h5 class="description text-center">
            Ett fel har uppstått vid export, vänta några minuter och försök på
            nytt.
          </h5>
        </div>
      </div>
    </div>
  </div>
</ng-container>
