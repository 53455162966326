<div
  aria-live="polite"
  aria-atomic="true"
  class="position-relative toast-wrapper"
>
  <div class="toast-container">
    <ama-studio-toast-notifications-message
      *ngIf="errorMessage$ | async as errorMessage"
      [message]="errorMessage"
    ></ama-studio-toast-notifications-message>

    <ama-studio-toast-notifications-message
      *ngIf="message$ | async as message"
      [message]="message"
    ></ama-studio-toast-notifications-message>
  </div>
</div>
