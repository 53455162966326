import { createAction, props } from '@ngrx/store';
import { Section } from './global-state.reducer';

export const setMainTitle = createAction(
  '[GlobalState] Set Main Title',
  props<{ mainTitle: string }>()
);

export const setActiveSection = createAction(
  '[GlobalState] Set Active Section',
  props<{ section: Section }>()
);

export const clearActiveSection = createAction(
  '[GlobalState] Clear Active Section'
);

export const onSideMenuCollapse = createAction(
  '[GlobalState] Set Side menu collapse',
  props<{ sideMenuCollapsed: boolean }>()
);
