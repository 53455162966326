import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastNotificationsContainerComponent } from './toast-notifications-container/toast-notifications-container.component';
import { ToastNotificationsMessageComponent } from './toast-notifications-message/toast-notifications-message.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ToastEffects } from './+state/toast.effects';
import * as fromToast from './+state/toast.reducer';
import { ToastFacade } from './+state/toast.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromToast.TOAST_NOTIFICATIONS_FEATURE_KEY,
      fromToast.reducer
    ),
    EffectsModule.forFeature([ToastEffects]),
  ],
  declarations: [
    ToastNotificationsContainerComponent,
    ToastNotificationsMessageComponent,
  ],
  providers: [ToastFacade, { provide: 'Window', useValue: window }],
  exports: [ToastNotificationsContainerComponent],
})
export class ToastNotificationsModule {}
