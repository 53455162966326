import { Component, ElementRef, AfterViewInit, ViewChild } from '@angular/core'
import { loadLatestNotifications } from '../+state/func-spec-notifications.actions';
import { FuncSpecNotificationsFacade } from '../+state/func-spec-notifications.facade';

@Component({
  selector: 'ama-studio-func-spec-notifications',
  templateUrl: './func-spec-notifications.component.html',
  styleUrls: ['./func-spec-notifications.component.scss']
})
export class FuncSpecNotificationsComponent implements AfterViewInit {

  @ViewChild('dropdown') dropdown!: ElementRef<HTMLElement>;

  constructor(private notificationsFacade: FuncSpecNotificationsFacade) {}

  ngAfterViewInit(): void {
    this.dropdown.nativeElement.addEventListener('shown.bs.dropdown', () => {
      this.notificationsFacade.dispatch(loadLatestNotifications());
    });
  }

}
