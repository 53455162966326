import { APIStructures } from '@ama-studio/shared';
import { normalize, schema } from 'normalizr';

function normalizeNestedItems(
  nestedStructures: APIStructures.IFunctionalSpecificationStructureItemViewModel
) {
  const item =
    new schema.Entity<APIStructures.IFunctionalSpecificationStructureItemViewModel>(
      'items'
    );
  item.define({
    children: [item],
  });

  return normalize(nestedStructures, item);
}

export function normalizeCanvasStructures(
  structureModel: APIStructures.IFunctionalSpecificationStructureItemViewModel
) {
  const normalized = normalizeNestedItems(structureModel);

  // return an object that can be directly put on the state.
  return {
    canvasStructures: normalized.entities.items,
    canvasRootItemId: normalized.result as string,
  };
}

export function normalizeStructureItem(
  structureItem: APIStructures.IFunctionalSpecificationStructureItemViewModel
) {
  const normalizedItem = normalizeNestedItems(structureItem);
  const itemId = normalizedItem.result;
  const newItem = normalizedItem.entities.items[
    itemId
  ] as APIStructures.IFunctionalSpecificationStructureItemViewModel;

  const itemsToAdd = {
    [itemId]: newItem,
  };

  normalizedItem.entities.items[itemId].children.forEach((childId: string) => {
    itemsToAdd[childId] = normalizedItem.entities.items[childId];
  });

  return itemsToAdd;
}

export function itemChildren(
  structureItem: APIStructures.IFunctionalSpecificationStructureItemViewModel
) {
  const normalizedItem = normalizeNestedItems(structureItem);
  const itemId = normalizedItem.result;

  return normalizedItem.entities.items[itemId].children;
}

export function normalizeCanvasStructureItemTree(
  structureModelTree: APIStructures.IFunctionalSpecificationStructureItemViewModel,
  existingItems: Record<
    string,
    APIStructures.IFunctionalSpecificationStructureItemViewModel
  >
) {
  const normalized = normalizeNestedItems(structureModelTree);
  /**
   * The server sent us a tree. Some of them is already in our state.
   * The one in our state might have information about its children which
   * is not available on all items in the tree sent from the server.
   * So, the items sent from the server that do not have children shall
   * not overwrite the existing items in the state.
   */
  const itemsToAdd = {};

  Object.keys(normalized.entities.items).forEach((itemId) => {
    let shallAdd = false;

    if (!Object.prototype.hasOwnProperty.call(existingItems, itemId)) {
      // Add all that is not in the state
      shallAdd = true;
    }

    if (normalized.entities.items[itemId].children.length > 0) {
      // If the item got the children info, it can be added to the state.
      shallAdd = true;
    }

    if (shallAdd) {
      itemsToAdd[itemId] = normalized.entities.items[itemId];
    }
  });

  return itemsToAdd;
}

export function getAllAncestorIds(
  structureModelTree: APIStructures.IFunctionalSpecificationStructureItemViewModel,
  topNodeItemId: string
) {
  const normalized = normalizeNestedItems(structureModelTree);

  const itemsToExpand = {};
  let node = normalized.entities.items[topNodeItemId];

  while (!node.isRoot) {
    const parentId = node.parentId;
    itemsToExpand[parentId] = parentId;
    node = normalized.entities.items[parentId];
  }

  return itemsToExpand;
}
