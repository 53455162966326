import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  map,
  mapTo,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';

import * as FuncSpecExportActions from './func-spec-export.actions';
import { select, Store, Action } from '@ngrx/store';
import {
  FuncSpecCorePartialState,
  getFunctionalSpecificationId,
} from '@ama-studio/func-spec-core-state';
import { FuncSpecExportService } from '../func-spec-export.service';
import { fetch } from '@nrwl/angular';
import { HttpErrorResponse } from '@angular/common/http';
import { APIExport } from '@ama-studio/shared';
import { timer } from 'rxjs';
import { FuncSpecExportPartialState } from './func-spec-export.reducer';
import { getQueue } from './func-spec-export.selectors';

@Injectable()
export class FuncSpecExportEffects {
  exportPdf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecExportActions.exportPdf),
      withLatestFrom(this.coreStore.pipe(select(getFunctionalSpecificationId))),
      fetch({
        run: (action, id) =>
          this.exportService
            .exportPdf({
              chapterNumber: action.chapterNumber,
              structureItemId: action.structureItemId,
              id: id,
            })
            .pipe(
              switchMap((response) => [
                FuncSpecExportActions.exportPdfSuccess({
                  pdfId: response.pdfId,
                }),
                FuncSpecExportActions.selectionForExport({
                  selectionMode: false,
                }),
              ])
            ),
        onError: (_, error: HttpErrorResponse | null) => {
          console.error('Error', error);
        },
      })
    )
  );

  fullExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecExportActions.fullExport),
      withLatestFrom(this.coreStore.pipe(select(getFunctionalSpecificationId))),
      fetch({
        run: (action, id) =>
          this.exportService
            .fullExport({
              id,
              email: action.email,
            })
            .pipe(
              switchMap(() => [
                FuncSpecExportActions.fullExportSuccess(),
                FuncSpecExportActions.selectionForExport({
                  selectionMode: false,
                }),
              ])
            ),
        onError: (_, error: HttpErrorResponse | null) => {
          console.error('Error', error);
        },
      })
    )
  );

  onExportPdfSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecExportActions.exportPdfSuccess),
      switchMap(() =>
        // First check after three seconds, then periodically each 10 seconds
        timer(3000, 10000).pipe(
          mapTo(FuncSpecExportActions.checkStatuses()),
          takeUntil(
            this.actions$.pipe(ofType(FuncSpecExportActions.stopChecking))
          )
        )
      )
    )
  );

  onCheckStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecExportActions.checkStatuses),
      withLatestFrom(this.store.pipe(select(getQueue))),
      switchMap(([, queue]) => {
        const actions: Action[] = [];
        // If we have items in the queue, check status for each of them
        if (queue.length) {
          queue.forEach((id) => {
            actions.push(
              FuncSpecExportActions.checkStatus({
                pdfId: id,
              })
            );
          });
          // Otherwise dispatch the stop checking action so the timer for checkStatuses can stop
        } else {
          actions.push(FuncSpecExportActions.stopChecking());
        }
        return actions;
      })
    )
  );

  onCheckStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecExportActions.checkStatus),
      withLatestFrom(this.coreStore.pipe(select(getFunctionalSpecificationId))),
      fetch({
        run: (action, id) =>
          this.exportService.checkPdfStatus({ id, pdfId: action.pdfId }).pipe(
            switchMap((response) => {
              let actions: Action[] = [
                FuncSpecExportActions.continueChecking(),
              ];
              // If we get back a string, it means it is a download URL
              if (typeof response === 'string') {
                actions = [
                  FuncSpecExportActions.downloadPdf({
                    pdfId: action.pdfId,
                    url: response,
                  }),
                ];
              }
              // If we get back the object and the status is failed, we call the export failed action
              if (response?.exportStatus === APIExport.ExportStatus.Failed) {
                actions = [
                  FuncSpecExportActions.exportFailed({
                    pdfId: action.pdfId,
                  }),
                ];
              }
              // Otherwise, it means it is pending and we should continue with status checks for this item
              return actions;
            })
          ),
        onError: (_, error: HttpErrorResponse | null) => {
          console.error('Error', error);
        },
      })
    )
  );

  downloadPdf$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FuncSpecExportActions.downloadPdf),
        map((action) => window.open(action.url, '_blank'))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<FuncSpecExportPartialState>,
    private coreStore: Store<FuncSpecCorePartialState>,
    private exportService: FuncSpecExportService
  ) {}
}
