import { APIStructures } from '@ama-studio/shared';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FUNCSPECSTRUCTURE_FEATURE_KEY,
  State,
  FuncSpecStructurePartialState,
} from './func-spec-structure-state.reducer';

// Lookup the 'FuncSpecStructure' feature state managed by NgRx
export const getFuncSpecStructureState = createFeatureSelector<
  State
>(FUNCSPECSTRUCTURE_FEATURE_KEY);

export const getFuncSpecStructureLoaded = createSelector(
  getFuncSpecStructureState,
  (state: State) => state.loaded
);

export const getFuncSpecStructureError = createSelector(
  getFuncSpecStructureState,
  (state: State) => state.error
);

export const getStructureCanvasModel = createSelector(
  getFuncSpecStructureState,
  (state: State) => state.structureCanvasModel
);

export const getStructures = createSelector(
  getFuncSpecStructureState,
  (state: State) => state.canvasStructures
);

export const getStructureItem = (id: string) =>
  createSelector(getFuncSpecStructureState, (state) =>
    state.canvasStructures ? state.canvasStructures[id] : null
  );

export const getRootItem = createSelector(
  getFuncSpecStructureState,
  (state: State) => state.canvasRootItemId
);

export const getStructureIsExpanded = (structureItemId: string) =>
  createSelector(getFuncSpecStructureState, (state: State) =>
    Object.prototype.hasOwnProperty.call(
      state.expandedStructureItemIds,
      structureItemId
    )
  );

export const getSelectedStructureItemInCanvas = createSelector(
  getFuncSpecStructureState,
  (state: State) => state.selectedStructureItemInCanvas
);

export const getStructureItemIsSelected = (structureItemId: string) =>
  createSelector(
    getFuncSpecStructureState,
    (state: State) => state.selectedStructureItemInCanvas === structureItemId
  );

export const getStructureItemSectionHighlighted = (structureItemId: string) =>
  createSelector(
    getFuncSpecStructureState,
    (state: State) => state.highlightedStructureItemSection?.[structureItemId]
  );

export const getSelectedStructureItemInCanvasItem = createSelector(
  getFuncSpecStructureState,
  (state: State) =>
    state.canvasStructures
      ? state.canvasStructures[state.selectedStructureItemInCanvas]
      : null
);

export const getSelectedStructureItemInAddMode = createSelector(
  getFuncSpecStructureState,
  (state: State) => state.selectedStructureItemInAddMode
);

export const getSelectedStructureItemInAddModeItem = createSelector(
  getFuncSpecStructureState,
  (state: State) =>
    state.canvasStructures
      ? state.canvasStructures[state.selectedStructureItemInAddMode]
      : null
);

export const getSelectedStructureItemInNavigation = createSelector(
  getFuncSpecStructureState,
  (state: State) => state.selectedItemInNavigation
);

export const getIsAddingStructureToCanvas = createSelector(
  getFuncSpecStructureState,
  (state: State) => state.isAddingStructureToCanvas
);

export const getHighlightedInCanvasItemId = createSelector(
  getFuncSpecStructureState,
  (state: State) => state.highlightedItemInCanvas
);

export const getShowProperties = createSelector(
  getFuncSpecStructureState,
  (state: State) => state.showProperties
);

export const getIsStructureRootItemExists = createSelector(
  getFuncSpecStructureState,
  (state: State) => state.isStructureRootItemExists
);

export const getPathFromSelectedToRoot = createSelector(
  getFuncSpecStructureState,
  (state: State) => {
    if (!state.selectedStructureItemInCanvas) return null;

    if (
      !Object.prototype.hasOwnProperty.call(
        state.canvasStructures,
        state.selectedStructureItemInCanvas
      )
    )
      return null;

    const item = state.canvasStructures[state.selectedStructureItemInCanvas];
    const path = pathToRoot(state, item);
    return path;
  }
);

function pathToRoot(
  state: State,
  item: APIStructures.IFunctionalSpecificationStructureItemViewModel
): APIStructures.IFunctionalSpecificationStructureItemViewModel[] {
  if (item.isRoot || item.parentId === null) {
    return [item];
  }
  const parent = state.canvasStructures[item.parentId];
  if (!parent) return null;

  const path = pathToRoot(state, parent);
  path.push(item);
  return path;
}
