import {
  logIn,
  logOut,
  setAccessToken,
  setIsPending,
  setHasValidLicense,
  checkHasValidLicense,
} from './auth.actions';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { User as OIDCUser } from 'oidc-client';
import { State } from './auth.reducer';
import { APIFunctionalSpecifications, BaseHttpService, EnvironmentConfig } from '@ama-studio/shared';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseHttpService {

  private apiUrl = `${this.envConfig.baseApiUrl}/functionalspecifications`;

  constructor(private store: Store<State>, http: HttpClient, envConfig: EnvironmentConfig) {
    super(http, envConfig)
  }

  /**
   * Inserts a `User` instance in store, created from a `oidc-client User`
   * @param oidcUser a oidc user object
   */
  public logIn(oidcUser: OIDCUser) {
    this.store.dispatch(
      logIn({
        userId: oidcUser.profile.userid,
        firstName: oidcUser.profile.given_name || '',
        lastName: oidcUser.profile.family_name || '',
        email: oidcUser.profile.sub,
        tokenType: oidcUser.token_type,
        accessToken: oidcUser.access_token,
      })
    );

    this.store.dispatch(checkHasValidLicense());
  }

  public logOut() {
    this.store.dispatch(logOut());
  }

  public setAccessToken(accessToken: string) {
    this.store.dispatch(
      setAccessToken({
        accessToken,
      })
    );
  }

  public setIsPendingLogin(isPending: boolean) {
    this.store.dispatch(setIsPending({ isPending }));
  }

  public setHasValidLicense(hasValidLicense: boolean) {
    this.store.dispatch(setHasValidLicense({ hasValidLicense }));
  }

  private getCurrentParticipantRoleUrl(fsId: string) {
    return `${this.apiUrl}/${fsId}/participants/me`;
  }

  public getCurrentParticipantRole(fsId: string) {
    return this.http.get<APIFunctionalSpecifications.IUserRoleImportanceDuplicate>(
      this.getCurrentParticipantRoleUrl(fsId),
      this.headers
    );
  }


}
