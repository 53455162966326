import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { FeatureFlagsService } from '@ama-studio/featureflags'
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceModeGuard implements CanActivate {

  constructor(
    private featureFlagService: FeatureFlagsService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.featureFlagService.flags$.pipe(
        first(flags => Object.keys(flags).length > 0),
        map(flags => {
          if (flags.maintenance_mode) {
            this.router.navigate(['maintenance']);
            return false;
          }
          return true;
        })
      )
  }

}
