import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuncSpecExportIconComponent } from './func-spec-export-icon/func-spec-export-icon.component';
import { FuncSpecExportComponent } from './func-spec-export/func-spec-export.component';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { FuncSpecExportModalComponent } from './func-spec-export-modal/func-spec-export-modal.component';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from '@ama-studio/ui';
import * as fromFuncSpecExport from './+state/func-spec-export.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FuncSpecExportEffects } from './+state/func-spec-export.effects';
import { FuncSpecExportFacade } from './+state/func-spec-export.facade';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FuncSpecExportButtonComponent } from './func-spec-export-button/func-spec-export-button.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
@NgModule({
  imports: [
    CommonModule,
    NgxPopperjsModule,
    MatRadioModule,
    FormsModule,
    AccordionModule,
    ModalModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    StoreModule.forFeature(
      fromFuncSpecExport.FUNCSPECEXPORT_FEATURE_KEY,
      fromFuncSpecExport.reducer
    ),
    EffectsModule.forFeature([FuncSpecExportEffects]),
  ],
  declarations: [
    FuncSpecExportIconComponent,
    FuncSpecExportComponent,
    FuncSpecExportModalComponent,
    FuncSpecExportButtonComponent,
  ],
  exports: [FuncSpecExportComponent, FuncSpecExportButtonComponent],
  providers: [FuncSpecExportFacade],
})
export class FuncSpecExportModule {}
