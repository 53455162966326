import { Component, Input } from '@angular/core';
import { NonChapter } from '@ama-studio/shared';

@Component({
  selector: 'ama-studio-func-spec-chapter-menu-item',
  templateUrl: './func-spec-chapter-menu-item.component.html',
  styleUrls: ['./func-spec-chapter-menu-item.component.scss'],
})
export class FuncSpecChapterMenuItemComponent {
  @Input()
  title: string;

  @Input()
  chapterNumber: number;

  @Input()
  isSelected: boolean;

  nonChapterEnum = NonChapter;

  constructor() {}
}
