import { createAction, props } from '@ngrx/store';

export const exportPdf = createAction(
  '[FuncSpecExport] Export PDF',
  props<{ chapterNumber: number; structureItemId?: string }>()
);

export const exportPdfSuccess = createAction(
  '[FuncSpecExport] Export PDF Success',
  props<{ pdfId: string }>()
);

export const fullExport = createAction(
  '[FuncSpecExport] Full Export',
  props<{ email: string }>()
);

export const fullExportSuccess = createAction(
  '[FuncSpecExport] Full Export Success'
);

export const fullExportFailed = createAction(
  '[FuncSpecExport] Full Export Failed'
);

export const clearExportResult = createAction(
  '[FuncSpecExport] Clear Export Result'
);

export const checkStatuses = createAction('[FuncSpecExport] Check Statuses');

export const checkStatus = createAction(
  '[FuncSpecExport] Check Status',
  props<{ pdfId: string }>()
);

export const continueChecking = createAction(
  '[FuncSpecExport] Continue Checking'
);

export const stopChecking = createAction('[FuncSpecExport] Stop Checking');

export const downloadPdf = createAction(
  '[FuncSpecExport] Download PDF',
  props<{ url: string; pdfId: string }>()
);

export const exportFailed = createAction(
  '[FuncSpecExport] Export Failed',
  props<{ pdfId: string }>()
);

export const selectionForExport = createAction(
  '[FuncSpecExport] Is object selection mode',
  props<{ selectionMode: boolean }>()
);
