import { createReducer, on, Action } from '@ngrx/store';
import { APIStructures } from '@ama-studio/shared';
import {
  getAllAncestorIds,
  itemChildren,
  normalizeCanvasStructureItemTree,
  normalizeCanvasStructures,
  normalizeStructureItem,
} from '../normalization-helpers/normalization-helpers';
import * as FuncSpecStructureActions from './func-spec-structure-state.actions';

export const FUNCSPECSTRUCTURE_FEATURE_KEY = 'funcSpecStructure';

export interface State {
  loaded: boolean; // has the FuncSpecStructure list been loaded
  error?: string | null; // last known error (if any)

  structureCanvasModel: APIStructures.IFunctionalSpecificationStructureItemViewModel;

  canvasStructures: Record<
    string,
    APIStructures.IFunctionalSpecificationStructureItemViewModel
  >;
  canvasRootItemId: string;

  /** Contains expanded structure items  */
  expandedStructureItemIds: Record<string, string>;

  selectedStructureItemInCanvas: string;
  selectedStructureItemInAddMode: string;
  selectedItemInNavigation: string;
  isAddingStructureToCanvas: boolean;
  highlightedItemInCanvas: string;
  showProperties: boolean;
  highlightedStructureItemSection: Record<
    string,
    APIStructures.IStructureItemSection
  >;
  isStructureRootItemExists: boolean;
}

export interface FuncSpecStructurePartialState {
  readonly [FUNCSPECSTRUCTURE_FEATURE_KEY]: State;
}

export const initialState: State = {
  // set initial required properties
  loaded: false,
  error: null,

  structureCanvasModel: null,

  canvasStructures: null,
  canvasRootItemId: null,

  expandedStructureItemIds: {},
  selectedStructureItemInCanvas: null,
  selectedStructureItemInAddMode: null,
  selectedItemInNavigation: null,
  isAddingStructureToCanvas: false,
  highlightedItemInCanvas: null,
  showProperties: false,
  highlightedStructureItemSection: {},
  isStructureRootItemExists: true,
};

const funcSpecStructureReducer = createReducer(
  initialState,
  on(FuncSpecStructureActions.clearState, () => initialState),
  on(
    FuncSpecStructureActions.loadStructureCanvasSuccess,
    (state, { structureCanvasModel }) => ({
      ...state,
      ...normalizeCanvasStructures(structureCanvasModel),
      structureCanvasModel: structureCanvasModel,
    })
  ),
  on(
    FuncSpecStructureActions.loadStructureCanvasFailure,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),
  on(
    FuncSpecStructureActions.loadStructureCanvasItemSuccess,
    (state, { structureCanvasItemModel }) => ({
      ...state,
      canvasStructures: {
        ...state.canvasStructures,
        // Update the item and add its children
        ...normalizeStructureItem(structureCanvasItemModel),
      },
    })
  ),
  on(
    FuncSpecStructureActions.updateChildListForCanvasItemSuccess,
    (state, { structureCanvasItemModel }) => ({
      ...state,
      canvasStructures: {
        ...state.canvasStructures,
        [structureCanvasItemModel.id]: {
          ...structureCanvasItemModel,
          children: itemChildren(structureCanvasItemModel),
        },
      },
    })
  ),
  on(
    FuncSpecStructureActions.updateChildListForCanvasItemFailure,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),
  on(
    FuncSpecStructureActions.deleteStructureSuccess,
    (state, { deleteStructureId }) => {
      const newState = {
        ...state,
      };
      newState.canvasStructures = {
        ...state.canvasStructures,
      };
      delete newState.canvasStructures[deleteStructureId];
      return newState;
    }
  ),
  on(FuncSpecStructureActions.deleteStructureFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(
    FuncSpecStructureActions.updateStructureItemValues,
    (state, { structureCanvasItemModel }) => {
      // Only update this item, don't update the children
      // in order to not destroy the grandchildren info
      const normalizedItems = normalizeStructureItem(structureCanvasItemModel);
      const newState = {
        ...state,
      };
      newState.canvasStructures = {
        ...state.canvasStructures,
        [structureCanvasItemModel.id]: {
          ...normalizedItems[structureCanvasItemModel.id],
        },
      };
      return newState;
    }
  ),
  on(
    FuncSpecStructureActions.loadStructureCanvasAncestorsItemSuccess,
    (state, { structureItems, topNodeItemId }) => ({
      ...state,
      isAddingStructureToCanvas: false,
      canvasStructures: {
        ...state.canvasStructures,
        ...normalizeCanvasStructureItemTree(
          structureItems,
          state.canvasStructures
        ),
      },
      expandedStructureItemIds: {
        ...getAllAncestorIds(structureItems, topNodeItemId),
        [topNodeItemId]: topNodeItemId
      },
    })
  ),
  on(
    FuncSpecStructureActions.loadStructureCanvasAncestorsItemFailure,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),
  on(
    FuncSpecStructureActions.addExpandedStructureItem,
    (state, { structureItemId }) => ({
      ...state,
      expandedStructureItemIds: {
        ...state.expandedStructureItemIds,
        [structureItemId]: structureItemId,
      },
    })
  ),
  on(
    FuncSpecStructureActions.hideExpandedChildren,
    (state, { structureItemId }) => {
      const newState = deleteExpandedIdWithChildren(state, structureItemId);
      // Add back this structureItem
      newState.expandedStructureItemIds[structureItemId] = structureItemId;
      return newState;
    }
  ),
  on(
    FuncSpecStructureActions.hideExpandedSiblings,
    (state, { structureItemId }) => {
      const newState = deleteExpandedSiblings(state, structureItemId);
      // Add back this structureItem
      newState.expandedStructureItemIds[structureItemId] = structureItemId;
      return newState;
    }
  ),
  on(
    FuncSpecStructureActions.removeExpandedStructureItem,
    (state, { structureItemId }) =>
      deleteExpandedIdWithChildren(state, structureItemId)
  ),
  on(FuncSpecStructureActions.clearSelectedStructureItemInCanvas, (state) => ({
    ...state,
    selectedStructureItemInCanvas: null,
  })),
  on(
    FuncSpecStructureActions.selectedStructureItemInCanvas,
    (state, { structureItemId }) => ({
      ...state,
      selectedStructureItemInCanvas: structureItemId,
    })
  ),
  on(
    FuncSpecStructureActions.selectedStructureItemInAddMode,
    (state, { structureItemId }) => ({
      ...state,
      selectedStructureItemInAddMode: structureItemId,
    })
  ),
  on(
    FuncSpecStructureActions.setIsAddingStructureToCanvas,
    (state, { isAddingStructureToCanvas }) => ({
      ...state,
      isAddingStructureToCanvas: isAddingStructureToCanvas,
    })
  ),
  on(
    FuncSpecStructureActions.highlightItemInCanvas,
    (state, { highlightedItemId }) => ({
      ...state,
      highlightedItemInCanvas: highlightedItemId,
    })
  ),
  on(
    FuncSpecStructureActions.highlightStructureItemSection,
    (state, { structureItemId, section }) => ({
      ...state,
      highlightedStructureItemSection: {
        ...state.highlightedStructureItemSection,
        [structureItemId]: section,
      },
    })
  ),
  on(
    FuncSpecStructureActions.unHighlightStructureItemSection,
    (state, { structureItemId }) => ({
      ...state,
      highlightedStructureItemSection: {
        ...state.highlightedStructureItemSection,
        [structureItemId]: APIStructures.IStructureItemSection.none,
      },
    })
  ),
  on(FuncSpecStructureActions.showProperties, (state) => ({
    ...state,
    showProperties: true,
  })),
  on(FuncSpecStructureActions.hideProperties, (state) => ({
    ...state,
    showProperties: false,
  })),
  on(FuncSpecStructureActions.addCommentToSelectedStructure, (state) => ({
    ...state,
    ...addOrSubtractTotalComments(
      state,
      state.selectedStructureItemInCanvas,
      true
    ),
  })),
  on(
    FuncSpecStructureActions.subtractCommentCount,
    (state, { structureId }) => ({
      ...state,
      ...addOrSubtractTotalComments(state, structureId, false),
    })
  ),
  on(
    FuncSpecStructureActions.saveStructureItemInCanvasAndSelect,
    (state, { structureItem }) => ({
      ...state,
      canvasStructures: {
        ...state.canvasStructures,
        // Update the item and add its children
        ...normalizeStructureItem(structureItem),
      },
    })
  ),
  on(
    FuncSpecStructureActions.setIsStructureRootItemExists,
    (state, { isStructureRootItemExists }) => ({
      ...state,
      isStructureRootItemExists: isStructureRootItemExists,
    })
  )
);

/**
 * Deletes expandedStructureItemIds and it's children recursively
 */
function deleteExpandedIdWithChildren(state: State, structureItemId: string) {
  let newState = {
    ...state,
  };
  newState.expandedStructureItemIds = {
    ...state.expandedStructureItemIds,
  };

  const childrenIds = newState.canvasStructures[structureItemId].children;
  childrenIds.forEach((id) => {
    if (
      Object.prototype.hasOwnProperty.call(
        newState.expandedStructureItemIds,
        id
      )
    ) {
      newState = deleteExpandedIdWithChildren(newState, id);
    }
  });

  delete newState.expandedStructureItemIds[structureItemId];
  return newState;
}

/**
 * Deletes expanded siblings
 */
function deleteExpandedSiblings(state: State, structureItemId: string) {
  const newState = {
    ...state,
    expandedStructureItemIds: {
      ...state.expandedStructureItemIds
    }
  };

  const parentId = newState.canvasStructures[structureItemId].parentId;
  if (parentId) {
    const siblingIds = newState.canvasStructures[parentId].children;
    siblingIds.forEach(id => {
      delete newState.expandedStructureItemIds[id];
    });
  }

  return newState;
}

function addOrSubtractTotalComments(
  state: State,
  structureId: string,
  add: boolean
) {
  return {
    canvasStructures: {
      ...state.canvasStructures,
      // Add one total comment to the selected structure
      [structureId]: {
        ...state.canvasStructures[structureId],
        totalComments:
          state.canvasStructures[structureId].totalComments + (add ? 1 : -1),
      },
    },
  };
}

export function reducer(state: State | undefined, action: Action) {
  return funcSpecStructureReducer(state, action);
}
