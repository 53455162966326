import { APIStatus } from '@ama-studio/shared';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FuncSpecStatusFacade } from '../+state/func-spec-status.facade';

@Component({
  selector: 'ama-studio-func-spec-status',
  templateUrl: './func-spec-status.component.html',
  styleUrls: ['./func-spec-status.component.scss'],
})
export class FuncSpecStatusComponent implements OnInit {
  status$: Observable<APIStatus.Status | null>;
  statusNameEnum = APIStatus.StatusName;
  constructor(
    private statusFacade: FuncSpecStatusFacade,
  ) {}

  ngOnInit(): void {
    this.status$ = this.statusFacade.status$;
  }
}
