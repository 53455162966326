import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { APIComments, APINotifications } from '@ama-studio/shared';
import * as FuncSpecCommentsActions from './func-spec-comments.actions';
import {
  validate,
  updateGroup,
  FormGroupState,
  createFormGroupState,
  onNgrxForms,
  wrapReducerWithFormStateUpdate,
} from 'ngrx-forms';
import { maxLength, required } from 'ngrx-forms/validation';

export enum CommentDeleteResult {
  none = 'None',
  success = 'Success',
  error = 'Error',
}

export interface FuncSpecCommentsForm {
  newComment: string;
}

export const validateAndUpdateForm = updateGroup<FuncSpecCommentsForm>({
  newComment: validate(required, maxLength(5000)),
});

export const FUNCSPECCOMMENTS_FEATURE_KEY = 'funcSpecComments';

export interface State extends EntityState<APIComments.ICommentViewModel> {
  loaded: boolean; // has the FuncSpecComments list been loaded
  error?: string | null; // last known error (if any)

  selectedItemId: string;
  selectedSubItemId?: string;
  selectedComment: APIComments.ICommentViewModel | APINotifications.INotificationViewModel | null;

  modalComment: APIComments.ICommentViewModel;

  createCommentType: APIComments.CommentType;

  formState: FormGroupState<FuncSpecCommentsForm>;

  showLoadingSpinner: boolean;
  commentSidepanelCollapsed: boolean;
  deleteCommentResult: CommentDeleteResult;
}

export interface FuncSpecCommentsPartialState {
  readonly [FUNCSPECCOMMENTS_FEATURE_KEY]: State;
}

export const funcSpecCommentsAdapter: EntityAdapter<APIComments.ICommentViewModel> =
  createEntityAdapter<APIComments.ICommentViewModel>({
    sortComparer: (a, b) =>
      new Date(b.created.when).getTime() - new Date(a.created.when).getTime(),
  });

export const FUNC_SPEC_COMMENTS_FORM_ID = 'funcSpecCommentsForm';
const initialFormState = createFormGroupState<FuncSpecCommentsForm>(
  FUNC_SPEC_COMMENTS_FORM_ID,
  {
    newComment: '',
  }
);

export const initialState: State = funcSpecCommentsAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  error: null,

  selectedItemId: null,
  selectedComment: null,
  selectedSubItemId: null,
  modalComment: null,

  createCommentType: null,

  formState: initialFormState,

  showLoadingSpinner: false,
  commentSidepanelCollapsed: true,
  deleteCommentResult: CommentDeleteResult.none,
});

const funcSpecCommentsReducer = createReducer(
  initialState,
  on(FuncSpecCommentsActions.clearState, () => initialState),
  on(FuncSpecCommentsActions.loadAllComments, (state) =>
    funcSpecCommentsAdapter.removeAll({
      ...state,
      loaded: false,
      showLoadingSpinner: false,
      error: null,
    })
  ),
  on(FuncSpecCommentsActions.loadAllCommentsSuccess, (state, { comments }) =>
    funcSpecCommentsAdapter.setAll(comments || [], {
      ...state,
      loaded: true,
      showLoadingSpinner: false,
    })
  ),
  on(
    FuncSpecCommentsActions.removeCommentsInStateForItem,
    (state, { itemId }) =>
      funcSpecCommentsAdapter.removeMany(
        (comment) => comment.itemId === itemId,
        { ...state }
      )
  ),
  on(FuncSpecCommentsActions.loadAllCommentsFailure, (state, { error }) => ({
    ...state,
    error,
    showLoadingSpinner: false,
  })),
  on(FuncSpecCommentsActions.loadCommentsForItem, (state) =>
    funcSpecCommentsAdapter.removeAll({
      ...state,
      loaded: false,
      showLoadingSpinner: false,
      error: null,
    })
  ),
  on(
    FuncSpecCommentsActions.loadCommentsForItemSuccess,
    (state, { comments }) =>
      funcSpecCommentsAdapter.setAll(comments || [], {
        ...state,
        loaded: true,
        showLoadingSpinner: false,
        commentSidepanelCollapsed: false,
      })
  ),
  on(
    FuncSpecCommentsActions.loadCommentsForItemFailure,
    (state, { error }) => ({
      ...state,
      error,
      showLoadingSpinner: false,
    })
  ),
  on(
    FuncSpecCommentsActions.setSelectedItemId,
    (state, { selectedItemId, selectedSubItemId }) => ({
      ...state,
      selectedItemId,
      selectedSubItemId,
    })
  ),
  on(
    FuncSpecCommentsActions.setCreateCommentType,
    (state, { createCommentType }) => ({ ...state, createCommentType })
  ),
  on(FuncSpecCommentsActions.setModalComment, (state, { modalComment }) => ({
    ...state,
    modalComment,
  })),
  on(FuncSpecCommentsActions.createCommentSuccess, (state, { comment }) =>
    funcSpecCommentsAdapter.addOne(comment, {
      ...state,
    })
  ),
  on(FuncSpecCommentsActions.deleteCommentSuccess, (state, { comment }) =>
    funcSpecCommentsAdapter.removeOne(comment.id, {
      ...state,
      deleteCommentResult: CommentDeleteResult.success,
    })
  ),
  on(FuncSpecCommentsActions.clearDeleteCommentResult, (state) => ({
    ...state,
    deleteCommentResult: CommentDeleteResult.none,
  })),
  on(FuncSpecCommentsActions.showLoadingSpinnerIfNotLoaded, (state) => ({
    ...state,
    showLoadingSpinner: !state.loaded,
  })),
  on(FuncSpecCommentsActions.toggleCommentPanel, (state, { collapsed }) => ({
    ...state,
    commentSidepanelCollapsed: collapsed,
    selectedComment: null,
  })),
  on(FuncSpecCommentsActions.selectCommentItem, (state, { comment }) => ({
    ...state,
    selectedComment: comment,
  })),
  on(FuncSpecCommentsActions.clearCommentItem, (state) => ({
    ...state,
    selectedComment: null,
  })),
  onNgrxForms()
);

export const reducer = wrapReducerWithFormStateUpdate(
  funcSpecCommentsReducer,
  (state) => state.formState,
  validateAndUpdateForm
);
