import { createAction, props } from '@ngrx/store';
import { APIComments, APINotifications } from '@ama-studio/shared';

export const clearState = createAction('[FuncSpecComments] Clear State');

export const loadAllComments = createAction(
  '[FuncSpecComments] Load All Comments'
);

export const loadAllCommentsSuccess = createAction(
  '[FuncSpecComments] Load All Comments Success',
  props<{ comments: APIComments.ICommentViewModel[] }>()
);

export const loadAllCommentsFailure = createAction(
  '[FuncSpecComments] Load All Comments Failure',
  props<{ error: string | null }>()
);

export const loadCommentsForItem = createAction(
  '[FuncSpecComments] Load Comments For Item'
);

export const loadCommentsForItemSuccess = createAction(
  '[FuncSpecComments] Load Comments For Item Success',
  props<{ comments: APIComments.ICommentViewModel[] }>()
);

export const loadCommentsForItemFailure = createAction(
  '[FuncSpecComments] Load Comments For Item Failure',
  props<{ error: string | null }>()
);

export const clearSelectedItemId = createAction(
  '[FuncSpecComments] Clear Selected Item Id'
);

export const showAllComments = createAction(
  '[FuncSpecComments] Show All Comments'
);

export const setSelectedItemId = createAction(
  '[FuncSpecComments] Set Selected Item Id',
  props<{ selectedItemId: string; selectedSubItemId?: string }>()
);

export const setCreateCommentType = createAction(
  '[FuncSpecComments] Set Create Comment Type',
  props<{ createCommentType: APIComments.CommentType }>()
);

export const setModalComment = createAction(
  '[FuncSpecComments] Set Modal Comment',
  props<{ modalComment: APIComments.ICommentViewModel }>()
);

export const deleteComment = createAction(
  '[FuncSpecComments] Delete Comment',
  props<{ comment: APIComments.ICommentViewModel }>()
);

export const deleteCommentSuccess = createAction(
  '[FuncSpecComments] Delete Comment Success',
  props<{ comment: APIComments.ICommentViewModel }>()
);

export const deleteCommentFailure = createAction(
  '[FuncSpecComments] Delete Comment Failure',
  props<{ error: string | null }>()
);

export const clearDeleteCommentResult = createAction(
  '[FuncSpecComments] Clear Delete Comment Success'
);

export const removeCommentsInStateForItem = createAction(
  '[FuncSpecComments] Remove Comments In State For Item',
  props<{ itemId: string }>()
);

export const createComment = createAction('[FuncSpecComments] Create Comment');

export const createCommentSuccess = createAction(
  '[FuncSpecComments] Create Comment Success',
  props<{ comment: APIComments.ICommentViewModel }>()
);

export const createCommentFailure = createAction(
  '[FuncSpecComments] Create Comment Failure',
  props<{ error: string | null }>()
);

export const selectCommentItem = createAction(
  '[FuncSpecComments] Select Comment Item',
  props<{ comment: APIComments.ICommentViewModel | APINotifications.INotificationViewModel | null }>()
);

export const clearCommentItem = createAction(
  '[FuncSpecComments] Clear Comment Item'
);

export const jumpToTextItem = createAction(
  '[FuncSpecComments] Jump To Text Item',
  props<{ textItemId: string }>()
);

export const showLoadingSpinnerIfNotLoaded = createAction(
  '[FuncSpecComments] Show Loading Spinner'
);

export const clearFormState = createAction(
  '[FuncSpecComments] Clear FormState'
);

export const toggleCommentPanel = createAction(
  '[FuncSpecComments] Toggle comment panel collapse',
  props<{ collapsed: boolean }>()
);
