import { Injectable } from '@angular/core';
import { IEnvironmentConfig } from '../interfaces';

@Injectable()
export class EnvironmentConfig implements IEnvironmentConfig {
  baseApiUrl!: string;
  code!: string;
  studioUrl!: string;
  byggtjanstUrl!: string;
  trackingId!: string;
  launchDarklyClientId!: string;
  productBaseUrl!: string;
  amaFunktionBuyLicenseUrl!: string;
  byggCreateAccountUrl!: string;
  environment!: string;
  incidentComponentId!: string;
}
