import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import {
  FuncSpecCoreFacade,
  inFuncSpecView,
} from '@ama-studio/func-spec-core-state';

@Injectable({
  providedIn: 'root',
})
export class FuncSpecActivateGuard implements CanActivate {
  constructor(private funcSpecCoreFacade: FuncSpecCoreFacade) {}

  public canActivate(): boolean {
    this.funcSpecCoreFacade.dispatch(inFuncSpecView());
    return true;
  }
}
