import { createReducer, on, Action } from '@ngrx/store';

import * as FuncSpecTextActions from './func-spec-text.actions';
import { APIChapter, APITexts } from '@ama-studio/shared';
import {
  normalizeTextItems,
  normalizeUpdatedTextItem,
} from '../normalization-helpers/normalization-helpers';

export const FUNCSPECTEXT_FEATURE_KEY = 'funcSpecText';

export interface State {
  loaded: boolean; // has the FuncSpecText list been loaded
  error?: string | null; // last known error (if any)

  textItems: Record<string, APITexts.ITextItemViewModel>;
  textRootId: string;
  guidanceText: APIChapter.IGuidanceWindowTextItemViewModel;
  chapterListModel: APITexts.IChapterListViewModel;
  selectedTextItemId: string;
  selectedRequirementTemplateTextItemId: string;
  textItemContent: Record<string, string>;
  textItemContentLoaded: boolean;

  /** Contains expanded text items  */
  expandedTextItemIds: string[];
  //Selected text item on arrow
  selectedTextItemInCanvas: string;
  highlightedTextItemSection: Record<string, APITexts.ITextItemSection>;
  updateTextContentLoading: boolean;
}

export interface FuncSpecTextPartialState {
  readonly [FUNCSPECTEXT_FEATURE_KEY]: State;
}

export const initialState: State = {
  // set initial required properties
  loaded: false,
  error: null,

  textItems: null,
  textRootId: null,
  guidanceText: null,
  chapterListModel: null,
  selectedTextItemId: null,
  selectedRequirementTemplateTextItemId: null,
  textItemContent: null,
  textItemContentLoaded: false,

  expandedTextItemIds: [],
  selectedTextItemInCanvas: null,
  highlightedTextItemSection: {},
  updateTextContentLoading: false,
};

const funcSpecTextReducer = createReducer(
  initialState,
  on(FuncSpecTextActions.clearState, () => initialState),
  on(FuncSpecTextActions.loadGuidanceText, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(
    FuncSpecTextActions.loadGuidanceTextSuccess,
    (state, { guidanceText }) => ({
      ...state,
      guidanceText,
    })
  ),
  on(FuncSpecTextActions.loadGuidanceTextFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(FuncSpecTextActions.loadGuidanceText, (state) => ({
    ...state,
    loaded: false,
    error: null,
    guidanceText: null,
  })),
  on(FuncSpecTextActions.loadTextsSuccess, (state, { chapterListModel }) => ({
    ...state,
    chapterListModel,
    ...normalizeTextItems(chapterListModel.textItems),
  })),
  on(FuncSpecTextActions.loadTextsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(FuncSpecTextActions.clearSelectedTextItem, (state) => ({
    ...state,
    selectedTextItemId: null,
    selectedRequirementTemplateTextItemId: null,
    selectedTextItemInCanvas: null,
    guidanceText: null,
  })),
  on(
    FuncSpecTextActions.selectedTextItem,
    (state, { textItemId, requirementTemplateTextItemId }) => ({
      ...state,
      selectedTextItemId: textItemId,
      selectedRequirementTemplateTextItemId: requirementTemplateTextItemId,
      selectedTextItemInCanvas: textItemId,
    })
  ),
  on(FuncSpecTextActions.loadTextItemContent, (state) => ({
    ...state,
    textItemContentLoaded: false,
    error: null,
  })),
  on(
    FuncSpecTextActions.loadTextItemContentSuccess,
    (state, { textItemContent }) => ({
      ...state,
      textItemContentLoaded: true,
      textItemContent: {
        ...state.textItemContent,
        [textItemContent.functionalSpecificationTextId]:
          textItemContent.content,
      },
    })
  ),
  on(FuncSpecTextActions.loadGuidanceTextFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(FuncSpecTextActions.updateTextContent, (state) => ({
    ...state,
    updateTextContentLoading: true,
  })),
  on(
    FuncSpecTextActions.updateTextContentSuccess,
    (state, { textItemModel }) => ({
      ...state,
      textItems: {
        ...state.textItems,
        ...normalizeUpdatedTextItem({
          ...textItemModel,
          children:
            state.textItems?.[textItemModel.functionalSpecificationTextId]
              ?.children,
        }),
      },
      updateTextContentLoading: false,
      textItemContent: {
        ...state.textItemContent,
        [textItemModel.functionalSpecificationTextId]: textItemModel.content,
      },
    })
  ),
  on(FuncSpecTextActions.addCommentToSelectedTextItem, (state) => ({
    ...state,
    ...addOrSubtractTotalComments(state, state.selectedTextItemId, true),
  })),
  on(FuncSpecTextActions.subtractCommentCount, (state, { textId }) => ({
    ...state,
    ...addOrSubtractTotalComments(state, textId, false),
  })),
  on(FuncSpecTextActions.addExpandedTextItem, (state, { textItemId }) => ({
    ...state,
    expandedTextItemIds: [...state.expandedTextItemIds, textItemId],
  })),
  on(FuncSpecTextActions.removeExpandedTextItem, (state, { textItemId }) => ({
    ...state,
    expandedTextItemIds: [
      ...state.expandedTextItemIds.filter((item) => item !== textItemId),
    ],
  })),
  on(
    FuncSpecTextActions.unhighlightTextItemSection,
    (state, { textItemId }) => ({
      ...state,
      highlightedTextItemSection: {
        ...state.highlightedTextItemSection,
        [textItemId]: APITexts.ITextItemSection.none,
      },
    })
  ),
  on(
    FuncSpecTextActions.highlightTextItemSection,
    (state, { textItemId, section }) => ({
      ...state,
      highlightedTextItemSection: {
        ...state.highlightedTextItemSection,
        [textItemId]: section,
      },
    })
  ),
  on(
    FuncSpecTextActions.openTextItemAncestors,
    (state, { textItemId }) => ({
      ...state,
      expandedTextItemIds: openParent(state, textItemId)
    })
  ),
  on(
    FuncSpecTextActions.hideExpandedChildren, (state, { textItemId }) => ({
      ...state,
      expandedTextItemIds: [
        ...openParent(state, textItemId),
        textItemId
      ]
    })
  )
);

function addOrSubtractTotalComments(
  state: State,
  textId: string,
  add: boolean
) {
  return {
    textItems: {
      ...state.textItems,
      // Add one total comment to the selected text item
      [textId]: {
        ...state.textItems[textId],
        totalComments: state.textItems[textId].totalComments + (add ? 1 : -1),
      },
    },
  };
}

function openParent(
  state: State,
  textItemId: string
) {
  const parentId = state.textItems[textItemId].parentId;
  // If no parent, just open text item itself, otherwise open text item and parents
  if (!parentId) {
    return [];
  }
  return [parentId].concat(openParent(state, parentId));
}

export function reducer(state: State | undefined, action: Action) {
  return funcSpecTextReducer(state, action);
}
