<title>{{ mainTitle$ | async }}</title>

<ama-studio-navbar></ama-studio-navbar>

<div class="main-content-wrapper">
  <div class="d-flex w-100 h-100">
    <div
      class="d-flex flex-column flex-shrink-0 p-0 side-menu shadow bg-white"
      [ngClass]="{ 'side-menu-collapsed': sideMenuCollapsed | async }"
    >
      <div class="flex-grow-1">
        <ama-studio-func-spec-chapter-navigator
          [bodyTemplateRef]="bodyTemplateRef"
        ></ama-studio-func-spec-chapter-navigator>
      </div>
      <div class="d-flex flex-column mx-3">
        <div class="d-flex align-items-center">
          <span class="text-small side-menu-hide-collapsed-item flex-grow-1"
            >Minimera menyn</span
          >
          <i
            type="button"
            [ngClass]="{ collapsed: sideMenuCollapsed | async }"
            (click)="onMenuCollapse()"
            class="material-icons"
            >keyboard_double_arrow_left</i
          >
        </div>
        <hr class="side-menu-hide-collapsed-item" />
        <div class="d-flex flex-column side-menu-hide-collapsed-item">
          <span class="ps-1 text-very-small side-menu-hide-collapsed-item"
            >En del av</span
          >
          <a
            class="byggtjanst-logo side-menu-hide-collapsed-item"
            href="https://byggtjanst.se/"
            ><img
              class="byggtjanst-logo"
              src="assets/icons/SvBygg-logo_1-rad_RGB.svg"
              alt="Svensk Byggtjänst logo"
            />
          </a>
        </div>
      </div>
    </div>
    <ama-studio-func-spec-status-banner></ama-studio-func-spec-status-banner>
    <div class="d-flex flex-column w-100" [class.offset-top]="showBanner$ | async">
      <incident-widget class="bg-gray-one"></incident-widget>
      <router-outlet></router-outlet>
    </div>
    <sbui-feedback></sbui-feedback>
  </div>
</div>
<ama-studio-toast-notifications-container></ama-studio-toast-notifications-container>