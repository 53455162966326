import { APIFunctionalSpecifications } from '@ama-studio/shared';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FUNCTIONALSPECIFICATIONDETAIL_FEATURE_KEY,
  State,
  participantsAdapter,
  projectParticipantsAdapter,
} from './func-spec-overview.reducer';

const { selectAll } = participantsAdapter.getSelectors();
const { selectAll: projectSelectAll } =
  projectParticipantsAdapter.getSelectors();

// Lookup the 'FuncSpecOverview' feature state managed by NgRx
export const getFuncSpecOverviewState = createFeatureSelector<
  State
>(FUNCTIONALSPECIFICATIONDETAIL_FEATURE_KEY);

export const getFuncSpecOverviewLoaded = createSelector(
  getFuncSpecOverviewState,
  (state: State) => state.loaded
);

export const getFuncSpecOverviewError = createSelector(
  getFuncSpecOverviewState,
  (state: State) => state.error
);

export const getFunctionalSpecification = createSelector(
  getFuncSpecOverviewState,
  (state: State) => state.functionalSpecification
);

export const getProjectModel = createSelector(
  getFuncSpecOverviewState,
  (state: State) => state.projectModel
);

export const getParticipants = createSelector(
  getFuncSpecOverviewState,
  (state: State) => selectAll(state.participants)
);

export const getProjectParticipants = createSelector(
  getFuncSpecOverviewState,
  (state: State) => projectSelectAll(state.projectParticipants)
);

export const getClients = createSelector(
  getFuncSpecOverviewState,
  (state: State) => selectAll(state.participants).filter(
    p => p.role === APIFunctionalSpecifications.IUserRoleImportanceDuplicate.client
  )
);

export const getFormState = createSelector(
  getFuncSpecOverviewState,
  (state: State) => state.formState
);

export const getIsEditingName = createSelector(
  getFuncSpecOverviewState,
  (state: State) => state.isEditingName
);

export const getIsEditingDescription = createSelector(
  getFuncSpecOverviewState,
  (state: State) => state.isEditingDescription
);

export const getIsEditingContactPerson = createSelector(
  getFuncSpecOverviewState,
  (state: State) => state.isEditingContactPerson
);

export const getIsEditingAny = createSelector(
  getFuncSpecOverviewState,
  (state: State) =>
    state.isEditingName ||
    state.isEditingDescription ||
    state.isEditingContactPerson
);

export const getIsAddingParticipant = createSelector(
  getFuncSpecOverviewState,
  (state: State) => state.isAddingNewParticipant
);

export const getShowSpinnerForDelete = createSelector(
  getFuncSpecOverviewState,
  (state: State) => state.showSpinner && state.isDeleting
);

export const getIsDeleted = createSelector(
  getFuncSpecOverviewState,
  (state: State) => state.isDeleted
);

export const getApproverUserId = createSelector(
  getFuncSpecOverviewState,
  (state: State) => state.functionalSpecification.approverUserId
);
