import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromFuncSpecCore from './func-spec-core.reducer';
import * as FuncSpecCoreSelectors from './func-spec-core.selectors';

@Injectable()
export class FuncSpecCoreFacade {
  error$ = this.store.pipe(select(FuncSpecCoreSelectors.getFuncSpecError));
  funcSpec$ = this.store.pipe(select(FuncSpecCoreSelectors.getFuncSpec));

  inFuncSpecView$ = this.store.pipe(
    select(FuncSpecCoreSelectors.getInFuncSpecView)
  );

  functionalSpecificationId$ = this.store.pipe(
    select(FuncSpecCoreSelectors.getFunctionalSpecificationId)
  );

  projectModel$ = this.store.pipe(
    select(FuncSpecCoreSelectors.getProjectModel)
  );
  projectId$ = this.store.pipe(select(FuncSpecCoreSelectors.getProjectId));

  chapterId$ = this.store.pipe(select(FuncSpecCoreSelectors.getChapterId));

  selectedRightNavTab$ = this.store.pipe(
    select(FuncSpecCoreSelectors.getSelectedRightNavTab)
  );

  rightNavTabHeadline$ = this.store.pipe(
    select(FuncSpecCoreSelectors.getRightNavTabHeadline)
  );

  showCommentTab$ = this.store.pipe(
    select(FuncSpecCoreSelectors.getShowCommentTab)
  );

  showSearchResultTab$ = this.store.pipe(
    select(FuncSpecCoreSelectors.getShowSearchResultTab)
  );

  chapterType$ = this.store.pipe(select(FuncSpecCoreSelectors.getChapterType));

  requirementTemplateName$ = this.store.pipe(
    select(FuncSpecCoreSelectors.getRequirementTemplateName)
  );

  hasLicenseToRequirementTemplate$ = this.store.pipe(
    select(FuncSpecCoreSelectors.getHasLicenseToRequirementTemplate)
  );

  coClassVersion$ = this.store.pipe(
    select(FuncSpecCoreSelectors.getCoClassVersion)
  );

  approverUserId$ = this.store.pipe(
    select(FuncSpecCoreSelectors.getApproverUserId)
  );

  constructor(
    private store: Store<fromFuncSpecCore.FuncSpecCorePartialState>
  ) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }
}
