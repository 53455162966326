import { EnvironmentConfig } from '@ama-studio/shared';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AnalyticsService } from '@ama-studio/shared';

@Component({
  selector: 'ama-studio-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('webWrapper', { static: false }) wrapperElement: ElementRef;
  public innerHeight: number;

  constructor(
    private router: Router, 
    private envConfig: EnvironmentConfig,
    private gtmService: AnalyticsService
    ) {
    this.router.events.forEach(event => {
      if (event instanceof NavigationEnd){
        const gtmTag = {
          event: 'page_view',
          pageName: event.url,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Page: "AMA Funktion"
        };
        this.gtmService.pushTag(gtmTag);
      }
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setWebHeight(window.innerHeight);
  }
  ngOnInit(): void {
    this.setWebHeight(window.innerHeight);
  }
  public ngAfterViewInit(): void {
    this.setBrowserHeightVariable();
  }

  setBrowserHeightVariable() {
    this.wrapperElement?.nativeElement?.style.setProperty(
      '--browser-height',
      `${innerHeight}px`
    );
  }
  setWebHeight(height: number) {
    this.innerHeight = height - 1;
    this.setBrowserHeightVariable();
  }
}
