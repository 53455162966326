import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FuncSpecCoreFacade } from '@ama-studio/func-spec-core-state';
import { AuthFacade } from '@ama-studio/login';

@Component({
  selector: 'ama-studio-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  funcSpecId$: Observable<string>;
  isLoggedIn$: Observable<boolean>;

  constructor(
    private funcSpecCoreFacade: FuncSpecCoreFacade,
    private authFacade: AuthFacade
  ) {}

  ngOnInit(): void {
    this.funcSpecId$ = this.funcSpecCoreFacade.functionalSpecificationId$;
    this.isLoggedIn$ = this.authFacade.isLoggedIn$;
  }
}
