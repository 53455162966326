import { Component, OnInit } from '@angular/core';
import { AuthFacade } from '@ama-studio/login';
import { Observable } from 'rxjs';
import { FuncSpecStatusFacade } from '@ama-studio/func-spec-status';
import { AnalyticsService } from '@ama-studio/shared';
import { take } from 'rxjs/operators';

@Component({
  selector: 'ama-studio-navbar-name',
  templateUrl: './navbar-name.component.html',
  styleUrls: ['./navbar-name.component.scss'],
})
export class NavbarNameComponent implements OnInit {
  initials$: Observable<string>;
  fullName$: Observable<string>;
  email$: Observable<string>;

  constructor(
    private authFacade: AuthFacade,
    private statusFacade: FuncSpecStatusFacade,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.initials$ = this.authFacade.initials$;
    this.fullName$ = this.authFacade.fullName$;
    this.email$ = this.authFacade.email$;
  }

  trackExport() {
    this.statusFacade.isApproved$.pipe(take(1)).subscribe((approved) => {
      if (approved) {
        this.analyticsService.approvedExport('menu-button');
      } else {
        this.analyticsService.unapprovedExport('menu-button');
      }
    });
  }
}
