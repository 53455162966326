import {
  AnalyticsService,
  APIChapters,
  ChapterType,
  StructureChapterNumber,
} from '@ama-studio/shared';
import { Component, Input, OnInit } from '@angular/core';
import { exportPdf } from '../+state/func-spec-export.actions';
import { FuncSpecExportFacade } from '../+state/func-spec-export.facade';

@Component({
  selector: 'ama-studio-func-spec-export-button',
  templateUrl: './func-spec-export-button.component.html',
  styleUrls: ['./func-spec-export-button.component.scss'],
})
export class FuncSpecExportButtonComponent implements OnInit {
  @Input()
  structureItemId?: string;
  @Input()
  chapter!: APIChapters.IRequirementTemplateTextItemViewModel;
  chapterType: ChapterType;
  chapterNumber: number;
  enumChapterType = ChapterType;
  constructor(
    private exportFacade: FuncSpecExportFacade,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.chapterType = this.chapter
      ? this.chapter.chapterType
      : ChapterType.Structure;
    this.chapterNumber = this.chapter
      ? this.chapter.chapterNumber
      : StructureChapterNumber;
  }

  export() {
    this.exportFacade.dispatch(
      exportPdf({
        chapterNumber: this.chapterNumber,
        structureItemId: this.structureItemId,
      })
    );
    this.analyticsService.exportChapter(this.chapter?.title);
  }
}
