import { Component, OnInit } from '@angular/core'
import { FuncSpecNotificationsFacade } from '../+state/func-spec-notifications.facade'
import { Observable } from 'rxjs'
import { AnalyticsService, APINotifications } from '@ama-studio/shared';
import { FuncSpecCoreFacade } from '@ama-studio/func-spec-core-state';
import { Router } from '@angular/router';

@Component({
  selector: 'ama-studio-func-spec-notifications-list',
  templateUrl: './func-spec-notifications-list.component.html',
  styleUrls: ['./func-spec-notifications-list.component.scss']
})
export class FuncSpecNotificationsListComponent implements OnInit {

  notifications$!: Observable<APINotifications.INotificationViewModel[]>;
  loaded$!: Observable<boolean>;
  funcSpecId$!: Observable<string>;

  constructor(
    private notificationsFacade: FuncSpecNotificationsFacade,
    private funcSpecCoreFacade: FuncSpecCoreFacade,
    private router: Router,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.notifications$ = this.notificationsFacade.latestNotifications$;
    this.loaded$ = this.notificationsFacade.loaded$;
    this.funcSpecId$ = this.funcSpecCoreFacade.functionalSpecificationId$;
  }

  seeAllNotifications(funcSpecId: string) {
    this.router.navigate(['overview', funcSpecId, 'notifications']);
    this.analyticsService.clickAllNotifications();
  }
}
