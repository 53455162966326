import { Component, OnInit, Input } from '@angular/core';
import { ChaptersFacade } from '@ama-studio/func-spec-chapter-state';
import { map } from 'rxjs/operators';
import { APIChapters, ChapterType } from '@ama-studio/shared';
import { AbstractChapterRouteItem } from '../base-classes/abstract-chapter-route-item.component';

@Component({
  selector: 'ama-studio-func-spec-chapter-route-item',
  templateUrl: './func-spec-chapter-route-item.component.html',
  styleUrls: ['./func-spec-chapter-route-item.component.scss'],
})
export class FuncSpecChapterRouteItemComponent
  extends AbstractChapterRouteItem
  implements OnInit
{
  @Input()
  chapter: APIChapters.IRequirementTemplateTextItemViewModel;

  @Input()
  chapterNumber: number;

  constructor(
    chaptersFacade: ChaptersFacade,
  ) {
    super(chaptersFacade);
  }

  getRouteLink(chapter: APIChapters.IRequirementTemplateTextItemViewModel) {
    return this.functionalSpecificationId$.pipe(
      map((id) => {
        if (chapter.chapterType === ChapterType.Overview)
          return `/overview/${id}`;

        if (chapter.chapterType === ChapterType.StatusAndRevision)
          return `/revision/${id}`;

        return `/functionalspecifications/${id}/chapters/${
          chapter.chapterNumber
        }/${this.chapter.id}/${
          this.chapter.chapterType === ChapterType.Text ? 'text' : 'structure'
        }`;
      })
    );
  }
}
