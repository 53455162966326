/* eslint-disable @typescript-eslint/naming-convention */
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {GoogleTagManagerService} from 'angular-google-tag-manager';

@Injectable({
    providedIn: 'root',
  })
export class AnalyticsService{
  
  isBrowser: boolean = isPlatformBrowser(this.platformId);

  constructor(
      @Inject(PLATFORM_ID) private platformId: object,
      private gtmService: GoogleTagManagerService
    ) {}

  public sendToGoogleTagManager(tag: GTMEventParams) {
    if (this.isBrowser) {
      try {
        this.gtmService?.pushTag(tag);
      } catch {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const dataLayer = (window as any)?.dataLayer;
        if (dataLayer) {
          dataLayer.push(tag);
        }
      }
    }
  }

  public pushTag(tag: GTMEventParams) {
    this.sendToGoogleTagManager(tag);
  }

  /* All chapters - accessing textItem/structureItem */
  clickObject(source: string) {
    const event: GTMEventParams = {
      event: 'click_object',
      event_category: 'expand_object',
      event_label: 'Click on objects to expand and view its details',
      source: source
    }
    this.gtmService.pushTag(event);
  }

  // Export events
  unapprovedExport(source: string) {
    this.gtmService.pushTag({
      event: 'unapproved_export',
      event_category: 'export',
      event_label: 'Unapproved export',
      source: source,
    });
  }

  approvedExport(source: string) {
    this.gtmService.pushTag({
      event: 'approved_export',
      event_category: 'export',
      event_label: 'Approved export',
      source: source,
    });
  }

  exportChapter(chapter: string) {
    this.gtmService.pushTag({
      event: 'export_chapter',
      event_category: 'export',
      event_label: 'Exported chapter',
      chapter_name: chapter,
    });
  }

  fullExport() {
    this.gtmService.pushTag({
      event: 'full_export',
      event_category: 'export',
      event_label: 'Full export',
    });
  }

  // Invite participant
  viewRoleInfo() {
    this.gtmService.pushTag({
      event: 'view_role_info',
      event_category: 'invite_participant',
      event_label: 'Click on view role information',
    });
  }

  // Create FS
  templateInformation() {
    this.gtmService.pushTag({
      event: 'template_information',
      event_category: 'create_specification',
      event_label: 'Click on template information',
    });
  }

  ownTemplates() {
    this.gtmService.pushTag({
      event: 'own_templates',
      event_category: 'create_specification',
      event_label: 'Click on own templates',
    });
  }

  buyLicense(template: string) {
    this.gtmService.pushTag({
      event: 'buy_license',
      event_category: 'create_specification',
      event_label: 'Buy a template license',
      template: template,
    });
  }

  createSpecification(template: string) {
    this.gtmService.pushTag({
      event: 'create_specification',
      event_category: 'create_specification',
      event_label: 'Create a new specification',
      template: template,
    });
  }

  // Notifications
  clickNotification() {
    this.gtmService.pushTag({
      event: 'click_notification',
      event_category: 'notifications',
      event_label: 'Click on a notification',
    });
  }

  clickAllNotifications() {
    this.gtmService.pushTag({
      event: 'click_all_notifications',
      event_category: 'notifications',
      event_label: 'Click on see all notifications',
    });
  }

  /* Access top menu on clicking the blue apps area */
  clickTopMenu() {
    this.gtmService.pushTag({
      event: 'top_blue_menu',
      event_category: 'top_menu',
      event_label: 'Click on top menu to switch between apps',
    });
  }
  // Chapter 3
  addRequirements() {
    this.gtmService.pushTag({
      event: 'add_requirements',
      event_category: 'chapter_3',
      event_label: 'Click on add requirements',
    });
  }

  openRequirement(is_active: boolean) {
    this.gtmService.pushTag({
      event: 'open_requirement',
      event_category: 'chapter_3',
      is_active: is_active.toString(),
      event_label: 'Open a single requirement',
    });
  }

  addRequirement() {
    this.gtmService.pushTag({
      event: 'add_requirement',
      event_category: 'chapter_3',
      event_label: 'Add a single requirement',
    });
  }

  addItem() {
    this.gtmService.pushTag({
      event: 'add_item',
      event_category: 'chapter_3',
      event_label: 'Click on add item',
    });
  }

  openPanel(panel: string) {
    this.gtmService.pushTag({
      event: 'open_panel',
      event_category: 'chapter_3',
      panel,
      event_label: `Open ${panel} panel`,
    });
  }

  // Chapter 3 search
  searchChapterThree(search_term: string) {
    this.gtmService.pushTag({
      event: 'chapter3_search',
      event_category: 'chapter3_search',
      search_term,
      event_label: 'Click on Chapter 3 search icon searching for a term',
    });
  }

  filterChapterThree() {
    this.gtmService.pushTag({
      event: 'chapter3_filter',
      event_category: 'chapter3_search',
      event_label: 'Click on Chapter 3 search filter icon',
    });
  }

  selectResultChapterThree(result: string) {
    this.gtmService.pushTag({
      event: 'chapter3_select_result',
      event_category: 'chapter3_search',
      result,
      event_label: 'Select structure item from chapter 3 search results',
    });
  }

  // Status
  statusChangeClick(source: string) {
    this.gtmService.pushTag({
      event: 'status_change_click',
      event_category: 'status',
      source,
      event_label: 'Click on a status change button',
    });
  }

  // CoClass search
  searchCoClass(searchTerm: string) {
    this.gtmService.pushTag({
      event: 'search_term',
      event_category: 'coclass_search',
      searchTerm,
      event_label: 'Click on CoClass search icon searching for a term',
    });
  }

  expandCoClassSearchResult() {
    this.gtmService.pushTag({
      event: 'expand_search_result',
      event_category: 'coclass_search',
      event_label: 'Click on expand icon to view more results',
    });
  }

  addItemFromCoClassSearchResult() {
    this.gtmService.pushTag({
      event: 'add_from_result',
      event_category: 'coclass_search',
      event_label: 'Add structure item from coclass search result',
    });
  }

}


export interface GTMEventParams {
  event: string;
  search_term?: string | undefined;
  value?: number | undefined;
  event_label?: string | undefined;
  event_category?: string | undefined;
  source?: string;
  chapter_name?: string;
}

