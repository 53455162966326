<header class="header">
  <nav class="navbar navbar-expand-md bg-transparent shadow">
    <div class="d-flex align-items-center ps-0 w-100">
      <ama-studio-navbar-apps></ama-studio-navbar-apps>

      <span class="navbar-text fs-5 fw-bold ms-3 d-flex text-nowrap">
        <ng-container *ngIf="funcSpecId$ | async as funcSpecId">
          <a class="navbar-link" [routerLink]="'/overview/' + funcSpecId"
            >AMA Funktionsbeskrivning
          </a>
        </ng-container>
      </span>
      <div
        class="d-flex flex-fill justify-content-end position-relative"
        *ngIf="isLoggedIn$ | async"
      >
        <div class="d-flex align-items-center justify-content-end">
            <ama-studio-func-spec-export
              class="px-3"
            ></ama-studio-func-spec-export>
          <ama-studio-func-spec-status
            class="px-3"
          ></ama-studio-func-spec-status>
          <ama-studio-func-spec-notifications
            class="px-3"
          ></ama-studio-func-spec-notifications>
          <ama-studio-navbar-name class="px-3"></ama-studio-navbar-name>
        </div>
      </div>
    </div>
  </nav>
</header>
