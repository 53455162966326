import { Injectable } from '@angular/core';
import { EnvironmentConfig } from '@ama-studio/shared';
import { initialize, LDClient, LDFlagSet } from 'launchdarkly-js-client-sdk';
import { BehaviorSubject, Observable } from 'rxjs';

export const featureFlags = {
};

interface LDUser {
  anonymous: boolean;
  key?: string;
}

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  public flags$: Observable<LDFlagSet>;
  private _flags$: BehaviorSubject<LDFlagSet> = new BehaviorSubject<LDFlagSet>({
    /**
     * This is default values which will be returned if LaunchDarkly does not work
     */
     
  });
  private ldClient!: LDClient;

  constructor(
    private envConfig: EnvironmentConfig,
    private userId$: Observable<string | undefined>
  ) {
    this.flags$ = this._flags$.asObservable();
    this.userId$.subscribe(
      (userId) => {
        const user: LDUser = userId
          ? {
              anonymous: false,
              key: userId,
            }
          : {
              anonymous: true,
            };

        this.initialize(user);
      },
      (error) => {
        console.error(error);
        this.initialize({
          anonymous: true,
        });
      }
    );
  }

  public async isEnabled(key: string, defaultValue = false): Promise<boolean> {
    await this.ldClient.waitForInitialization();
    return this.ldClient.variation(key, defaultValue);
  }

  public setFlags() {
    this._flags$.next(this.ldClient.allFlags());
  }

  private initialize(user: LDUser) {
    this.ldClient = initialize(this.envConfig.launchDarklyClientId, user);

    this.ldClient.on('change', (flags) => {
      const updatedFlags = this._flags$.getValue();
      Object.keys(flags).forEach((flagKey: string) => {
        updatedFlags[flagKey] = flags[flagKey].current;
      });
      this._flags$.next({ ...updatedFlags });
    });

    // Set flags when client is ready
    this.ldClient.on('ready', () => {
      this.setFlags();
    });
  }
}
