import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromFuncSpecOverview from './func-spec-overview.reducer';
import * as FuncSpecOverviewSelectors from './func-spec-overview.selectors';

@Injectable()
export class FuncSpecOverviewFacade {
  loaded$ = this.store.pipe(
    select(FuncSpecOverviewSelectors.getFuncSpecOverviewLoaded)
  );

  error$ = this.store.pipe(
    select(FuncSpecOverviewSelectors.getFuncSpecOverviewError)
  );

  functionalSpecification$ = this.store.pipe(
    select(FuncSpecOverviewSelectors.getFunctionalSpecification)
  );

  projectModel$ = this.store.pipe(
    select(FuncSpecOverviewSelectors.getProjectModel)
  );

  participants$ = this.store.pipe(
    select(FuncSpecOverviewSelectors.getParticipants)
  );

  projectParticipants$ = this.store.pipe(
    select(FuncSpecOverviewSelectors.getProjectParticipants)
  );

  clients$ = this.store.pipe(
    select(FuncSpecOverviewSelectors.getClients)
  );

  formState$ = this.store.pipe(select(FuncSpecOverviewSelectors.getFormState));

  isEditingName$ = this.store.pipe(
    select(FuncSpecOverviewSelectors.getIsEditingName)
  );

  isEditingDescription$ = this.store.pipe(
    select(FuncSpecOverviewSelectors.getIsEditingDescription)
  );

  isEditingContactPerson$ = this.store.pipe(
    select(FuncSpecOverviewSelectors.getIsEditingContactPerson)
  );

  isEditingAny$ = this.store.pipe(
    select(FuncSpecOverviewSelectors.getIsEditingAny)
  );

  isAddingParticipant$ = this.store.pipe(
    select(FuncSpecOverviewSelectors.getIsAddingParticipant)
  );

  showSpinnerForDelete$ = this.store.pipe(
    select(FuncSpecOverviewSelectors.getShowSpinnerForDelete)
  );

  isDeleted$ = this.store.pipe(select(FuncSpecOverviewSelectors.getIsDeleted));

  approverUserId$ = this.store.pipe(
    select(FuncSpecOverviewSelectors.getApproverUserId)
  );

  constructor(
    private store: Store<fromFuncSpecOverview.FuncSpecOverviewPartialState>
  ) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }
}
