import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'sbui-feedback-action',
  templateUrl: './feedback-action.component.html',
  styleUrls: [
    '../styles/feedback.scss',
    '../styles/modal.scss',
    '../styles/button.scss',
  ],
})
export class FeedbackActionComponent implements OnChanges {
  @Input()
  isVisible!: boolean;

  @ViewChild('modal')
  modal!: ElementRef<HTMLElement>;

  @ViewChild('dialog')
  dialog!: ElementRef<HTMLElement>;

  @Output()
  modalVisibilityChanged = new EventEmitter<{
    action: string;
    value: boolean;
  }>();

  constructor() {}

  toggleModal(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.modalVisibilityChanged.emit({ action: 'feedback-form', value: true });
  }

  isDescendant(child: HTMLElement | null, parent: HTMLElement | null): boolean {
    let element = child;
    while (element !== null) {
      if (element.isSameNode(parent)) {
        return true;
      }

      element = element.parentElement;
    }
    return false;
  }

  isClickOnDialogComponent(
    dialog: HTMLElement | null,
    ev: MouseEvent
  ): boolean {
    return (
      ev.target === dialog ||
      this.isDescendant(ev.target as HTMLElement, dialog) === true
    );
  }

  onHideHandler(ev: MouseEvent) {
    if (!this.isVisible) {
      return;
    }

    if (!this.isClickOnDialogComponent(this.dialog.nativeElement, ev)) {
      this.modalVisibilityChanged.emit({ action: 'all', value: false });
    }
  }

  ngOnChanges(): void {
    if (this.isVisible) {
      window.addEventListener('click', this.onHideHandler.bind(this));
    }
  }
}
