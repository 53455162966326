import { Injectable } from '@angular/core';
import {
  EnvironmentConfig,
  BaseHttpService,
  APIStructureProperties,
} from '@ama-studio/shared';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FuncSpecStructurePropertiesService extends BaseHttpService {
  constructor(http: HttpClient, envConfig: EnvironmentConfig) {
    super(http, envConfig);
  }

  private apiUrl = `${this.envConfig.baseApiUrl}/functionalspecifications`;

  private getListStructurePropertiesUrl(
    funcSpecId: string,
    propertiesId: string
  ) {
    return `${this.apiUrl}/${funcSpecId}/properties/${propertiesId}`;
  }

  private getTogglePropertyUrl(funcSpecId: string) {
    return `${this.apiUrl}/${funcSpecId}/properties/toggle`;
  }

  public getListStructureProperties(funcSpecId: string, propertiesId: string) {
    return this.http.get<APIStructureProperties.PropertyContainerViewModel>(
      this.getListStructurePropertiesUrl(funcSpecId, propertiesId),
      this.headers
    );
  }

  public toggleProperty(
    toggleCommand: APIStructureProperties.ITogglePropertyStateCommand
  ) {
    return this.http.patch<APIStructureProperties.IPropertyViewModel>(
      this.getTogglePropertyUrl(toggleCommand.id),
      { ...toggleCommand },
      this.headers
    );
  }
}
