<div class="d-flex align-items-center h-100">
  <span
    class="selected-list-item side-menu-highlight"
    *ngIf="isSelected === true"
  ></span>
  <span
    class="
      badge
      d-flex
      align-items-center
      justify-content-center
      text-medium
      me-2
    "
    [ngClass]="{
      'selected ms-2': isSelected,
      'ms-3': !isSelected
    }"
    >{{ chapterNumber }}</span
  >
  <span
    id="{{ 'collapse_' + chapterNumber }}"
    class="
      text-truncate text-medium
      flex-grow-1
      align-items-center
      side-menu-hide-collapsed-item
    "
    [ngClass]="{
      'text-green fw-bold': isSelected
    }"
  >
    {{ title }}
  </span>
  <span
    *ngIf="
      chapterNumber !== nonChapterEnum.overview &&
      chapterNumber !== nonChapterEnum.statusRevision
    "
    class="multicollapse material-icons side-menu-hide-collapsed-item"
    data-icon="arrow"
  >
    keyboard_arrow_right</span
  >
  <!-- </div> -->
</div>
