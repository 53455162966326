<div #modal class="modal fade" role="dialog" aria-hidden="true">
  <ng-container *ngIf="messageType">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content shadow rounded">
        <div class="modal-header align-items-start border-bottom-0 pb-0">
          <div
            class="
              d-flex
              flex-fill flex-column
              align-items-center
              justify-content-center
              mt-5
              mb-2
            "
          >
            <ng-container [ngSwitch]="message.type">
              <ng-container *ngSwitchCase="'locked'">
                <div class="d-flex justify-content-between">
                  <span
                    class="material-icons modal-icon ms-4 mt-4 mb-3"
                    [class]="messageType.iconClass"
                  >
                    {{ messageType.icon }}
                  </span>
                  <span
                    class="material-icons modal-icon--locked ms-n1"
                    [class]="messageType.iconClass"
                  >
                    auto_awesome
                  </span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'warning'">
                <svg
                  width="58"
                  height="58"
                  viewBox="0 0 58 58"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  class="warning-icon ms-4 mt-4 mb-4"
                  [class]="messageType.iconClass"
                >
                  <path
                    d="M29.0013 0.666504C13.3613 0.666504 0.667969 13.3598 0.667969 28.9998C0.667969 44.6398 13.3613 57.3332 29.0013 57.3332C44.6413 57.3332 57.3346 44.6398 57.3346 28.9998C57.3346 13.3598 44.6413 0.666504 29.0013 0.666504ZM31.8346 43.1665H26.168V37.4998H31.8346V43.1665ZM31.8346 31.8332H26.168V14.8332H31.8346V31.8332Z"
                    fill="currentColor"
                  />
                </svg>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <span
                  class="material-icons modal-icon ms-4 mt-4 mb-3"
                  [class]="messageType.iconClass"
                >
                  {{ messageType.icon }}
                </span>
              </ng-container>
            </ng-container>
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            (click)="closeMessage()"
          ></button>
        </div>
        <div class="modal-body text-center pt-0 pb-5">
          <h5 class="modal-title">
            {{ message.message }}
          </h5>
        </div>
      </div>
    </div>
  </ng-container>
</div>
