import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FUNCSPECTEXT_FEATURE_KEY,
  State,
  FuncSpecTextPartialState,
} from './func-spec-text.reducer';

// Lookup the 'FuncSpecText' feature state managed by NgRx
export const getFuncSpecTextState = createFeatureSelector<
  State
>(FUNCSPECTEXT_FEATURE_KEY);

export const getFuncSpecTextLoaded = createSelector(
  getFuncSpecTextState,
  (state: State) => state.loaded
);

export const getUpdateTextContentLoading = createSelector(
  getFuncSpecTextState,
  (state: State) => state.updateTextContentLoading
);

export const getFuncSpecTextError = createSelector(
  getFuncSpecTextState,
  (state: State) => state.error
);

export const getGuidanceText = createSelector(
  getFuncSpecTextState,
  (state: State) => state.guidanceText
);

export const getSelectedRequirementTemplateTextItemId = createSelector(
  getFuncSpecTextState,
  (state: State) => state.selectedRequirementTemplateTextItemId
);

export const getChapterListModel = createSelector(
  getFuncSpecTextState,
  (state: State) => state.chapterListModel
);

export const getSelectedTextItemId = createSelector(
  getFuncSpecTextState,
  (state: State) => state.selectedTextItemId
);

export const getTextRootId = createSelector(
  getFuncSpecTextState,
  (state: State) => state.textRootId
);

export const getTextRootItems = createSelector(
  getFuncSpecTextState,
  (state: State) => (state?.textItems && state?.textRootId) ? state.textItems[state.textRootId] : null
);

export const getTextItems = createSelector(
  getFuncSpecTextState,
  (state: State) => state.textItems
);

export const getSelectedTextItem = createSelector(
  getTextItems,
  getSelectedTextItemId,
  (textItems, selectedTextItemId) =>
    textItems ? textItems[selectedTextItemId] : null
);

export const getTextItemContentLoaded = createSelector(
  getFuncSpecTextState,
  (state: State) => state.textItemContentLoaded
);

export const getTextItem = (id: string) =>
  createSelector(getFuncSpecTextState, (state: State) =>
    state.textItems ? state.textItems[id] : null
  );

export const getTextItemContent = (id: string) =>
  createSelector(getFuncSpecTextState, (state: State) =>
    state.textItemContent ? state.textItemContent[id] : null
  );

export const getTextItemIsExpanded = (id: string) =>
  createSelector(getFuncSpecTextState, (state: State) =>
    state.expandedTextItemIds.some((item) => item === id)
  );

export const getTextItemIsSelected = (textItemId: string) =>
  createSelector(
    getFuncSpecTextState,
    (state: State) => state.selectedTextItemInCanvas === textItemId
  );

export const getHighlightedTextItemSection = (textItemId: string) =>
  createSelector(
    getFuncSpecTextState,
    (state: State) => state.highlightedTextItemSection[textItemId]
  );
