import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import {
  map,
  withLatestFrom,
  switchMap,
  catchError,
  filter,
  mergeMap,
} from 'rxjs/operators';
import { FuncSpecStructurePropertiesService } from '../func-spec-structure-properties.service';
import { Store, select } from '@ngrx/store';

import * as funcSpecCore from '@ama-studio/func-spec-core-state';
import * as FuncSpecStructurePropertiesActions from './func-spec-structure-properties.actions';
import * as structures from '@ama-studio/func-spec-structure-state';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class FuncSpecStructurePropertiesEffects {
  loadStructureProperties = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecStructurePropertiesActions.loadStructureProperties),
      withLatestFrom(
        this.coreStore.pipe(select(funcSpecCore.getFunctionalSpecificationId)),
        this.structureStore.pipe(
          select(structures.getSelectedStructureItemInCanvasItem)
        )
      ),
      filter(([, , selectedStructure]) => !!selectedStructure),
      switchMap(([, funcSpecId, selectedStructure]) =>
        this.structurePropertiesService
          .getListStructureProperties(
            funcSpecId,
            selectedStructure.propertiesId
          )
          .pipe(
            map((propertyModel) =>
              FuncSpecStructurePropertiesActions.loadStructurePropertiesSuccess(
                {
                  propertyModel,
                  propertiesId: selectedStructure.propertiesId,
                }
              )
            ),
            catchError((error: HttpErrorResponse | null) =>
              of(
                FuncSpecStructurePropertiesActions.loadStructurePropertiesFailure(
                  { error: error?.message }
                )
              )
            )
          )
      )
    )
  );

  propertiesClicked = createEffect(() =>
    this.actions$.pipe(
      ofType(structures.propertiesClicked),
      withLatestFrom(
        this.structureStore.pipe(select(structures.getFuncSpecStructureState))
      ),
      map(([action, structureState]) => {
        // Hide properties if clicking the properties button on the showing properties-structure.
        if (
          structureState.showProperties &&
          structureState.selectedStructureItemInCanvas === action.structureId
        ) {
          return structures.hideProperties();
        }

        return structures.showProperties();
      })
    )
  );

  togglePropertyActive = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecStructurePropertiesActions.togglePropertyActive),
      withLatestFrom(
        this.coreStore.pipe(select(funcSpecCore.getFunctionalSpecificationId)),
        this.structureStore.pipe(
          select(structures.getSelectedStructureItemInCanvasItem)
        )
      ),
      mergeMap(([action, funcSpecId, selectedStructure]) =>
        this.structurePropertiesService
          .toggleProperty({
            id: funcSpecId,
            propertyContainerId: selectedStructure.propertiesId,
            propertyClassificationId: action.property.classificationId,
            state: action.isActive,
          })
          .pipe(
            map((updatedProperty) =>
              FuncSpecStructurePropertiesActions.togglePropertyActiveSuccess({
                updatedProperty,
              })
            ),
            catchError((error: HttpErrorResponse | null) =>
              of(
                FuncSpecStructurePropertiesActions.togglePropertyActiveFailure({
                  error: error?.message,
                  property: action.property,
                })
              )
            )
          )
      )
    )
  );

  loadPropertiesOnSelection = createEffect(() =>
    this.actions$.pipe(
      ofType(structures.selectedStructureItemInCanvas),
      withLatestFrom(
        this.structureStore.pipe(select(structures.getShowProperties))
      ),
      filter(([, showProperties]) => showProperties),
      map(() => FuncSpecStructurePropertiesActions.loadStructureProperties())
    )
  );

  constructor(
    private actions$: Actions,
    private coreStore: Store<funcSpecCore.FuncSpecCorePartialState>,
    private structureStore: Store<structures.FuncSpecStructurePartialState>,
    private structurePropertiesService: FuncSpecStructurePropertiesService
  ) {}
}
