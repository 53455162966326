import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuncSpecCommentsComponent } from './func-spec-comments/func-spec-comments.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromFuncSpecComments from './+state/func-spec-comments.reducer';
import { FuncSpecCommentsEffects } from './+state/func-spec-comments.effects';
import { FuncSpecCommentsFacade } from './+state/func-spec-comments.facade';
import { FuncSpecCommentItemComponent } from './func-spec-comment-item/func-spec-comment-item.component';
import { FuncSpecCommentsExpandComponent } from './func-spec-comments-expand/func-spec-comments-expand.component';
import { FuncSpecCommentsCreateComponent } from './func-spec-comments-create/func-spec-comments-create.component';
import { NgrxFormsModule } from 'ngrx-forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DirectivesModule } from '@ama-studio/shared';
import { FuncSpecCommentsDeleteComponent } from './func-spec-comments-delete/func-spec-comments-delete.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { InitialsModule, RelatedItemModule } from '@ama-studio/ui';
import { FsCommentSidepanelComponent } from './fs-comment-sidepanel/fs-comment-sidepanel.component';
import { FuncSpecCommentsButtonComponent } from './func-spec-comments-button/func-spec-comments-button.component';
import { FsSidepanelModule } from '@ama-studio/fs-sidepanel';
import { FuncSpecCommentsAddButtonComponent } from './func-spec-comments-add-button/func-spec-comments-add-button.component';
import { QuillModule } from 'ngx-quill';
import { NgxPopperjsModule } from 'ngx-popperjs'

@NgModule({
  imports: [
    CommonModule,
    NgrxFormsModule,
    RelatedItemModule,
    MatFormFieldModule,
    MatInputModule,
    DirectivesModule,
    MatProgressSpinnerModule,
    StoreModule.forFeature(
      fromFuncSpecComments.FUNCSPECCOMMENTS_FEATURE_KEY,
      fromFuncSpecComments.reducer
    ),
    EffectsModule.forFeature([FuncSpecCommentsEffects]),
    FsSidepanelModule,
    InitialsModule,
    QuillModule,
    NgxPopperjsModule
  ],
  declarations: [
    FuncSpecCommentsComponent,
    FuncSpecCommentItemComponent,
    FuncSpecCommentsExpandComponent,
    FuncSpecCommentsCreateComponent,
    FuncSpecCommentsDeleteComponent,
    FsCommentSidepanelComponent,
    FuncSpecCommentsButtonComponent,
    FuncSpecCommentsAddButtonComponent,
  ],
  exports: [
    FuncSpecCommentsComponent,
    FsCommentSidepanelComponent,
    FuncSpecCommentsDeleteComponent,
    FuncSpecCommentsButtonComponent,
    FuncSpecCommentsAddButtonComponent,
    FuncSpecCommentsCreateComponent,
  ],
  providers: [FuncSpecCommentsFacade, { provide: 'Window', useValue: window }],
})
export class FuncSpecCommentsModule {}
