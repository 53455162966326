import { createAction, props } from '@ngrx/store';
import { APIFunctionalSpecifications, APIProjects } from '@ama-studio/shared';

export const loadFuncSpecOverview = createAction(
  '[FuncSpecOverview] Load FuncSpecOverview',
  props<{ funcSpecId: string }>()
);

export const loadFuncSpecOverviewSuccess = createAction(
  '[FuncSpecOverview] Load FuncSpecOverview Success',
  props<{
    functionalSpecification: APIFunctionalSpecifications.IFunctionalSpecificationOverviewContainerViewModel;
  }>()
);

export const loadFuncSpecOverviewFailure = createAction(
  '[FuncSpecOverview] Load FuncSpecOverview Failure',
  props<{ error?: string }>()
);

export const loadFuncSpecOverviewParticipants = createAction(
  '[FuncSpecOverview] Load FuncSpecOverviewParticipants',
);

export const loadFuncSpecOverviewParticipantsSuccess = createAction(
  '[FuncSpecOverview] Load FuncSpecOverviewParticipants Success',
  props<{
    participants: APIFunctionalSpecifications.IParticipantListViewModel;
  }>()
);

export const loadFuncSpecOverviewParticipantsFailure = createAction(
  '[FuncSpecOverview] Load FuncSpecOverviewParticipants Failure',
  props<{ error?: string }>()
);

export const loadProject = createAction(
  '[FuncSpecOverview] Load Project',
  props<{ projectId: string }>()
);

export const loadProjectSuccess = createAction(
  '[FuncSpecOverview] Load Project Success',
  props<{
    projectModel: APIProjects.IProjectViewModel;
  }>()
);

export const loadProjectFailure = createAction(
  '[FuncSpecOverview] Load Project Failure',
  props<{ error?: string }>()
);

export const clearState = createAction('[FuncSpecOverview] Clear State');

export const startEditingName = createAction(
  '[FuncSpecOverview] Start Editing Name'
);

export const stopEditingName = createAction(
  '[FuncSpecOverview] Stop Editing Name'
);

export const startEditingDescription = createAction(
  '[FuncSpecOverview] Start Editing Description'
);

export const stopEditingDescription = createAction(
  '[FuncSpecOverview] Stop Editing Description'
);

export const startEditingContactPerson = createAction(
  '[FuncSpecOverview] Start Editing ContactPerson'
);

export const stopEditingContactPerson = createAction(
  '[FuncSpecOverview] Stop Editing ContactPerson'
);

export const syncFormState = createAction('[FuncSpecOverview] Sync Form State');

export const syncFormField = createAction(
  '[FuncSpecOverview] Sync Form Value',
  props<{ field: string; value?: unknown }>()
);

export const updateOverviewValue = createAction(
  '[FuncSpecOverview] Update Overview Value',
  props<{ field: string; isFromStatusPage: boolean }>()
);

export const updateOverviewValueSuccess = createAction(
  '[FuncSpecOverview] Update Overview Value Success',
  props<{
    field: string;
    functionalSpecification: APIFunctionalSpecifications.IFunctionalSpecificationOverviewContainerViewModel;
  }>()
);

export const updateOverviewValueFailure = createAction(
  '[FuncSpecOverview] Update Overview Value Failure',
  props<{ error: string | null }>()
);

export const addParticipant = createAction(
  '[FuncSpecOverview] Add Participant',
  props<{
    participant: APIFunctionalSpecifications.ISpecificationParticipantViewModel;
  }>()
);

export const editParticipant = createAction(
  '[FuncSpecOverview] Edit Participant',
  props<{
    participant: APIFunctionalSpecifications.ISpecificationParticipantViewModel;
  }>()
);

export const deleteParticipant = createAction(
  '[FuncSpecOverview] Delete Participant',
  props<{ email: string }>()
);

export const deleteFuncSpec = createAction(
  '[FuncSpecOverview] Delete FuncSpec'
);

export const deleteFuncSpecSuccess = createAction(
  '[FuncSpecOverview] Delete FuncSpec Success',
  props<{
    functionalSpecification: APIFunctionalSpecifications.IFunctionalSpecificationOverviewContainerViewModel;
  }>()
);

export const deleteFuncSpecFailure = createAction(
  '[FuncSpecOverview] Delete FuncSpec Failure',
  props<{ error: string | null }>()
);
