import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromFuncSpecStructure from './+state/func-spec-structure-state.reducer';
import { FuncSpecStructureEffects } from './+state/func-spec-structure-state.effects';
import { FuncSpecStructureFacade } from './+state/func-spec-structure-state.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromFuncSpecStructure.FUNCSPECSTRUCTURE_FEATURE_KEY,
      fromFuncSpecStructure.reducer
    ),
    EffectsModule.forFeature([FuncSpecStructureEffects]),
  ],
  providers: [FuncSpecStructureFacade],
})
export class FuncSpecStructureStateModule {}
