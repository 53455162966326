import { APIFunctionalSpecifications } from '@ama-studio/shared';
import { Action, createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';

export const authFeatureKey = 'auth';

export interface State {
  userId: string | undefined;
  isPending: boolean;
  isLoggedIn: boolean;
  tokenType: string;
  accessToken: string;
  firstName: string;
  lastName: string;
  email: string;
  hasValidLicense: boolean | null; // Null before set from API
  role: APIFunctionalSpecifications.IUserRoleImportanceDuplicate | null;
  error?: string | null;
}

export const initialState: State = {
  userId: undefined,
  isPending: false,
  isLoggedIn: false,
  tokenType: '',
  accessToken: '',
  firstName: '',
  lastName: '',
  email: '',
  /* @ts-ignore */
  hasValidLicense: null,
  role: null,
  error: null
};

export const reducer = createReducer(
  initialState,

  on(AuthActions.logIn, (state, action) => {
    const { userId, firstName, lastName, email, tokenType, accessToken } =
      action;
    return {
      ...state,
      userId,
      firstName,
      lastName,
      email,
      tokenType,
      accessToken,
      isLoggedIn: true,
    };
  }),
  on(AuthActions.logOut, () => initialState),
  on(AuthActions.setAccessToken, (state, { accessToken }) => ({
    ...state,
    accessToken,
  })),
  on(AuthActions.setIsPending, (state, { isPending }) => ({
    ...state,
    isPending,
  })),
  on(AuthActions.setHasValidLicense, (state, { hasValidLicense }) => ({
    ...state,
    hasValidLicense,
  })),
  on(AuthActions.getCurrentParticipantRoleSuccess, (state, { role }) => ({ ...state, role })),
  on(AuthActions.getCurrentParticipantRoleFailure, (state, { error }) => ({ ...state, error }))
);
