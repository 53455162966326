<div class="nav-item d-flex align-items-center shadow">
  <a
    class="navbar-apps"
    href="#"
    id="navbarDropdownMenuLink"
    role="button"
    data-bs-toggle="dropdown"
    aria-expanded="false"
    (click)="trackTopMenu()"
  >
    <i class="material-icons mt-4 ms-3">apps</i>
  </a>
  <ul
    class="dropdown-menu mt-0 pt-0 shadow"
    aria-labelledby="navbarDropdownMenuLink"
  >
    <li>
      <a
        class="dropdown-item align-items-center d-flex"
        [href]="environmentConfig.studioUrl"
        ><i class="material-icons me-2 fs-5">dashboard</i>Byggtjänst Studio</a
      >
    </li>
    <li>
      <a
        class="dropdown-item align-items-center d-flex"
        [href]="environmentConfig.studioUrl + '/projekt'"
        ><i class="material-icons me-2 fs-5">folder</i>Projekt</a
      >
    </li>
    <li>
      <a
        class="dropdown-item align-items-center d-flex"
        [href]="environmentConfig.studioUrl + '/Beskrivningar'"
        ><i class="material-icons me-2 fs-5">description</i>Beskrivningar</a
      >
    </li>
  </ul>
</div>
