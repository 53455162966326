import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromFuncSpecComments from './func-spec-comments.reducer';
import * as FuncSpecCommentsSelectors from './func-spec-comments.selectors';

@Injectable()
export class FuncSpecCommentsFacade {
  loaded$ = this.store.pipe(
    select(FuncSpecCommentsSelectors.getFuncSpecCommentsLoaded)
  );

  comments$ = this.store.pipe(
    select(FuncSpecCommentsSelectors.getAllFuncSpecComments)
  );

  selectedItemId$ = this.store.pipe(
    select(FuncSpecCommentsSelectors.getSelectedItemId)
  );

  modalComment$ = this.store.pipe(
    select(FuncSpecCommentsSelectors.getModalComment)
  );

  formState$ = this.store.pipe(select(FuncSpecCommentsSelectors.getFormState));

  showLoadingSpinner$ = this.store.pipe(
    select(FuncSpecCommentsSelectors.getShowLoadingSpinner)
  );

  isCommentSidepanelCollapsed$ = this.store.pipe(
    select(FuncSpecCommentsSelectors.isCommentSidepanelCollapsed)
  );

  type$ = this.store.pipe(select(FuncSpecCommentsSelectors.getCommentType));

  deleteCommentResult$ = this.store.pipe(
    select(FuncSpecCommentsSelectors.getDeleteCommentResult)
  );

  selectedCommentId$ = this.store.pipe(
    select(FuncSpecCommentsSelectors.getSelectedCommentId)
  );

  constructor(
    private store: Store<fromFuncSpecComments.FuncSpecCommentsPartialState>
  ) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }
}
