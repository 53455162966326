import { ChapterType } from '@ama-studio/shared';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CHAPTERS_FEATURE_KEY,
  State,
  chaptersAdapter,
} from './chapters.reducer';

// Lookup the 'Chapters' feature state managed by NgRx
export const getChaptersState =
  createFeatureSelector< State>(CHAPTERS_FEATURE_KEY);

const { selectAll, selectEntities } = chaptersAdapter.getSelectors();

export const getChaptersLoaded = createSelector(
  getChaptersState,
  (state: State) => state.loaded
);

export const getChaptersError = createSelector(
  getChaptersState,
  (state: State) => state.error
);

export const getFunctionalSpecificationId = createSelector(
  getChaptersState,
  (state: State) => state.functionalSpecificationId
);

export const getSelectedChapterId = createSelector(
  getChaptersState,
  (state: State) => state.selectedChapterId
);

export const getSelectedChapterNumber = createSelector(
  getChaptersState,
  (state: State) => state.selectedChapterNumber
);

export const getChapterIdIsSelected = (chapterId: string) =>
  createSelector(
    getChaptersState,
    (state: State) => state.selectedChapterId === chapterId
  );

export const getChapterNumberIsSelected = (chapterNumber: number) =>
  createSelector(
    getChaptersState,
    (state: State) => state.selectedChapterNumber === chapterNumber
  );

export const getAllChapters = createSelector(getChaptersState, (state: State) =>
  selectAll(state)
);

export const getChaptersEntities = createSelector(
  getChaptersState,
  (state: State) => selectEntities(state)
);

export const getExportableChapters = createSelector(
  getChaptersState,
  (state: State) => selectAll(state).filter(chapter =>
    chapter.chapterType !== ChapterType.StatusAndRevision)
);
