import { APIStatus } from '@ama-studio/shared'
import { createAction, props } from '@ngrx/store';

export const readyForReview = createAction(
  '[FuncSpecStatus] Ready For Review',
  props<{
    userId: number;
  }>()
);

export const readyForReviewSuccess = createAction(
  '[FuncSpecStatus] Ready For Review Success',
);

export const readyForReviewFailure = createAction(
  '[FuncSpecStatus] Ready For Review Failure',
  props<{ error: string | null }>()
);

export const clearReadyForReviewResult = createAction(
  '[FuncSpecStatus] Clear Ready For Review Result',
);

export const approve = createAction(
  '[FuncSpecStatus] Approve',
);

export const approveFailure = createAction(
  '[FuncSpecStatus] Approve Failure',
  props<{ error: string | null }>()
);

export const disapprove = createAction(
  '[FuncSpecStatus] Disapprove'
);

export const disapproveFailure = createAction(
  '[FuncSpecStatus] Disapprove Failure',
  props<{ error: string | null }>()
);

export const inProgress = createAction(
  '[FuncSpecStatus] In Progress'
);

export const inProgressFailure = createAction(
  '[FuncSpecStatus] In Progress Failure',
  props<{ error: string | null }>()
);

export const cancelApproval = createAction(
  '[FuncSpecStatus] Cancel Approval'
);

export const cancelApprovalFailure = createAction(
  '[FuncSpecStatus] Cancel Approval Failure',
  props<{ error: string | null }>()
);

export const setConfirmationStatus = createAction(
  '[FuncSpecStatus] Set Confirmation Status',
  props<{ status: APIStatus.Status }>()
);

export const updateStatus = createAction(
  '[FuncSpecStatus] Update Status',
  props<{
    status: APIStatus.Status,
    showBanner: boolean
  }>()
);

export const showBanner = createAction(
  '[FuncSpecStatus] Show Banner'
)

export const hideBanner = createAction(
  '[FuncSpecStatus] Hide Banner'
)
