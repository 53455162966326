import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvironmentConfig } from '../shared';

export abstract class BaseHttpService {
  constructor(
    protected http: HttpClient,
    protected envConfig: EnvironmentConfig
  ) {}

  get headers(): {
    headers?:
      | HttpHeaders
      | {
          [header: string]: string | string[];
        };
  } {
    
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return { headers: { 'x-functions-key': this.envConfig.code } };
  }
}
