import { NgrxFormsModule } from 'ngrx-forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuncSpecInviteComponent } from './func-spec-invite/func-spec-invite.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromFuncSpecInvite from './+state/func-spec-invite.reducer';
import { FuncSpecInviteEffects } from './+state/func-spec-invite.effects';
import { FuncSpecInviteFacade } from './+state/func-spec-invite.facade';
import { DirectivesModule } from '@ama-studio/shared';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { FuncSpecInviteChangeRoleComponent } from './func-spec-invite-change-role/func-spec-invite-change-role.component';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    NgrxFormsModule,
    FormsModule,
    DirectivesModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatRadioModule,
    StoreModule.forFeature(
      fromFuncSpecInvite.FUNCTIONALSPECIFICATIONDETAIL_INVITE_KEY,
      fromFuncSpecInvite.reducer
    ),
    EffectsModule.forFeature([FuncSpecInviteEffects]),
    ModalModule
  ],
  declarations: [
    FuncSpecInviteComponent,
    FuncSpecInviteChangeRoleComponent
  ],
  exports: [FuncSpecInviteComponent, FuncSpecInviteChangeRoleComponent],
  providers: [FuncSpecInviteFacade, { provide: 'Window', useValue: window }],
})
export class FuncSpecInviteModule {}
