import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as FuncSpecStructurePropertiesActions from './func-spec-structure-properties.actions';
import { APIStructureProperties } from '@ama-studio/shared';

export const FUNCSPECSTRUCTUREPROPERTIES_FEATURE_KEY =
  'funcSpecStructureProperties';

export interface State
  extends EntityState<APIStructureProperties.IPropertyViewModel> {
  selectedId: string; // which Property has been selected
  expandedId: string; // which Property has been expanded
  loaded: boolean; // has the FuncSpecStructureProperties list been loaded
  error?: string | null; // last known error (if any)

  propertiesId: string;
  inheritedRequirementId: number;
  color: string;

  addedId: string;
  highlightedPropertySection: Record<
    string,
    APIStructureProperties.IPropertySection
  >;
}

export interface FuncSpecStructurePropertiesPartialState {
  readonly [FUNCSPECSTRUCTUREPROPERTIES_FEATURE_KEY]: State;
}

export const funcSpecStructurePropertiesAdapter: EntityAdapter<APIStructureProperties.IPropertyViewModel> =
  createEntityAdapter<APIStructureProperties.IPropertyViewModel>({
    selectId: (property) => property.classificationId,
    sortComparer: (a, b) =>
      // Sort by requirementID first and then on title
      // Since we update the property before the response from server, make sure a new active property is at the end of the list
      (a.requirementId === 0 ? Number.MAX_SAFE_INTEGER : a.requirementId) -
        (b.requirementId === 0 ? Number.MAX_SAFE_INTEGER : b.requirementId) ||
      a.identifier.localeCompare(b.identifier),
  });

export const initialState: State =
  funcSpecStructurePropertiesAdapter.getInitialState({
    // set initial required properties
    loaded: false,
    selectedId: null,
    expandedId: null,
    error: null,

    propertiesId: null,
    inheritedRequirementId: null,
    color: null,

    addedId: null,
    highlightedPropertySection: {},
  });

const funcSpecStructurePropertiesReducer = createReducer(
  initialState,
  on(FuncSpecStructurePropertiesActions.clearState, () => initialState),
  on(
    FuncSpecStructurePropertiesActions.loadStructureProperties,
    () => initialState
  ),
  on(
    FuncSpecStructurePropertiesActions.loadStructurePropertiesSuccess,
    (state, { propertyModel, propertiesId }) =>
      funcSpecStructurePropertiesAdapter.setAll(
        propertyModel.properties || [],
        {
          ...state,
          propertiesId,
          inheritedRequirementId: propertyModel.inheritedRequirementId,
          color: propertyModel.color,
          loaded: true,
        }
      )
  ),
  on(
    FuncSpecStructurePropertiesActions.loadStructurePropertiesFailure,
    (state, { error }) => ({ ...state, error })
  ),
  on(
    FuncSpecStructurePropertiesActions.selectedPropertyItem,
    (state, { selectedPropertyItem }) => ({
      ...state,
      selectedId: selectedPropertyItem.classificationId,
    })
  ),
  on(
    FuncSpecStructurePropertiesActions.expandPropertyItem,
    (state, { expandedId }) => ({
      ...state,
      expandedId,
    })
  ),
  on(FuncSpecStructurePropertiesActions.collapsePropertyItem, (state) => ({
    ...state,
    expandedId: null,
  })),
  on(
    FuncSpecStructurePropertiesActions.togglePropertyActive,
    (state, { property, isActive }) =>
      funcSpecStructurePropertiesAdapter.updateOne(
        {
          id: property.classificationId,
          changes: { ...property, isActive },
        },
        {
          ...state,
          addedId: isActive ? property.classificationId : null,
          expandedId: null
        }
      )
  ),
  on(
    FuncSpecStructurePropertiesActions.togglePropertyActiveSuccess,
    (state, { updatedProperty }) =>
      funcSpecStructurePropertiesAdapter.updateOne(
        {
          id: updatedProperty.classificationId,
          changes: updatedProperty,
        },
        {
          ...state,
        }
      )
  ),
  on(
    FuncSpecStructurePropertiesActions.togglePropertyActiveFailure,
    (state, { property }) =>
      funcSpecStructurePropertiesAdapter.updateOne(
        {
          id: property.classificationId,
          changes: property,
        },
        {
          ...state,
        }
      )
  ),

  on(FuncSpecStructurePropertiesActions.addCommentToSelectedProperty, (state) =>
    funcSpecStructurePropertiesAdapter.updateOne(
      {
        id: state.selectedId,
        ...addOrSubtractTotalComments(state, state.selectedId, true),
      },
      {
        ...state,
      }
    )
  ),
  on(
    FuncSpecStructurePropertiesActions.subtractCommentCount,
    (state, { propertyId }) =>
      funcSpecStructurePropertiesAdapter.updateOne(
        {
          id: propertyId,
          ...addOrSubtractTotalComments(state, propertyId, false),
        },
        {
          ...state,
        }
      )
  ),
  on(
    FuncSpecStructurePropertiesActions.highlightPropertySection,
    (state, { propertyId, section }) => ({
      ...state,
      highlightedPropertySection: {
        [propertyId]: section,
      },
    })
  ),
  on(
    FuncSpecStructurePropertiesActions.unHighlightPropertySection,
    (state, { propertyId }) => ({
      ...state,
      highlightedPropertySection: {
        ...state.highlightedPropertySection,
        [propertyId]: APIStructureProperties.IPropertySection.none,
      },
    })
  )
);

function addOrSubtractTotalComments(
  state: State,
  propertyId: string,
  add: boolean
) {
  return {
    changes: {
      ...state.entities[propertyId],
      totalComments:
        (state.entities[propertyId]?.totalComments || 0) + (add ? 1 : -1),
    },
  };
}

export function reducer(state: State | undefined, action: Action) {
  return funcSpecStructurePropertiesReducer(state, action);
}
