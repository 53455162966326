import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { GlobalStateEffects } from './+state/global-state.effects';
import { GlobalStateFacade } from './+state/global-state.facade';
import * as fromGlobalState from './+state/global-state.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(
      fromGlobalState.GLOBALSTATE_FEATURE_KEY,
      fromGlobalState.reducer
    ),
    EffectsModule.forFeature([GlobalStateEffects]),
  ],
  providers: [GlobalStateFacade],
})
export class FsGlobalStateModule {}
