import { AuthFacade } from '../../+state/auth.facade';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ama-studio-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public showCta = false;

  constructor(public route: ActivatedRoute, private authFacade: AuthFacade) {}

  public ngOnInit() {
    this.authFacade.isPendingLogin$.subscribe((isPendingLogin) => {
      if (!isPendingLogin) {
        const requestedUrl =
          this.route.snapshot.queryParamMap.get('requestedUrl') || '/';
        this.authFacade.login(requestedUrl);
      }
    });
  }
}
