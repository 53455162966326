import { UserViewModel } from './models/sharedModels';

export namespace APIComments {
  /* eslint-disable @typescript-eslint/naming-convention */
  export enum CommentType {
    Structure = 'StructureItemEntity',
    Text = 'TextItemEntity',
    Property = 'Property',
  }
  /* eslint-enable @typescript-eslint/naming-convention */

  export interface ICommentViewModel {
    id: string;
    itemId: string;
    title: string;
    subTitle: string;
    content: string;
    subItemId: string;
    /**Color will be empty for text items as they are not color-coded */
    color: string;
    requirementId: string;
    created: UserViewModel;
    commentType: CommentType;
    createdByUserId: number;
  }

  export interface ICreateCommentCommand {
    /**FS Container Id. E.g "R296:43d05f5f-ae2f-44e7-88d4-5373c59d669f" */
    id: string;

    type: CommentType;

    chapterId: string;

    /**Id of the item the comment is attached to (Structure or Text or PropertyContainerId) */
    itemId: string;

    /**E.g. "23:CNCD". Needed for property comments (in order to find the property in the container). */
    propertyClassificationId: string;

    content: string;

    mentions?: number[];
  }

  export interface IDeleteCommentCommand {
    id: string;
    commentId: string;
  }
}
