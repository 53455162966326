import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuncSpecStatusComponent } from './func-spec-status/func-spec-status.component';
import { FuncSpecReadyForReviewButtonComponent } from './func-spec-ready-for-review-button/func-spec-ready-for-review-button.component';
import { FuncSpecStatusActionsComponent } from './func-spec-status-actions/func-spec-status-actions.component';
import { FuncSpecReadyForReviewModalComponent } from './func-spec-ready-for-review-modal/func-spec-ready-for-review-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AvatarModule } from '@ama-studio/ui';
import { MatRadioModule } from '@angular/material/radio'
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import * as fromFuncSpecStatus from './+state/func-spec-status.reducer';
import { EffectsModule } from '@ngrx/effects';
import { FuncSpecStatusEffects } from './+state/func-spec-status.effects';
import { FuncSpecStatusFacade } from './+state/func-spec-status.facade';
import { FuncSpecStatusBannerComponent } from './func-spec-status-banner/func-spec-status-banner.component';
import { FuncSpecStatusLockedComponent } from './func-spec-status-locked/func-spec-status-locked.component';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { RouterModule } from '@angular/router';
import { FuncSpecApprovalButtonsComponent } from './func-spec-approval-buttons/func-spec-approval-buttons.component';
import { FuncSpecApprovalModalComponent } from './func-spec-approval-modal/func-spec-approval-modal.component';
import { FuncSpecInProgressButtonComponent } from './func-spec-in-progress-button/func-spec-in-progress-button.component';
import { FuncSpecCancelApprovalButtonComponent } from './func-spec-cancel-approval-button/func-spec-cancel-approval-button.component';

@NgModule({
  imports: [
    CommonModule,
    ModalModule,
    AvatarModule,
    MatRadioModule,
    FormsModule,
    StoreModule.forFeature(
      fromFuncSpecStatus.FUNCSPECSTATUS_FEATURE_KEY,
      fromFuncSpecStatus.reducer
    ),
    EffectsModule.forFeature([FuncSpecStatusEffects]),
    NgxPopperjsModule,
    RouterModule
  ],
  declarations: [
    FuncSpecStatusComponent,
    FuncSpecReadyForReviewButtonComponent,
    FuncSpecStatusActionsComponent,
    FuncSpecReadyForReviewModalComponent,
    FuncSpecStatusBannerComponent,
    FuncSpecStatusLockedComponent,
    FuncSpecApprovalButtonsComponent,
    FuncSpecApprovalModalComponent,
    FuncSpecInProgressButtonComponent,
    FuncSpecCancelApprovalButtonComponent
  ],
  exports: [
    FuncSpecStatusComponent,
    FuncSpecStatusActionsComponent,
    FuncSpecStatusBannerComponent,
    FuncSpecStatusLockedComponent
  ],
  providers: [FuncSpecStatusFacade]
})
export class FuncSpecStatusModule {}
