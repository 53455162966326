/* eslint-disable @typescript-eslint/no-explicit-any */
import { APIFunctionalSpecifications } from '../interfaces/API/APIFunctionalSpecifications';
import { Classification } from '../interfaces/API/models/sharedModels';

export const nameof = <T>(name: keyof T) => name;

export const confirmPendingChanges = () =>
  confirm(
    'Är du säker på att du vill avbryta? Den senaste ändringen kommer inte att sparas.'
  );

export function sortParticipantsOnImportance(
  a: APIFunctionalSpecifications.ISpecificationParticipantViewModel,
  b: APIFunctionalSpecifications.ISpecificationParticipantViewModel
): number {
  return a.importance - b.importance;
}

export function sortProjectParticipantsOnImportance(
  a: APIFunctionalSpecifications.IProjectParticipantViewModel,
  b: APIFunctionalSpecifications.IProjectParticipantViewModel
): number {
  return a.roleName.localeCompare(b.roleName);
}

export function getClassificationId(classification: Classification) {
  const d = classification.categoryIdentifier
    ? `${classification.categoryIdentifier}${
        classification.identifier ? ':' + classification.identifier : ''
      }`
    : null;
  return d;
}

/**
 * Get corresponding color for a category identifier.
 * Colors are taken from Figma.
 */
export function getColorByCategoryIdentifier(categoryIdentifier: string) {
  switch (categoryIdentifier) {
    case 'BX':
      return '#f1f1f1';
    case 'BV':
      return '#edfdf4';
    case 'UT':
      return '#fffdf0';
    case 'FS':
    case 'KS':
    case 'KO':
      return '#f0fcff';
    default:
      return '';
  }
}

export function isAdmin(
  role: APIFunctionalSpecifications.IUserRoleImportanceDuplicate | null
): boolean {
  return (
    role ===
      APIFunctionalSpecifications.IUserRoleImportanceDuplicate
        .projectAdministrator ||
    role ===
      APIFunctionalSpecifications.IUserRoleImportanceDuplicate.administrator
  );
}

export function isClient(
  role: APIFunctionalSpecifications.IUserRoleImportanceDuplicate | null
): boolean {
  return (
    role === APIFunctionalSpecifications.IUserRoleImportanceDuplicate.client
  );
}

export function sortRevisionsByDateTime(
  revisions: APIFunctionalSpecifications.IFuncSpecRevisionViewModel[]
): APIFunctionalSpecifications.IFuncSpecRevisionViewModel[] {
  revisions = revisions.sort(
    (
      a: APIFunctionalSpecifications.IFuncSpecRevisionViewModel,
      b: APIFunctionalSpecifications.IFuncSpecRevisionViewModel
    ) => new Date(b.created).getTime() - new Date(a.created).getTime()
  );
  return revisions;
}
