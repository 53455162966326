import { APIStatus } from '@ama-studio/shared';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FUNCSPECSTATUS_FEATURE_KEY, State } from './func-spec-status.reducer';

export const getFuncSpecStatusState = createFeatureSelector<State>(
  FUNCSPECSTATUS_FEATURE_KEY
);

export const getFuncSpecStatus = createSelector(
  getFuncSpecStatusState,
  (state: State) => state.status
);

export const getFuncSpecStatusError = createSelector(
  getFuncSpecStatusState,
  (state: State) => state.error
);

export const getReadyForReviewResult = createSelector(
  getFuncSpecStatusState,
  (state: State) => state.readyForReviewResult
);

export const getShowBanner = createSelector(
  getFuncSpecStatusState,
  (state: State) => state.showBanner
);

export const getIsLocked = createSelector(
  getFuncSpecStatusState,
  (state: State) =>
    state.status !== APIStatus.Status.InProgress &&
    state.status !== APIStatus.Status.RevisionInProgress
);

export const getConfirmationStatus = createSelector(
  getFuncSpecStatusState,
  (state: State) => state.confirmationStatus
);

export const getIsApproved = createSelector(
  getFuncSpecStatusState,
  (state: State) =>
    state.status === APIStatus.Status.Approved ||
    state.status === APIStatus.Status.RevisionApproved
);
