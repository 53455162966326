/* eslint-disable @typescript-eslint/naming-convention */
import { AuthService } from './../+state/auth.service';
import { Router } from '@angular/router';
import { Observable, from, of } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { UserManager, User as OIDCUser } from 'oidc-client';
import { isPlatformBrowser } from '@angular/common';
import { AuthConfig } from '../injectables/AuthConfig';
import { EnvironmentConfig } from '@ama-studio/shared';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private _userManager!: UserManager;
  private _ticket: string | null = null;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private config: AuthConfig,
    private envConfig: EnvironmentConfig,
    private http: HttpClient,
    private router: Router,
    private authService: AuthService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this._userManager = new UserManager(this.config);
      this._userManager.events.addUserLoaded(() => {
        this._userManager
          .getUser()
          .then((user: OIDCUser | null) => {
            if (user === null) throw new Error('Argument should not be null');
            this.authService.setAccessToken(user.access_token);
          })
          .catch((err) => {
            console.log(err);
          });
      });
      if ((window as any).Cypress) {
        const user = sessionStorage.getItem(
          'oidc.user:https://login.byggtjanst.net:ama-x-contentcreator'
        );
        if (user) {
          const {
            firstName: given_name,
            lastName: family_name,
            userId: userid,
            email: sub,
          } = JSON.parse(user);
          const token = sessionStorage.getItem('cy-token');
          if (token) {
            const { access_token } = JSON.parse(token);
            setTimeout(() => {
              (this.authService as any).logIn({
                token_type: 'Bearer',
                access_token,
                profile: {
                  given_name,
                  family_name,
                  userid,
                  sub,
                },
              });
            }, 0);
          }
        }
      } else {
        this.authService.setIsPendingLogin(true);
        this._userManager
          .getUser()
          .then(this.handleUserLoggedIn)
          .catch((err) => {
            this.authService.setIsPendingLogin(false);
          });
      }
    }
  }

  public getTicket(): string | null {
    if (isPlatformBrowser(this.platformId)) {
      return this._ticket || localStorage.getItem('ticket');
    }
    return this._ticket;
  }

  public canActivate(requestedUrl: string, params?: { [key: string]: string }) {
    return this._userManager
      .getUser()
      .then((user) => {
        if (user && !user.expired) {
          return true;
        } else {
          throw new Error('Not logged in');
        }
      })
      .catch(() => {
        this.login(requestedUrl, params);
        return this.router.createUrlTree(['/login']);
      });
  }

  public login(requestedUrl: string, params?: { [key: string]: string }) {
    this.authService.setIsPendingLogin(true);
    this.startAuthentication(requestedUrl, params);
  }

  public handleAuthCallback() {
    return this.completeAuthentication();
  }

  public async handleSilentRenewCallback() {
    await this._userManager.signinSilentCallback();
    const oidcUser = await this._userManager.getUser();
    if (oidcUser) {
      this.authService.setAccessToken(oidcUser.access_token);
    }
  }

  public logout() {
    this.authService.logOut();
    this.startSignoutMainWindow();
  }

  public loginInBackground(sendToLogin = true): Observable<boolean> {
    this.authService.setIsPendingLogin(true);
    return from(
      this._userManager
        .signinSilent()
        .then(this.handleUserLoggedIn)
        .catch((err) => {
          if (sendToLogin) {
            this.login(this.router.url);
          } else {
            this.authService.setIsPendingLogin(false);
          }
          return false;
        })
    );
  }

  private handleUserLoggedIn = (user: OIDCUser | null) => {
    if (user === null) throw new Error('Argument should not be null');
    this.authService.setIsPendingLogin(false);
    if (user && user.expired !== undefined && user.expired === false) {
      this.authService.logIn(user);
      return true;
    } else {
      this.authService.logOut();
      return false;
    }
  };

  private startAuthentication(
    requestedUrl: string = '/',
    params?: { [key: string]: string }
  ) {
    this._userManager
      .signinRedirect({ state: { requestedUrl, params } })
      .then(() => {
        console.log('signinRedirect done');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  private completeAuthentication(): Promise<{
    url: string;
    href: string;
    params: { queryParams: any };
  }> {
    return this._userManager.signinRedirectCallback().then((user: OIDCUser) => {
      this.authService.logIn(user);
      /**
       * Retrieve any state variables from `user.state` property
       */
      const href = user.state.requestedUrl;
      const url = href.split('?')[0] || '/dev';
      const params = {
        queryParams: user.state.params,
        replaceUrl: true,
      };
      return this.router
        .navigate([url], params)
        .then(() => ({
          url,
          href,
          params,
        }))
        .catch((err) => {
          console.log(err);
          return { url: '/', href: '/', params };
        });
    });
  }

  private startSignoutMainWindow() {
    this._userManager
      .signoutRedirect()
      .then((resp) => {
        console.log('[start] signed out', resp);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  private endSignoutMainWindow() {
    this._userManager
      .signoutRedirectCallback()
      .then((resp) => {
        console.log('[end] signed out', resp);
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
