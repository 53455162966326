/* eslint-disable @typescript-eslint/naming-convention */
import { AuthFacade } from './+state/auth.facade';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authFacade: AuthFacade) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.authFacade.accessToken$.subscribe({
      next: (token) => {
        if (token) {
          req = req.clone({
            setHeaders: {
              Authorization: token,
            },
          });
        }
      },
    });
    return next.handle(req);
  }
}
