import { createAction, props } from '@ngrx/store';
import { IToastErrorMessage, IToastMessage } from '../interfaces';

export const showToastMessage = createAction(
  '[ToastNotifications] Show Message',
  props<{ message: IToastMessage }>()
);

export const removeToastMessage = createAction(
  '[ToastNotifications] Remove Message'
);

export const removeAllToastMessages = createAction(
  '[ToastNotifications] Remove All Messages'
);

export const showToastError = createAction(
  '[ToastNotifications] Show Error',
  props<{ errorMessage: IToastErrorMessage }>()
);

export const removeToastError = createAction(
  '[ToastNotifications] Remove Error'
);

export const showFuncSpecLockedToastMessage = createAction(
  '[ToastNotifications] Show FuncSpec Locked Message',
  props<{ funcSpecId: string }>()
);

export const showSuccessToastMessage = createAction(
  '[ToastNotifications] Show success toast Message'
);


