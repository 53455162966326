<ng-container *ngIf="status$ | async as status">
  <div
    class="d-flex align-items-center w-100 px-4 banner"
    [ngClass]="statusClassEnum[status]"
    *ngIf="showBanner$ | async"
  >
    <i class="material-icons me-2">tips_and_updates</i>
    <ng-container [ngSwitch]="status">
      <span *ngSwitchCase="statusEnum.ReadyForReview">
        Funktionsbeskrivningen är för granskning och låst för ändringar.
      </span>
      <span *ngSwitchCase="statusEnum.Approved">
        Funktionsbeskrivningen är godkänd. För att göra ändringar krävs en
        revidering.
      </span>
      <span *ngSwitchCase="statusEnum.NotApproved">
        Funktionsbeskrivningen är inte godkänd. Endast beskrivningsägare eller
        projektägare kan ändra status.
      </span>
      <span *ngSwitchCase="statusEnum.RevisionInProgress">
        Funktionsbeskrivningen är under revidering.
      </span>
      <span *ngSwitchCase="statusEnum.RevisionReadyForReview">
        Funktionsbeskrivningen är för granskning och låst för ändringar.
      </span>
      <span *ngSwitchCase="statusEnum.RevisionNotApproved">
        Revideringen är inte godkänd. Skapa revidering för att låsa upp
        funktionsbeskrivning.
      </span>
      <span *ngSwitchCase="statusEnum.RevisionApproved">
        Funktionsbeskrivningen är godkänd. För att göra ändringar krävs en revidering.
      </span>
    </ng-container>
  </div>
</ng-container>
