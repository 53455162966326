import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FeedbackFacade } from '../+state/feedback.facade';
import { createFeedback } from '../+state/feedback.actions';

@Component({
  selector: 'sbui-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['../styles/feedback.scss'],
})
export class FeedbackComponent {
  modalVisibility: Record<string, boolean> = {
    ['feedback-action']: false,
    ['feedback-form']: false,
  };

  constructor(private router: Router, private feedbackFacade: FeedbackFacade) {}

  isVisible(): boolean {
    return Object.keys(this.modalVisibility).some(
      (modal) => this.modalVisibility[modal]
    );
  }

  handleClick(event: MouseEvent, action: string) {
    event.preventDefault();
    event.stopPropagation();

    if (this.isVisible()) {
      this.setModalVisibilityAction('all', false);
    } else {
      this.setModalVisibilityAction(action);
    }
  }

  handleModalVisibilityChanged(event: {
    action: string;
    value: boolean;
  }): void {
    this.setModalVisibilityAction(event.action, event.value);
  }

  setModalVisibilityAction(action: string, value?: boolean): void {
    const visibility = value ?? !this.modalVisibility[action];
    if (action === 'all') {
      Object.keys(this.modalVisibility).forEach(
        (modal) => (this.modalVisibility[modal] = visibility)
      );
    } else {
      this.modalVisibility[action] = visibility;

      Object.keys(this.modalVisibility)
        .filter((modal) => modal !== action)
        .forEach((modal) => (this.modalVisibility[modal] = !visibility));
    }
  }

  handleSubmit(message: string) {
    if (typeof message !== 'string') {
      return;
    }
    const feedback = {
      url: this.router.url,
      title: 'AMA Funktion Studio',
      description: message,
    };
    this.feedbackFacade.dispatch(
      createFeedback({
        feedback,
      })
    );
  }
}
