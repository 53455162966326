<div class="d-flex p-3 item cursor-pointer" [ngClass]="{ 'animate': !notification.read }"
  (click)="selectNotification()">
  <div class="icon text-small d-flex align-items-center justify-content-center me-3">
    FS
  </div>
  <div class="content">
    <div class="message" [innerHTML]="notification.message"></div>
    <div class="date">{{ notification.created | date:'EE, d MMMM H:mm' }}</div>
  </div>
</div>
