import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuncSpecNotificationsIconComponent } from './func-spec-notifications-icon/func-spec-notifications-icon.component';
import { FuncSpecNotificationsListComponent } from './func-spec-notifications-list/func-spec-notifications-list.component';
import { StoreModule } from '@ngrx/store';
import * as fromFuncSpecNotifications from './+state/func-spec-notifications.reducer';
import { EffectsModule } from '@ngrx/effects';
import { FuncSpecNotificationsEffects } from './+state/func-spec-notifications.effects';
import { FuncSpecNotificationsFacade } from './+state/func-spec-notifications.facade';
import { FuncSpecNotificationsComponent } from './func-spec-notifications/func-spec-notifications.component';
import { FuncSpecNotificationItemComponent } from './func-spec-notification-item/func-spec-notification-item.component'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromFuncSpecNotifications.FUNCSPECNOTIFICATIONS_FEATURE_KEY,
      fromFuncSpecNotifications.reducer
    ),
    EffectsModule.forFeature([FuncSpecNotificationsEffects]),
    MatProgressSpinnerModule,
    RouterModule
  ],
  declarations: [
    FuncSpecNotificationsIconComponent,
    FuncSpecNotificationsListComponent,
    FuncSpecNotificationsComponent,
    FuncSpecNotificationItemComponent
  ],
  exports: [
    FuncSpecNotificationsComponent,
    FuncSpecNotificationItemComponent
  ],
  providers: [FuncSpecNotificationsFacade],

})
export class FuncSpecNotificationsModule {}
