import { createReducer, on } from '@ngrx/store';

import * as FuncSpecOverviewActions from './func-spec-overview.actions';
import {
  APIFunctionalSpecifications,
  APIProjects,
  sortProjectParticipantsOnImportance,
} from '@ama-studio/shared';
import { sortParticipantsOnImportance } from '@ama-studio/shared';
import {
  FormGroupState,
  createFormGroupState,
  onNgrxForms,
  updateGroup,
  validate,
  wrapReducerWithFormStateUpdate,
  reset,
} from 'ngrx-forms';
import { required, maxLength, pattern } from 'ngrx-forms/validation';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export interface FuncSpecDetailForm {
  name: string;
  description: string;
  contactPerson: APIFunctionalSpecifications.IFunctionalSpecificationPersonViewModel;
}

export const FUNC_SPEC_DETAIL_FORM_ID = 'funcSpecDetailForm';

export const initialFormState = createFormGroupState<FuncSpecDetailForm>(
  FUNC_SPEC_DETAIL_FORM_ID,
  {
    name: '',
    description: '',
    contactPerson: { email: '', name: '', phone: '' },
  }
);

export const validateAndUpdateForm = updateGroup<FuncSpecDetailForm>({
  name: validate(
    required,
    maxLength(35),
    pattern(new RegExp('^[ÅÄÖåäöa-zA-Z0-9 .,:-]{1,35}$'))
  ),
  description: validate(maxLength(2000)),
  contactPerson:
    updateGroup<APIFunctionalSpecifications.IFunctionalSpecificationPersonViewModel>(
      {
        name: validate(maxLength(34)),
        email: validate(maxLength(34)),
      }
    ),
});

export const FUNCTIONALSPECIFICATIONDETAIL_FEATURE_KEY = 'funcSpecOverview';

export type SpecificationParticipantsState =
  EntityState<APIFunctionalSpecifications.ISpecificationParticipantViewModel>;

export type ProjectParticipantsState =
  EntityState<APIFunctionalSpecifications.IProjectParticipantViewModel>;

export const participantsAdapter: EntityAdapter<APIFunctionalSpecifications.ISpecificationParticipantViewModel> =
  createEntityAdapter<APIFunctionalSpecifications.ISpecificationParticipantViewModel>(
    {
      selectId: (participant) => participant.email.toLowerCase(),
      sortComparer: sortParticipantsOnImportance,
    }
  );

export const projectParticipantsAdapter: EntityAdapter<APIFunctionalSpecifications.IProjectParticipantViewModel> =
  createEntityAdapter<APIFunctionalSpecifications.IProjectParticipantViewModel>(
    {
      selectId: (participant) => participant.email.toLowerCase(),
      sortComparer: sortProjectParticipantsOnImportance,
    }
  );

export interface State {
  functionalSpecification: APIFunctionalSpecifications.IFunctionalSpecificationOverviewContainerViewModel;
  projectModel: APIProjects.IProjectViewModel;
  participants: SpecificationParticipantsState;
  projectParticipants: ProjectParticipantsState;
  loaded: boolean; // has the FuncSpecOverview list been loaded
  error?: string | null; // last known error (if any)
  isEditingName: boolean;
  isEditingDescription: boolean;
  isEditingContactPerson: boolean;
  isAddingNewParticipant: boolean;
  isDeleting: boolean;
  formState: FormGroupState<FuncSpecDetailForm>;
  showSpinner: boolean;
  isDeleted: boolean;
}

export interface FuncSpecOverviewPartialState {
  readonly [FUNCTIONALSPECIFICATIONDETAIL_FEATURE_KEY]: State;
}

export const initialState: State = {
  // set initial required properties
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  functionalSpecification: null,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  projectModel: null,
  participants: participantsAdapter.getInitialState(),
  projectParticipants: projectParticipantsAdapter.getInitialState(),
  loaded: false,
  isEditingName: false,
  isEditingDescription: false,
  isEditingContactPerson: false,
  isAddingNewParticipant: false,
  isDeleting: false,
  formState: initialFormState,
  showSpinner: false,
  isDeleted: false,
};

function funcSpecUpdated(
  state: State,
  funcSpec: APIFunctionalSpecifications.IFunctionalSpecificationOverviewContainerViewModel,
  clearEditing: boolean
) {
  const newState = { ...state };
  newState.functionalSpecification = funcSpec;
  newState.showSpinner = false;

  if (clearEditing) {
    newState.loaded = true;
    newState.isEditingName = false;
    newState.isEditingDescription = false;
    newState.isEditingContactPerson = false;
  }
  return newState;
}

function funcSpecUpdateField(
  state: State,
  funcSpec: APIFunctionalSpecifications.IFunctionalSpecificationOverviewContainerViewModel,
  field: string
): State {
  if (!state || !state?.functionalSpecification) return initialState;

  const newState: State = { ...state };
  const newFuncSpec: APIFunctionalSpecifications.IFunctionalSpecificationOverviewContainerViewModel =
    {
      ...state.functionalSpecification,
    };

  switch (field) {
    case state.formState.controls.description.id:
      newFuncSpec.description = funcSpec.description;
      newState.isEditingDescription = false;
      break;
    case state.formState.controls.name.id:
      newFuncSpec.name = funcSpec.name;
      newState.isEditingName = false;
      break;
    case state.formState.controls.contactPerson.id:
      newFuncSpec.contactPerson = {
        ...funcSpec.contactPerson,
      };
      newState.isEditingContactPerson = false;
      break;
  }

  newState.functionalSpecification = newFuncSpec;
  newState.formState = reset(newState.formState);
  return newState;
}

const funcSpecOverviewReducer = createReducer(
  initialState,
  on(FuncSpecOverviewActions.loadFuncSpecOverview, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(
    FuncSpecOverviewActions.loadFuncSpecOverviewSuccess,
    (state, { functionalSpecification }) =>
      funcSpecUpdated(state, functionalSpecification, true)
  ),

  on(
    FuncSpecOverviewActions.loadFuncSpecOverviewFailure,
    (state, { error }) => ({ ...state, error })
  ),
  on(FuncSpecOverviewActions.loadFuncSpecOverviewParticipantsSuccess, (state, { participants }) => ({
    ...state,
    participants: participantsAdapter.setAll(participants.specificationParticipants || [], {
      ...state.participants
    }),
    projectParticipants: projectParticipantsAdapter.setAll(participants.projectParticipants || [], {
      ...state.projectParticipants
    })
  })),
  on(FuncSpecOverviewActions.loadFuncSpecOverviewParticipantsFailure, (state, { error }) =>
    ({ ...state, error })
  ),
  on(FuncSpecOverviewActions.clearState, () => initialState),
  on(FuncSpecOverviewActions.startEditingName, (state) => ({
    ...state,
    isEditingName: true,
  })),
  on(FuncSpecOverviewActions.stopEditingName, (state) => ({
    ...state,
    isEditingName: false,
    error: null,
  })),
  on(FuncSpecOverviewActions.startEditingDescription, (state) => ({
    ...state,
    isEditingDescription: true,
  })),
  on(FuncSpecOverviewActions.stopEditingDescription, (state) => ({
    ...state,
    isEditingDescription: false,
    error: null,
  })),
  on(FuncSpecOverviewActions.startEditingContactPerson, (state) => ({
    ...state,
    isEditingContactPerson: true,
  })),
  on(FuncSpecOverviewActions.stopEditingContactPerson, (state) => ({
    ...state,
    isEditingContactPerson: false,
    error: null,
  })),
  on(
    FuncSpecOverviewActions.updateOverviewValueSuccess,
    (state, { field, functionalSpecification }) =>
      funcSpecUpdateField(state, functionalSpecification, field)
  ),
  on(FuncSpecOverviewActions.loadProjectSuccess, (state, { projectModel }) => ({
    ...state,
    projectModel,
  })),
  on(FuncSpecOverviewActions.loadProjectFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(FuncSpecOverviewActions.addParticipant, (state, { participant }) => ({
    ...state,
    participants: participantsAdapter.upsertOne(
      participant,
      state.participants
    ),
  })),
  on(FuncSpecOverviewActions.editParticipant, (state, { participant }) => ({
    ...state,
    participants: participantsAdapter.updateOne(
      { id: participant.email.toLowerCase(), changes: participant },
      state.participants
    ),
  })),
  on(FuncSpecOverviewActions.deleteParticipant, (state, { email }) => ({
    ...state,
    participants: participantsAdapter.removeOne(email, state.participants),
  })),
  on(FuncSpecOverviewActions.deleteFuncSpec, (state) => ({
    ...state,
    showSpinner: true,
    isDeleting: true,
  })),
  on(
    FuncSpecOverviewActions.deleteFuncSpecSuccess,
    (state, { functionalSpecification }) =>
      funcSpecUpdated(
        { ...state, isDeleted: true },
        functionalSpecification,
        true
      )
  ),
  on(FuncSpecOverviewActions.deleteFuncSpecFailure, (state, { error }) => ({
    ...state,
    error,
    showSpinner: false,
  })),
  onNgrxForms()
);

export const reducer = wrapReducerWithFormStateUpdate(
  funcSpecOverviewReducer,
  // point to the form state to update
  (state) => state.formState,
  // this function is always called after the reducer
  validateAndUpdateForm
);
