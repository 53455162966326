import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  APIFunctionalSpecifications,
  BaseHttpService,
  EnvironmentConfig,
} from '@ama-studio/shared';

@Injectable({
  providedIn: 'root',
})
export class FunctionSpecificationInviteService extends BaseHttpService {
  constructor(http: HttpClient, envConfig: EnvironmentConfig) {
    super(http, envConfig);
  }

  apiUrl = `${this.envConfig.baseApiUrl}/functionalspecifications`;
  rolesUrl = `${this.envConfig.baseApiUrl}/roles`;

  private getInviteParticipantURL(fsId: string): string {
    return `${this.apiUrl}/${fsId}/participants`;
  }

  private getDeleteParticipantURL(
    fsId: string,
    userId: number,
    roleId: string
  ): string {
    return `${this.apiUrl}/${fsId}/participants/userid/${userId}/roleid/${roleId}`;
  }

  private getParticipantsURL(fsId: string): string {
    return `${this.apiUrl}/${fsId}/participants`;
  }

  private getEditParticipantRoleURL(fsId: string): string {
    return `${this.apiUrl}/${fsId}/participants`;
  }

  private getAccessibleRolesURL(): string {
    return `${this.rolesUrl}/accessibleroles`;
  }

  private getRoleDefinitionsURL(): string {
    return `${this.rolesUrl}/definition`;
  }

  public getParticipants(fsId: string) {
    return this.http.get<APIFunctionalSpecifications.IFunctionalSpecificationParticipantsViewModel>(
      this.getParticipantsURL(fsId),
      this.headers
    );
  }

  public getAccessibleRoles(projectId: string) {
    return this.http.get<
      APIFunctionalSpecifications.IAccessibleRolesViewModel[]
    >(this.getAccessibleRolesURL(), {
      params: { projectId: projectId },
      ...this.headers,
    });
  }

  public inviteParticipant(
    user: APIFunctionalSpecifications.ICreateUserRoleCommand
  ) {
    return this.http.post<APIFunctionalSpecifications.ISpecificationParticipantViewModel>(
      this.getInviteParticipantURL(user.id),
      {
        ...user,
      },
      this.headers
    );
  }

  public deleteParticipantRole(fsId: string, userId: number, roleId: string) {
    return this.http.delete(
      this.getDeleteParticipantURL(fsId, userId, roleId),
      this.headers
    );
  }

  editParticipantRole(
    patchUserCommand: APIFunctionalSpecifications.IPatchUserRoleCommand
  ): Observable<APIFunctionalSpecifications.ISpecificationParticipantViewModel> {
    return this.http.patch<APIFunctionalSpecifications.ISpecificationParticipantViewModel>(
      this.getEditParticipantRoleURL(patchUserCommand.id),
      { ...patchUserCommand },
      this.headers
    );
  }

  getRoleDefinitions(): Observable<
    APIFunctionalSpecifications.IRoleDefinitionViewModel[]
  > {
    return this.http.get<
      APIFunctionalSpecifications.IRoleDefinitionViewModel[]
    >(this.getRoleDefinitionsURL(), this.headers);
  }
}
