import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as FuncSpecInviteActions from './func-spec-invite.actions';
import { APIFunctionalSpecifications } from '@ama-studio/shared';
import { sortParticipantsOnImportance } from '@ama-studio/shared';

export enum InviteFormActicity {
  initial = 1,
  loading,
  failed,
  error,
}

export interface FuncSpecInviteForm {
  email: string;
  roleId: string;
  userId: number;
  name: string;
}

export const FUNCTIONALSPECIFICATIONDETAIL_INVITE_KEY =
  'functionalSpecificationInvite';

export interface State
  extends EntityState<APIFunctionalSpecifications.ISpecificationParticipantViewModel> {
  funcSpecId: string;
  loaded: boolean; // has the FuncSpecInvite list been loaded
  error?: string | null; // last known error (if any)
  inviteFormActivity: InviteFormActicity;
  accessibleRoles: APIFunctionalSpecifications.IAccessibleRolesViewModel[];
  invitedStatus: APIFunctionalSpecifications.IInvitedParticipantStatus | null;
  roleDefinitions: APIFunctionalSpecifications.IRoleDefinitionViewModel[];
  startEditingParticipantRole: APIFunctionalSpecifications.ISpecificationParticipantViewModel | null;
}

export interface FuncSpecInvitePartialState {
  readonly [FUNCTIONALSPECIFICATIONDETAIL_INVITE_KEY]: State;
}

export const inviteAdapter: EntityAdapter<APIFunctionalSpecifications.ISpecificationParticipantViewModel> =
  createEntityAdapter<APIFunctionalSpecifications.ISpecificationParticipantViewModel>(
    {
      selectId: (participant) => participant.email.toLowerCase(),
      sortComparer: sortParticipantsOnImportance,
    }
  );

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export const initialState: State = inviteAdapter.getInitialState({
  // set initial required properties
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  funcSpecId: null,
  functionalSpecification: null,
  loaded: false,
  inviteFormActivity: InviteFormActicity.initial,
  accessibleRoles: [],
  invitedStatus: null,
  roleDefinitions: [],
  startEditingParticipantRole: null,
});

const funcSpecInviteReducer = createReducer(
  initialState,
  on(FuncSpecInviteActions.clearState, () => initialState),
  on(FuncSpecInviteActions.setFuncSpecId, (state, { funcSpecId }) => ({
    ...state,
    funcSpecId,
  })),
  on(FuncSpecInviteActions.loadAccessibleRoles, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(
    FuncSpecInviteActions.loadAccessibleRolesSuccess,
    (state, { accessibleRoles }) => ({
      ...state,
      loaded: true,
      error: null,
      accessibleRoles: accessibleRoles,
    })
  ),
  on(FuncSpecInviteActions.loadAccessibleRolesFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  on(FuncSpecInviteActions.inviteParticipant, (state) => ({
    ...state,
    inviteFormActivity: InviteFormActicity.loading,
  })),

  on(
    FuncSpecInviteActions.inviteParticipantSuccess,
    (state) => ({
      ...state,
      invitedStatus: APIFunctionalSpecifications.IInvitedParticipantStatus.success
    })
  ),

  on(FuncSpecInviteActions.addParticipantToState, (state, { participant }) =>
    inviteAdapter.addOne(participant, {
      ...state,
    })
  ),

  on(FuncSpecInviteActions.invitationFailed, (state, { invitedStatus }) => ({
    ...state,
    inviteFormActivity: InviteFormActicity.failed,
    invitedStatus
  })),

  on(FuncSpecInviteActions.inviteParticipantError, (state, { error }) => ({
    ...state,
    inviteFormActivity: InviteFormActicity.failed,
    error,
  })),

  on(FuncSpecInviteActions.deleteParticipant, (state) => ({
    ...state,
  })),
  on(
    FuncSpecInviteActions.deleteParticipantSuccess,
    (state, { email: userEmail }) =>
      inviteAdapter.removeOne(userEmail.toLowerCase(), {
        ...state,
      })
  ),
  on(FuncSpecInviteActions.deleteParticipantFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  on(FuncSpecInviteActions.clearInvitation, (state) => ({
    ...state,
    inviteFormActivity: InviteFormActicity.initial,
    invitedStatus: null,
  })),

  on(
    FuncSpecInviteActions.startEditParticipantRole,
    (state, { participant }) => ({
      ...state,
      startEditingParticipantRole: participant,
    })
  ),
  on(FuncSpecInviteActions.stopEditParticipantRole, (state) => ({
    ...state,
    startEditingParticipantRole: null,
  })),

  on(FuncSpecInviteActions.editParticipantRole, (state) => ({
    ...state,
    inviteFormActivity: InviteFormActicity.loading,
  })),

  on(
    FuncSpecInviteActions.editParticipantRoleSuccess,
    (state, { participant }) =>
      inviteAdapter.updateOne(
        { id: participant.email.toLowerCase(), changes: participant },
        {
          ...state,
          startEditingParticipantRole: null,
        }
      )
  ),
  on(FuncSpecInviteActions.setInvitationDuplicateError, (state) => ({
    ...state,
    inviteFormActivity: InviteFormActicity.failed,
    invitedStatus: APIFunctionalSpecifications.IInvitedParticipantStatus.duplicateAccount,
  })),
  on(FuncSpecInviteActions.loadRoleDefinitions, (state) => ({
    ...state,
    error: null,
  })),
  on(
    FuncSpecInviteActions.loadRoleDefinitionsSuccess,
    (state, { roleDefinitions }) => ({
      ...state,
      error: null,
      roleDefinitions: roleDefinitions,
    })
  ),
  on(FuncSpecInviteActions.loadRoleDefinitionsFailure, (state, { error }) => ({
    ...state,
    error: error,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return funcSpecInviteReducer(state, action);
}
