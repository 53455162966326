import { createAction, props } from '@ngrx/store';
import {
  ChapterType,
  APIFunctionalSpecifications,
  APIProjects,
} from '@ama-studio/shared';
import { RightNavTab } from './func-spec-core.reducer';

export const clearState = createAction('[FuncSpecCore] Clear State');

export const inFuncSpecView = createAction('[FuncSpecCore] In Func Spec View');

export const leavingFuncSpecView = createAction(
  '[FuncSpecCore] Leaving Func Spec View'
);

export const onNavigation = createAction(
  '[FuncSpecCore] Func Spec Navigation',
  props<{
    functionalSpecificationId: string;
    chapterId: string;
    chapterNumber: number;
    chapterType: ChapterType;
  }>()
);

export const setFuncSpec = createAction(
  '[FuncSpecCore] Set Func Spec',
  props<{ functionalSpecificationId: string }>()
);

export const setChapter = createAction(
  '[FuncSpecCore] Set Chapter',
  props<{
    chapterId: string;
    chapterNumber: number;
    chapterType: ChapterType;
  }>()
);

export const setProjectId = createAction(
  '[FuncSpecCore] Set ProjectId',
  props<{ projectId: string }>()
);

export const loadProject = createAction(
  '[FuncSpecCore] Load Project',
  props<{ projectId: string }>()
);

export const loadProjectSuccess = createAction(
  '[FuncSpecCore] Load Project Success',
  props<{
    projectModel: APIProjects.IProjectViewModel;
  }>()
);

export const loadProjectFailure = createAction(
  '[FuncSpecCore] Load Project Failure',
  props<{ error?: string }>()
);

export const setRequirementTemplate = createAction(
  '[FuncSpecCore] Set RequirementTemplateId',
  props<{ requirementTemplateId: string }>()
);

export const setHasLicenseToRequirementTemplate = createAction(
  '[FuncSpecCore] Set Has License To Requirement Template',
  props<{ requirementTemplateId: string }>()
);

export const setHasLicenseToRequirementTemplateSuccess = createAction(
  '[FuncSpecCore] Set Has License To Requirement Template Success',
  props<{ hasLicenseToRequirementTemplate: boolean }>()
);

export const setHasLicenseToRequirementTemplateFailure = createAction(
  '[FuncSpecCore] Set Has License To Requirement Template Failure',
  props<{ error?: string }>()
);

export const selectTab = createAction(
  '[FuncSpecCore] Select Tab',
  props<{ tab: RightNavTab }>()
);

export const setRightNavTabHeadline = createAction(
  '[FuncSpecCore] Set Right Nav Tab Headline',
  props<{ rightNavTabHeadline: string }>()
);

export const setShowCommentTab = createAction(
  '[FuncSpecCore] Set Show Comment Tab',
  props<{ showCommentTab: boolean }>()
);

export const setShowSearchResultTab = createAction(
  '[FuncSpecCore] Set Show Search Result Tab',
  props<{ showSearchResultTab: boolean }>()
);

export const loadFuncSpec = createAction('[FuncSpecCore] Load FuncSpec');

export const loadFuncSpecSuccess = createAction(
  '[FuncSpecCore] Load FuncSpec Success',
  props<{
    funcSpec: APIFunctionalSpecifications.IFunctionalSpecificationOverviewContainerViewModel;
  }>()
);

export const loadFuncSpecFailure = createAction(
  '[FuncSpecCore] Load FuncSpec Failure',
  props<{ error?: string }>()
);
