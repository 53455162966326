import { Component, Input } from '@angular/core';

@Component({
  selector: 'sbui-user-message',
  templateUrl: './user-message.component.html',
  styleUrls: ['./user-message.component.scss'],
})
export class UserMessageComponent {
  @Input()
  heading!: string;
  @Input()
  subHeading!: string;
  @Input()
  body!: string;
  @Input()
  btnBack!: string;
  @Input()
  btnForward!: string;
  @Input()
  ctas!: { text: string; class: string; action: () => void }[];

  constructor() {}
}
