import { AuthFacade } from './../+state/auth.facade';
import { first, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LicenseGuard implements CanActivate {
  constructor(private authFacade: AuthFacade) {}

  public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.authFacade.hasValidLicense$.pipe(
      first((r) => r !== null),
      map((hasValidLicense) => {
        if (!hasValidLicense) {
          alert('Du behöver en licens för AMA Funktion Studio.');
          return false;
        }
        return true;
      })
    );
  }
}
