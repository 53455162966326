import { APIStatus } from './APIStatus';

export namespace APIFunctionalSpecifications {
  export interface IFunctionalSpecificationOverviewContainerViewModel {
    id: string;
    functionalSpecificationsId: string;
    name: string;
    description: string;
    status: APIStatus.Status;
    projectId: string;
    version: string;
    requirementTemplatePublishId: string;
    requirementTemplateName: string;
    requirementTemplateVersion: string;
    coClassVersion: string;
    contactPerson: IFunctionalSpecificationPersonViewModel;
    revisions: IFuncSpecRevisionViewModel[];
    approverUserId: number;
  }
  export interface IFunctionalSpecificationPersonViewModel {
    email: string | null;
    phone: string | null;
    name: string | null;
  }

  export enum IInvitedParticipantStatus {
    missingLicense = 1,
    success,
    missingAccount,
    duplicateAccount,
    serverError,
  }

  export enum IUserRoleImportance {
    superAdministrator = 0,
    projectAdministrator = 5,
    administrator = 15,
    client = 18,
    contributor = 20,
  }

  // The original will be changed to this duplicate once BE stops casting enums as integers
  // so we can use the same one throughout the whole project
  export enum IUserRoleImportanceDuplicate {
    superAdministrator = 'SuperAdministrator',
    projectAdministrator = 'ProjectAdministrator',
    administrator = 'Administrator',
    client = 'Client',
    contributor = 'Contributor',
  }

  export interface ISpecificationParticipantViewModel {
    fsId: string;
    userId: number;
    roleId: string;
    roleName: string;
    name: string;
    email: string;
    role: IUserRoleImportanceDuplicate;
    importance: number;
  }

  export interface IProjectParticipantViewModel {
    name: string;
    email: string;
    userId: number;
    roleId: string;
    roleName: string;
  }

  export interface IParticipantListViewModel {
    specificationParticipants: ISpecificationParticipantViewModel[];
    projectParticipants: IProjectParticipantViewModel[];
  }
  export interface IFunctionalSpecificationParticipantsViewModel {
    accessibleRoles: IAccessibleRolesViewModel[];
    functionalSpecificationParticipants: ISpecificationParticipantViewModel[];
  }

  export interface IAccessibleRolesViewModel {
    roleId: string;
    roleName: string;
    importance: number;
  }

  /* eslint-enable @typescript-eslint/naming-convention */

  export interface ICreateUserRoleCommand {
    id: string;
    email: string;
    roleId: string;
  }

  export interface IPatchUserRoleCommand {
    id: string;
    userId: number;
    roleId: string;
  }

  export interface ICreateFunctionalSpecificationCommand {
    name: string;
    description: string;
    projectId: string;
    requirementTemplateId: string;
  }

  export interface IPatchFunctionalSpecificationCommand {
    id: string;
    name: string;
    description: string;
    contactPerson: IFunctionalSpecificationPersonViewModel;
  }

  export interface IPatchFunctionalSpecificationReadyForReviewCommand {
    id: string;
    userId: number;
  }

  export interface IDeleteFunctionalSpecificationCommand {
    id: string;
  }

  export interface IRoleDefinitionViewModel {
    roleId: string;
    roleName: string;
    roleDescription: string;
    importance: number;
  }

  export interface IPdfDownloadResponse {
    success: boolean;
    url: string;
    error: string;
  }

  export interface ICreateRevisionCommand {
    id: string;
    changeText: string;
  }

  export interface IFuncSpecRevisionViewModel {
    revisionNumber: string;
    changeText: string;
    created: string;
    userId: number;
    status: APIStatus.Status;
  }
}
