import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { BaseHttpService, EnvironmentConfig, APIStatus } from '@ama-studio/shared'

@Injectable({
  providedIn: 'root'
})
export class FuncSpecStatusService extends BaseHttpService {

  constructor(http: HttpClient, envConfig: EnvironmentConfig) {
    super(http, envConfig);
  }

  private apiUrl = `${this.envConfig.baseApiUrl}/functionalspecifications`;

  private getReadyForReviewUrl() {
    return `${this.apiUrl}/statuses/readyforreview`;
  }

  private getApproveUrl(funcSpecId: string) {
    return `${this.apiUrl}/${funcSpecId}/status/approved`;
  }

  private getDisapproveUrl(funcSpecId: string) {
    return `${this.apiUrl}/${funcSpecId}/status/notapproved`;
  }

  private getInProgressUrl(funcSpecId: string) {
    return `${this.apiUrl}/${funcSpecId}/status/inprogress`;
  }

  private getCancelApprovalUrl(funcSpecId: string) {
    return `${this.apiUrl}/${funcSpecId}/statuses/readyforreview/cancel`;
  }

  public readyForReview(command: APIStatus.ReadyForReviewCommand) {
    return this.http.patch<APIStatus.Status>(
      this.getReadyForReviewUrl(),
      { ...command },
      this.headers
    );
  }

  public approve(funcSpecId: string) {
    return this.http.patch<APIStatus.Status>(
      this.getApproveUrl(funcSpecId),
      {},
      this.headers
    );
  }

  public disapprove(funcSpecId: string) {
    return this.http.patch<APIStatus.Status>(
      this.getDisapproveUrl(funcSpecId),
      {},
      this.headers
    );
  }

  public inProgress(funcSpecId: string) {
    return this.http.patch<APIStatus.Status>(
      this.getInProgressUrl(funcSpecId),
      {},
      this.headers
    );
  }

  public cancelApproval(funcSpecId: string) {
    return this.http.post<APIStatus.Status>(
      this.getCancelApprovalUrl(funcSpecId),
      {},
      this.headers
    );
  }

}
