<div class="title fw-bold my-3 mx-3">
  Notifikationer
</div>
<div class="dropdown-divider my-0"></div>
<div *ngIf="notifications$ | async as notifications">

  <ng-container *ngIf="(loaded$ | async) && notifications?.length">
    <div class="items">
      <ama-studio-func-spec-notification-item
        *ngFor="let notification of notifications"
        [notification]="notification">
      </ama-studio-func-spec-notification-item>
    </div>

    <div class="dropdown-divider my-0"></div>

    <div class="bottom mx-4 my-3">
      <ng-container *ngIf="funcSpecId$ | async as funcSpecId">
        <a (click)="seeAllNotifications(funcSpecId)" class="see-all cursor-pointer fw-bold">Se alla</a>
      </ng-container>
    </div>
  </ng-container>

  <div class="d-flex justify-content-center mt-4 mb-3" *ngIf="(loaded$ | async) === false">
    <mat-spinner
      diameter="36"
    ></mat-spinner>
  </div>

  <ng-container *ngIf="(loaded$ | async) && !notifications?.length">
    <div class="text-center mt-4 mb-3 py-4">
      <i class="material-icons empty">
        notifications
      </i>
      <div class="fw-bold text-medium">
        Det finns inga nya notifikationer tillgängliga
      </div>
    </div>
  </ng-container>
</div>
