<cdk-accordion>
  <cdk-accordion-item
    #accordionItem="cdkAccordionItem"
    (expandedChange)="onExpandedChange($event)"
  >
    <ng-content select="[header]"></ng-content>
    <ng-content select="[divider]"></ng-content>
    <ng-content select="[body]"></ng-content>
  </cdk-accordion-item>
</cdk-accordion>
