import { createAction, props } from '@ngrx/store';
import { APIChapter, APITexts } from '@ama-studio/shared';

export const clearState = createAction('[FuncSpecText] Clear State');

export const loadGuidanceText = createAction(
  '[FuncSpecText] Load GuidanceText',
  props<{ requirementTemplateTextItemId: string }>()
);

export const loadGuidanceTextSuccess = createAction(
  '[FuncSpecText] Load GuidanceText Success',
  props<{ guidanceText: APIChapter.IGuidanceWindowTextItemViewModel }>()
);

export const loadGuidanceTextFailure = createAction(
  '[FuncSpecText] Load GuidanceText Failure',
  props<{ error: string | null }>()
);

export const loadTextItemContent = createAction(
  '[FuncSpecText] Load TextItem Content',
  props<{
    functionalSpecificationTextId: string;
  }>()
);

export const loadTextItemContentSuccess = createAction(
  '[FuncSpecText] Load TextItem Content Success',
  props<{ textItemContent: APITexts.ITextItemContentViewModel }>()
);

export const loadTextItemContentFailure = createAction(
  '[FuncSpecText] Load TextItem Content Failure',
  props<{ error: string | null }>()
);

export const clearGuidanceText = createAction(
  '[FuncSpecText] Clear GuidanceText'
);

export const loadTexts = createAction('[FuncSpecText] Load Texts');

export const loadTextsSuccess = createAction(
  '[FuncSpecText] Load Texts Success',
  props<{ chapterListModel: APITexts.IChapterListViewModel }>()
);

export const loadTextsFailure = createAction(
  '[FuncSpecText] Load Texts Failure',
  props<{ error: string | null }>()
);

export const clearSelectedTextItem = createAction(
  '[FuncSpecText] Clear Selected TextItem'
);

export const selectTextItem = createAction(
  '[FuncSpecText] Select TextItem',
  props<{ textItemId: string; requirementTemplateTextItemId: string }>()
);

export const openTextItemAncestors = createAction(
  '[FuncSpecText] Open TextItem Ancestors',
  props<{ textItemId: string }>()
);

export const selectTextItemCanceled = createAction(
  '[FuncSpecText] Select TextItem Canceled'
);

export const selectedTextItem = createAction(
  '[FuncSpecText] Selected TextItem',
  props<{ textItemId: string; requirementTemplateTextItemId: string }>()
);

export const addExpandedTextItem = createAction(
  '[FuncSpecText] Add Expanded TextItem',
  props<{ textItemId: string }>()
);

export const removeExpandedTextItem = createAction(
  '[FuncSpecText] Remove Expanded TextItem',
  props<{ textItemId: string }>()
);

export const updateTextContent = createAction(
  '[FuncSpecText] Update Text Content',
  props<{ updatedText: string; functionalSpecificationTextId: string }>()
);

export const updateTextContentSuccess = createAction(
  '[FuncSpecText] Update Text Content Success',
  props<{ textItemModel: APITexts.ITextItemViewModel }>()
);

export const updateTextContentError = createAction(
  '[FuncSpecText] Update Text Content Error',
  props<{ error: string | null }>()
);

export const addCommentToSelectedTextItem = createAction(
  '[FuncSpecText] Add Comment To Selected TextItem'
);

export const subtractCommentCount = createAction(
  '[FuncSpecText] Subtract Comment Count',
  props<{
    textId: string;
  }>()
);

export const highlightTextItemSection = createAction(
  '[FuncSpecText] Highlight TextItem Section',
  props<{ textItemId: string; section: APITexts.ITextItemSection }>()
);

export const unhighlightTextItemSection = createAction(
  '[FuncSpecText] UnHighlight TextItem Section',
  props<{ textItemId: string }>()
);

export const hideExpandedChildren = createAction(
  '[FuncSpecText] Hide Expanded Children',
  props<{ textItemId: string }>()
);
