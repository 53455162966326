import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ModalModule, FeedbackModule } from '@ama-studio/ui';
import { FuncSpecChapterNavigatorComponent } from './func-spec-chapter-navigator/func-spec-chapter-navigator.component';
import { FuncSpecChapterRouteItemComponent } from './func-spec-chapter-route-item/func-spec-chapter-route-item.component';
import { FooterModule } from '@ama-studio/footer';
import { FuncSpecChapterMenuItemComponent } from './func-spec-chapter-menu-item/func-spec-chapter-menu-item.component';
import { FuncSpecChapterStateModule } from '@ama-studio/func-spec-chapter-state';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FuncSpecChapterStateModule,
    ModalModule,
    FooterModule,
    FeedbackModule,
  ],
  declarations: [
    FuncSpecChapterNavigatorComponent,
    FuncSpecChapterRouteItemComponent,
    FuncSpecChapterMenuItemComponent,
  ],
  exports: [
    FuncSpecChapterNavigatorComponent,
    FuncSpecChapterRouteItemComponent,
  ],
})
export class FuncSpecChapterModule {}
