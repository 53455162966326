import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromChapters from './+state/chapters.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ChaptersEffects } from './+state/chapters.effects';
import { ChaptersFacade } from './+state/chapters.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromChapters.CHAPTERS_FEATURE_KEY,
      fromChapters.reducer
    ),
    EffectsModule.forFeature([ChaptersEffects]),
  ],
  providers: [ChaptersFacade],
})
export class FuncSpecChapterStateModule {}
