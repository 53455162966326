import { Component, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { FuncSpecNotificationsFacade } from '../+state/func-spec-notifications.facade'

@Component({
  selector: 'ama-studio-func-spec-notifications-icon',
  templateUrl: './func-spec-notifications-icon.component.html',
  styleUrls: ['./func-spec-notifications-icon.component.scss']
})
export class FuncSpecNotificationsIconComponent implements OnInit {

  unreadCount$!: Observable<number>;

  constructor(private notificationsFacade: FuncSpecNotificationsFacade) {}

  ngOnInit(): void {
    this.unreadCount$ = this.notificationsFacade.unreadCount$;
  }

}
