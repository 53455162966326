import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { withLatestFrom, filter, switchMap, map } from 'rxjs/operators';

import * as fromChapters from './chapters.reducer';
import * as ChaptersActions from './chapters.actions';
import { ChaptersService } from '../chapters.service';
import { Store, select } from '@ngrx/store';
import { getChaptersLoaded } from './chapters.selectors';
import { clearActiveSection } from '@ama-studio/fs-global-state';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ChaptersEffects {
  loadChapters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChaptersActions.loadChapters),
      withLatestFrom(this.store.pipe(select(getChaptersLoaded))),
      filter(([, loaded]) => !loaded),
      fetch({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        run: (action, _loaded) =>
          this.chaptersService
            .getChaptersOverviewModel(action.funcSpecId)
            .pipe(
              map((overviewModel) =>
                ChaptersActions.loadChaptersSuccess({ overviewModel })
              )
            ),
        onError: (action, error: HttpErrorResponse | null) => {
          console.error('Error', error);
          return ChaptersActions.loadChaptersFailure({ error: error?.message });
        },
      })
    )
  );

  clearState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChaptersActions.clearState),
      switchMap(() => [clearActiveSection()])
    )
  );

  constructor(
    private actions$: Actions,
    private chaptersService: ChaptersService,
    private store: Store<fromChapters.ChaptersPartialState>,
  ) {}
}
