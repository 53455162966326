import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar.component';
import { RouterModule } from '@angular/router';
import { NavbarNameComponent } from './navbar-name/navbar-name.component';
import { NavbarInviteComponent } from './navbar-invite/navbar-invite.component';
import { FuncSpecInviteModule } from '@ama-studio/func-spec-invite';
import { NavbarAppsComponent } from './navbar-apps/navbar-apps.component';
import { FuncSpecNotificationsModule } from '@ama-studio/func-spec-notifications';
import { FuncSpecStatusModule } from '@ama-studio/func-spec-status';
import { FuncSpecExportModule } from '@ama-studio/func-spec-export';

export * from './navbar.component';
export * from './navbar-name/navbar-name.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FuncSpecInviteModule,
    FuncSpecNotificationsModule,
    FuncSpecStatusModule,
    FuncSpecExportModule
  ],
  declarations: [
    NavbarComponent,
    NavbarNameComponent,
    NavbarInviteComponent,
    NavbarAppsComponent
  ],
  exports: [
    NavbarComponent,
    NavbarNameComponent,
    NavbarInviteComponent,
    NavbarAppsComponent,
  ],
})
export class NavbarModule {}
