import { APIFeedback as FeedbackActions } from '@ama-studio/shared';
import { createAction, props } from '@ngrx/store';

export const createFeedback = createAction(
  '[Feedback] Create Feedback',
  props<{
    feedback: FeedbackActions.ICreateFeedbackCommand;
  }>()
);

export const createFeedbackSuccess = createAction(
  '[Feedback] Create Feedback Success',
  props<{
    feedback: FeedbackActions.ICreateFeedbackCommand;
  }>()
);

export const createFeedbackFailure = createAction(
  '[Feedback] Create Feedback Failure',
  props<{ error: string | null }>()
);
