import { Classification } from './models/sharedModels';

export namespace APIStructures {
  export interface IFunctionalSpecificationStructureItemViewModel
    extends Classification {
    id: string;
    requirementTemplateStructureItemId: string;
    requirementTemplateId: string;
    title: string;
    colorCode: string;
    categoryName: string;
    categoryIdentifier: string;
    identifier: string;
    popularName: string;
    requirementId: number;
    parentId: string;
    isRoot: boolean;
    isFromClassification: boolean;
    children: string[];
    propertiesId: string;
    hasChildren: boolean;
    totalComments: number;
  }

  export interface ICreateStructureItemFromTemplateCommand {
    requirementTemplateId: string;
    /** FS Container ID */
    id: string;
    /**	Added item where index 0 is top parent and last index is the item added by user */
    structureTree: string[];
    /** Parent ID in the FS canvas */
    parentId: string;
  }

  export interface ICreateStructureItemFromTemplateWithParentCommand {
    /** FS Container ID */
    id: string;
    /** Id of item in the requirement template*/
    itemId: string;
    /** Parent ID in the FS canvas */
    parentId: string;
    /** The requirement template to use */
    requirementTemplatePublishId: string;
  }

  export interface IPatchPopularNameCommand {
    /**FS Container id */
    id: string;

    /**The structure item id to modify popular name */
    itemId: string;

    /**The popular name */
    popularName: string;
  }

  export interface IAddItemFromClassificationCommand {
    id: string;
    parentId: string;
    requirementTemplatePublishedId: string;
    classificationId: string;
  }
  
  export interface IAddRootItemFromClassificationCommand {
    id: string;
    requirementTemplatePublishedId: string;
    classificationId: string;
  }

  export interface IFunctionalSpecificationStructureDuplicatesModel {
    breadCrumbs: string[];
    duplicates: IFunctionalSpecificationStructureItemViewModel[];
  }

  export enum IStructureItemSection {
    none = 'None',
    info = 'Information',
    activeProperties = 'ActiveProperties',
    inactiveProperties = 'InactiveProperties',
    property = 'Property',
    popularName = 'PopularName',
  }
}
