import { OnInit, Directive } from '@angular/core';
import { Observable } from 'rxjs';
import { ChaptersFacade } from '@ama-studio/func-spec-chapter-state';
import { APIChapters } from '@ama-studio/shared';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractChapterRouteItem implements OnInit {
  functionalSpecificationId$: Observable<string>;
  chapterIdIsSelected$: (chapterId: string) => Observable<boolean>;

  constructor(
    protected chaptersFacade: ChaptersFacade,
  ) {}

  ngOnInit(): void {
    this.functionalSpecificationId$ =
      this.chaptersFacade.functionalSpecificationId$;
    this.chapterIdIsSelected$ = this.chaptersFacade.chapterIdIsSelected$;
  }

  abstract getRouteLink(
    chapter: APIChapters.IRequirementTemplateTextItemViewModel,
  );
}
