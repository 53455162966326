import { Component } from '@angular/core';
import { AnalyticsService, EnvironmentConfig } from '@ama-studio/shared';

@Component({
  selector: 'ama-studio-navbar-apps',
  templateUrl: './navbar-apps.component.html',
  styleUrls: ['./navbar-apps.component.scss'],
})
export class NavbarAppsComponent {
  constructor(
    public environmentConfig: EnvironmentConfig,
    public analyticsService: AnalyticsService
  ) {}

  trackTopMenu() {
    this.analyticsService.clickTopMenu();
  }
}
