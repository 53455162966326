import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromFuncSpecNotifications from './func-spec-notifications.reducer';
import * as FuncSpecNotificationsSelectors from './func-spec-notifications.selectors';

@Injectable()
export class FuncSpecNotificationsFacade {

  loaded$ = this.store.pipe(
    select(FuncSpecNotificationsSelectors.getFuncSpecNotificationsLoaded)
  );

  error$ = this.store.pipe(
    select(FuncSpecNotificationsSelectors.getFuncSpecNotificationsError)
  );

  unreadCount$ = this.store.pipe(
    select(FuncSpecNotificationsSelectors.getFuncSpecNotificationsUnreadCount)
  );

  latestNotifications$ = this.store.pipe(
    select(FuncSpecNotificationsSelectors.getLatestFuncSpecNotifications)
  );

  allNotifications$ = this.store.pipe(
    select(FuncSpecNotificationsSelectors.getAllFuncSpecNotifications)
  );

  constructor(
    private store: Store<fromFuncSpecNotifications.FuncSpecNotificationsPartialState>
  ) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }
}
