export interface UserViewModel {
  by: string;
  when: string;
}

/* eslint-disable @typescript-eslint/naming-convention */
export enum FunctionalSpecificationStatus {
  InProgress,
}

export const StructureChapterNumber = 3;

export enum ChapterType {
  Text = 'Text',
  Structure = 'Structure',
  Overview = 'Overview',
  StatusAndRevision = 'Status',
}
/* eslint-enable @typescript-eslint/naming-convention */

export interface Classification {
  /**
   * Identifier for the category, e.g. "UT". This is the first part of the ClassificationId,
   *  which is in the format Category:Classification, e.g. "UT:AAA".
   */
  categoryIdentifier: string;
  /**
   * Identifier for the classification, e.g. "AAA". This is the second part of the ClassificationId,
   *  which is in the format Category:Classification, e.g. "UT:AAA".
   */
  identifier: string;
}

export interface MetaDataGuidanceViewModel {
  /**
   * Guidance text from the template
   */
  guidance: string;
  /**
   * Proposed value text from the template
   */
  proposedValue: string;
  /**
   * Source text from the template
   */
  source: string;
  /**
   * Verification text from the template
   */
  verification: string;
}

/* eslint-disable @typescript-eslint/naming-convention */
export enum ExtNotificationType {
  Information = 'Information',
  Warning = 'Warning',
}

/**
This list is used to handle left menu along with requirement template chapters
Chapters 1 to 6 are from requirement template
 */
export enum NonChapter {
  overview = 0,
  statusRevision = 5,
}
