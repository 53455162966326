import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromFuncSpecStructureProperties from './+state/func-spec-structure-properties.reducer';
import { FuncSpecStructurePropertiesEffects } from './+state/func-spec-structure-properties.effects';
import { FuncSpecStructurePropertiesFacade } from './+state/func-spec-structure-properties.facade';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromFuncSpecStructureProperties.FUNCSPECSTRUCTUREPROPERTIES_FEATURE_KEY,
      fromFuncSpecStructureProperties.reducer
    ),
    EffectsModule.forFeature([FuncSpecStructurePropertiesEffects]),
  ],
  providers: [FuncSpecStructurePropertiesFacade],
})
export class FuncSpecStructurePropertiesStateModule {}
