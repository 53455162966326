import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'ama-studio-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss'],
})
export class AuthCallbackComponent implements OnInit {
  public requestedUrl!: string;
  public showCta = false;

  constructor(private loginService: LoginService, private router: Router) {}

  public ngOnInit() {
    this.loginService
      .handleAuthCallback()
      .then(({ href, url, params }) => {
        this.requestedUrl = href;
        setTimeout(() => {
          // If user has not been redirected yet, navigate to requestedUrl
          if (this.router.url !== href) {
            console.log(
              'Automatic redirect in auth-callback component to',
              url,
              'with params',
              params
            );
          }
        }, 1500);
      })
      .catch((err) => {
        console.error(err);
      });
  }
}
