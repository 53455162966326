import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FUNCSPECNOTIFICATIONS_FEATURE_KEY,
  funcSpecNotificationsAdapter,
  State
} from './func-spec-notifications.reducer';

export const getFuncSpecNotificationsState = createFeatureSelector<State>(
  FUNCSPECNOTIFICATIONS_FEATURE_KEY
);

const { selectAll } = funcSpecNotificationsAdapter.getSelectors();

export const getFuncSpecNotificationsLoaded = createSelector(
  getFuncSpecNotificationsState,
  (state: State) => state.loaded
);

export const getFuncSpecNotificationsError = createSelector(
  getFuncSpecNotificationsState,
  (state: State) => state.error
);

export const getFuncSpecNotificationsUnreadCount = createSelector(
  getFuncSpecNotificationsState,
  (state: State) => state.unreadCount
);

export const getLatestFuncSpecNotifications = createSelector(
  getFuncSpecNotificationsState,
  (state: State) => selectAll(state).slice(0, 20)
);

export const getAllFuncSpecNotifications = createSelector(
  getFuncSpecNotificationsState,
  (state: State) => selectAll(state)
);

export const getSelectedNotification = createSelector(
  getFuncSpecNotificationsState,
  (state: State) => state.selectedNotification
);
