export namespace APIStructureProperties {
  export interface PropertyContainerViewModel {
    /**
     * The inherited requirement id from the structure item

     */
    inheritedRequirementId: number;
    properties: IPropertyViewModel[];
    color: string;
  }

  export interface IPropertyViewModel {
    /**
     *  Reference the data entity for the FS which contains all meta data for this property
     */
    metaDataReferenceId: string;

    /**
     * Reference the internal fields that are only accessible for users with correct role
     */
    metaDataInternalReferenceId: string;

    /**
     * Reference to the RowKey for the structure item requirement template meta data to get guidance texts
     */
    requirementTemplateMetaDataId: string;

    /**
     * The property identifier (eg. 23:CNDN)
     */
    classificationId: string;

    /**
     * The identifier short (eg. CNDN)
     */
    identifier: string;

    /**
     * The title (eg. Utformning)
     */
    title: string;

    /**
     * Unique requirement id (eg 52.1)
     */
    requirementId: number;

    /**
     *  If this property is toggled as active or not by the user
     */
    isActive: boolean;

    totalComments: number;
  }

  interface CreatedInfo {
    created: {
      by: string;
      when: string;
    };
    updated: {
      by: string;
      when: string;
    };
  }
  export interface IPropertyMetadataEntity extends CreatedInfo {
    propertyMetaDataId: string;
    requirementValue: string;
    requirementDescription: string;
    source: string;
    verification: string;
    unit: string;
  }

  export interface IPropertyMetadataInternalEntity extends CreatedInfo {
    propertyMetadataInternalId: string;
    motiveForRequirement: string;
    risks: string;
    notes: string;
    responsibleForRequirement: string;
  }

  export interface IPropertyMetadataEntityCommand {
    id: string;
    propertyMetadataId: string;
    requirementValue: string;
    requirementDescription: string;
    source: string;
    verification: string;
  }

  export interface IPropertyMetadataInternalEntityCommand {
    id: string;
    propertyMetadataInternalId: string;
    motiveForRequirement: string;
    risks: string;
    notes: string;
    responsibleForRequirement: string;
  }

  export interface ITogglePropertyStateCommand {
    /**
     * 	The functional specification container Id
     */
    id: string;

    /**
     * The identifier for the property (eg. 23:CNDN)
     */
    propertyClassificationId: string;
    /**
     * 	The container that this identifier exists in
     */
    propertyContainerId: string;

    /**
     * If the property should be toggled as active or inactive
     */
    state: boolean;
  }

  export enum IPropertySection {
    none = 'None',
    info = 'Information',
    requirementDescription = 'RequirementDescription',
    requirementValue = 'RequirementValue',
    verification = 'Verification',
    source = 'Source',
    motiveForRequirement = 'MotiveForRequirement',
    risks = 'Risks',
    notes = 'Notes',
    responsibleForRequirement = 'ResponsibleForRequirement',
    activated = 'Activated',
  }
}
