import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import {
  map,
  withLatestFrom,
  filter,
  switchMap,
  catchError,
  take,
  mergeMap,
  delay,
} from 'rxjs/operators';

import * as fromFuncSpecComments from './func-spec-comments.reducer';
import * as commentSelectors from './func-spec-comments.selectors';
import * as structures from '@ama-studio/func-spec-structure-state';
import * as text from '@ama-studio/func-spec-text-state';
import * as funcSpecCore from '@ama-studio/func-spec-core-state';
import * as properties from '@ama-studio/func-spec-structure-properties-state';
import * as FuncSpecCommentsActions from './func-spec-comments.actions';
import { RightNavTab } from '@ama-studio/func-spec-core-state';
import { FuncSpecCommentsService } from '../../func-spec-comments.service';
import { Store, select } from '@ngrx/store';
import { APIComments } from '@ama-studio/shared';
import { of } from 'rxjs';
import { ResetAction, SetValueAction } from 'ngrx-forms';
import { HttpErrorResponse } from '@angular/common/http';
import * as fromFuncSpecOverview from '@ama-studio/func-spec-overview-state';
import { clearSelectedStructureItemInCanvas } from '@ama-studio/func-spec-structure-state';

@Injectable()
export class FuncSpecCommentsEffects {
  toggleCommentPanel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecCommentsActions.toggleCommentPanel),
      withLatestFrom(
        this.commentsStore.select(commentSelectors.getFuncSpecCommentsState)
      ),
      switchMap(([, state]) =>
        state.commentSidepanelCollapsed
          ? []
          : [FuncSpecCommentsActions.loadAllComments()]
      )
    )
  );

  loadAllComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecCommentsActions.loadAllComments),
      withLatestFrom(
        this.coreStore.pipe(select(funcSpecCore.getFuncSpecCoreState))
      ),
      fetch({
        run: (action, state) =>
          this.commentsService
            .getAllComments(state.functionalSpecificationId, state.chapterId)
            .pipe(
              map((comments) =>
                FuncSpecCommentsActions.loadAllCommentsSuccess({
                  comments,
                })
              )
            ),
        onError: (action, error: HttpErrorResponse | null) => {
          console.error('Error', error);
          FuncSpecCommentsActions.loadAllCommentsFailure({
            error: error?.message,
          });
        },
      })
    )
  );

  loadCommentsForItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecCommentsActions.loadCommentsForItem),
      withLatestFrom(
        this.coreStore.pipe(select(funcSpecCore.getFunctionalSpecificationId)),
        this.commentsStore.pipe(
          select(commentSelectors.getFuncSpecCommentsState)
        )
      ),
      switchMap(([, funcSpecId, commentState]) =>
        this.commentsService
          .getCommentsForItem(
            funcSpecId,
            commentState.selectedItemId,
            commentState.selectedSubItemId
          )
          .pipe(
            map((comments) =>
              FuncSpecCommentsActions.loadCommentsForItemSuccess({
                comments,
              })
            ),
            catchError((error: HttpErrorResponse | null) =>
              of(
                FuncSpecCommentsActions.loadCommentsForItemFailure({
                  error: error?.message,
                })
              )
            )
          )
      )
    )
  );

  createComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecCommentsActions.createComment),
      withLatestFrom(
        this.coreStore.pipe(select(funcSpecCore.getFuncSpecCoreState)),
        this.commentsStore.pipe(
          select(commentSelectors.getFuncSpecCommentsState)
        ),
        this.propertiesStore.pipe(select(properties.getSelected)),
        this.overviewStore.pipe(select(fromFuncSpecOverview.getParticipants)),
      ),
      switchMap(([, coreState, commentsState, selectedProperty, participants]) => {
        const mentions = [];
        let strippedComment = commentsState.formState.controls.newComment.value.replace(/<[^>]*>?/gm, '');
        participants.forEach(p => {
          strippedComment = strippedComment.replace(`@${p.name}`, `<span data-userid='${p.userId}'>@${p.name}</span>`)
          const index = strippedComment.indexOf(`@${p.name}`);
          if (index >= 0) {
            mentions.push({ index, userId: p.userId });
          }
        })
        return this.commentsService
          .createComment({
            id: coreState.functionalSpecificationId,
            chapterId: coreState.chapterId,
            itemId: commentsState.selectedItemId,
            content: strippedComment,
            type: commentsState.createCommentType,
            propertyClassificationId:
              commentsState.createCommentType ===
              APIComments.CommentType.Property
                ? selectedProperty?.classificationId
                : null,
            mentions: mentions.sort((a, b) => a.index - b.index).map(m => m.userId)
          })
          .pipe(
            mergeMap((comment) => [
              FuncSpecCommentsActions.createCommentSuccess({
                comment,
              }),
              FuncSpecCommentsActions.clearFormState(),
              FuncSpecCommentsActions.toggleCommentPanel({ collapsed: false }),
              FuncSpecCommentsActions.selectCommentItem({ comment })
            ]),
            catchError((error: HttpErrorResponse | null) =>
              of(
                FuncSpecCommentsActions.createCommentFailure({
                  error: error?.message,
                })
              )
            )
          )
      })
    )
  );

  deleteComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecCommentsActions.deleteComment),
      withLatestFrom(
        this.coreStore.pipe(select(funcSpecCore.getFunctionalSpecificationId))
      ),
      fetch({
        run: (action, funcSpecId) =>
          this.commentsService
            .deleteComment(funcSpecId, action.comment.id)
            .pipe(
              map(() =>
                FuncSpecCommentsActions.deleteCommentSuccess({
                  comment: action.comment,
                })
              )
            ),

        onError: (action, error: HttpErrorResponse | null) => {
          console.error('Error', error);
          return FuncSpecCommentsActions.deleteCommentFailure({
            error: error?.message,
          });
        },
      })
    )
  );

  clearDeleteCommentResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecCommentsActions.clearDeleteCommentResult),
      withLatestFrom(this.commentsStore.pipe(
        select(commentSelectors.getAllFuncSpecComments)
      )),
      switchMap(([, state]) =>
        state.length === 0
          ? [FuncSpecCommentsActions.toggleCommentPanel({ collapsed: true })]
          : []
      )
    )
  );

  clearSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecCommentsActions.clearSelectedItemId),
      switchMap(() => [
        FuncSpecCommentsActions.setSelectedItemId({ selectedItemId: null }),
        text.clearSelectedTextItem(),
        structures.clearSelectedStructureItemInCanvas(),
      ])
    )
  );

  showLoadingSpinnerForAllComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecCommentsActions.loadAllComments),
      delay(500),
      map(() => FuncSpecCommentsActions.showLoadingSpinnerIfNotLoaded())
    )
  );

  showLoadingSpinnerForItemComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecCommentsActions.loadCommentsForItem),
      delay(500),
      map(() => FuncSpecCommentsActions.showLoadingSpinnerIfNotLoaded())
    )
  );

  selectedStructureItemInCanvas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(structures.selectedStructureItemInCanvas),
      switchMap((action) => [
        FuncSpecCommentsActions.setSelectedItemId({
          selectedItemId: action.structureItemId,
        }),
        FuncSpecCommentsActions.setCreateCommentType({
          createCommentType: APIComments.CommentType.Structure,
        }),
      ])
    )
  );

  selectedPropertyItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(properties.selectedPropertyItem),
      withLatestFrom(
        this.structureStore.pipe(
          select(structures.getSelectedStructureItemInCanvas)
        )
      ),
      switchMap(([action, selectedStructureItemId]) => [
        FuncSpecCommentsActions.setSelectedItemId({
          selectedItemId: selectedStructureItemId,
          selectedSubItemId: action.selectedPropertyItem.classificationId,
        }),
        FuncSpecCommentsActions.setCreateCommentType({
          createCommentType: APIComments.CommentType.Property,
        }),
      ])
    )
  );

  selectedTextItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(text.selectedTextItem),
      switchMap((action) => [
        FuncSpecCommentsActions.setSelectedItemId({
          selectedItemId: action.textItemId,
        }),
        FuncSpecCommentsActions.setCreateCommentType({
          createCommentType: APIComments.CommentType.Text,
        }),
      ])
    )
  );

  selectCommentItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecCommentsActions.selectCommentItem),
      switchMap((action) => {
        let returnActions = [];
        if (
          action.comment.commentType === APIComments.CommentType.Structure ||
          action.comment.commentType === APIComments.CommentType.Property
        ) {
          returnActions = [
            structures.loadStructureCanvasAncestorsItem({
              itemId: action.comment.itemId,
              selectStructureAfterLoad: true,
            }),
          ];
        }
        if (action.comment.commentType === APIComments.CommentType.Text) {
          returnActions = [
            FuncSpecCommentsActions.jumpToTextItem({
              textItemId: action.comment.itemId,
            }),
          ];
        }

        return returnActions;
      })
    )
  );

  showAllComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecCommentsActions.showAllComments),
      switchMap(() => [
        FuncSpecCommentsActions.clearSelectedItemId(),
        FuncSpecCommentsActions.loadAllComments(),
      ])
    )
  );

  addCommentCountAfterSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecCommentsActions.createCommentSuccess),
      switchMap((action) => {
        switch (action.comment.commentType) {
          case APIComments.CommentType.Text:
            return [text.addCommentToSelectedTextItem()];
          case APIComments.CommentType.Structure:
            return [structures.addCommentToSelectedStructure()];
          case APIComments.CommentType.Property:
            return [
              structures.addCommentToSelectedStructure(),
              properties.addCommentToSelectedProperty(),
            ];
        }
      })
    )
  );

  subtractCommentCountAfterSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecCommentsActions.deleteCommentSuccess),
      switchMap((action) => {
        switch (action.comment.commentType) {
          case APIComments.CommentType.Text:
            return [
              text.subtractCommentCount({ textId: action.comment.itemId }),
            ];
          case APIComments.CommentType.Structure:
            return [
              structures.subtractCommentCount({
                structureId: action.comment.itemId,
              }),
            ];
          case APIComments.CommentType.Property:
            return [
              structures.subtractCommentCount({
                structureId: action.comment.itemId,
              }),
              properties.subtractCommentCount({
                propertyId: action.comment.subItemId,
              }),
            ];
        }
      })
    )
  );

  jumpToTextItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecCommentsActions.jumpToTextItem),
      switchMap((action) =>
        this.textStore.pipe(
          select(text.getTextItem(action.textItemId)),
          take(1)
        )
      ),
      switchMap((textItem) => [
        text.selectTextItem({
          requirementTemplateTextItemId: textItem.requirementTemplateTextItemId,
          textItemId: textItem.functionalSpecificationTextId,
        }),
        text.openTextItemAncestors({
          textItemId: textItem.functionalSpecificationTextId
        }),
        text.addExpandedTextItem({
          textItemId: textItem.functionalSpecificationTextId
        })
      ])
    )
  );

  clearHighlightedItemId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(structures.loadStructureCanvasAncestorsItemSuccess),
      withLatestFrom(
        this.structureStore.pipe(
          select(structures.getHighlightedInCanvasItemId)
        )
      ),
      filter(([, highlightedItemId]) => highlightedItemId !== null),
      delay(3000),
      map(() =>
        structures.highlightItemInCanvas({
          highlightedItemId: null,
        })
      )
    )
  );

  clearFormState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecCommentsActions.clearFormState),
      mergeMap(() => [
        new SetValueAction(fromFuncSpecComments.FUNC_SPEC_COMMENTS_FORM_ID, <
          fromFuncSpecComments.FuncSpecCommentsForm
        >{
          newComment: null,
        }),
        new ResetAction(fromFuncSpecComments.FUNC_SPEC_COMMENTS_FORM_ID),
      ])
    )
  );

  removeCommentsForDeletedItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(structures.deleteStructureSuccess),
      withLatestFrom(
        this.coreStore.pipe(select(commentSelectors.isCommentSidepanelCollapsed))
      ),
      filter(([, collapsed]) => !collapsed),
      map(() =>
        FuncSpecCommentsActions.toggleCommentPanel({ collapsed: true }),
      )
    )
  );

  loadStructurePropertiesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(properties.loadStructurePropertiesSuccess),
      withLatestFrom(
        this.commentsStore.pipe(select(commentSelectors.getSelectedComment)),
      ),
      filter(([, selectedComment]) => !!selectedComment?.subItemId),
      switchMap(([action, selectedComment]) => {
        const property = action.propertyModel.properties.find(p => p.classificationId === selectedComment.subItemId);
        return [ properties.openRequirements({ active: property.isActive }) ];
      })
    )
  );

  clearSelectedCommentOnClearSelectedStructureItemInCanvas = createEffect(() =>
    this.actions$.pipe(
      ofType(clearSelectedStructureItemInCanvas),
      switchMap(() => [FuncSpecCommentsActions.clearCommentItem()])
    )
  );

  constructor(
    private actions$: Actions,
    private commentsService: FuncSpecCommentsService,
    private commentsStore: Store<fromFuncSpecComments.FuncSpecCommentsPartialState>,
    private coreStore: Store<funcSpecCore.FuncSpecCorePartialState>,
    private propertiesStore: Store<properties.FuncSpecStructurePropertiesPartialState>,
    private structureStore: Store<structures.FuncSpecStructurePartialState>,
    private textStore: Store<text.FuncSpecTextPartialState>,
    private overviewStore: Store<fromFuncSpecOverview.FuncSpecOverviewPartialState>
  ) {}
}
