import { createAction, props } from '@ngrx/store';
import { APINotifications } from '@ama-studio/shared'

export const clearState = createAction('[FuncSpecNotifications] Clear State');

export const loadUnreadCount = createAction(
  '[FuncSpecNotifications] Load Unread Count',
);

export const loadUnreadCountSuccess = createAction(
  '[FuncSpecNotifications] Load Unread Count Success',
  props<{ count: number }>()
);

export const loadUnreadCountFailure = createAction(
  '[FuncSpecNotifications] Load Unread Count Failure',
  props<{ error: string | null }>()
);

export const loadLatestNotifications = createAction(
  '[FuncSpecNotifications] Load Latest Notifications',
);

export const loadLatestNotificationsSuccess = createAction(
  '[FuncSpecNotifications] Load Latest Notifications Success',
  props<{ notifications: APINotifications.INotificationViewModel[] }>()
);

export const loadLatestNotificationsFailure = createAction(
  '[FuncSpecNotifications] Load Latest Notifications Failure',
  props<{ error: string | null }>()
);

export const loadAllNotifications = createAction(
  '[FuncSpecNotifications] Load All Notifications',
);

export const loadAllNotificationsSuccess = createAction(
  '[FuncSpecNotifications] Load All Notifications Success',
  props<{ notifications: APINotifications.INotificationViewModel[] }>()
);

export const loadAllNotificationsFailure = createAction(
  '[FuncSpecNotifications] Load All Notifications Failure',
  props<{ error: string | null }>()
);

export const markNotificationsAsRead = createAction(
  '[FuncSpecNotifications] Mark Notifications As Read',
);

export const markNotificationsAsReadSuccess = createAction(
  '[FuncSpecNotifications] Mark Notifications As Read Success',
);

export const markNotificationsAsReadFailure = createAction(
  '[FuncSpecNotifications] Mark Notifications As Read Failure',
  props<{ error: string | null }>()
);


export const selectNotification = createAction(
  '[FuncSpecNotifications] Select notification',
  props<{ notification: APINotifications.INotificationViewModel }>()
);

export const clearSelectedNotification = createAction(
  '[FuncSpecNotifications] Clear selected notification'
);
