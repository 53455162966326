import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import {
  FuncSpecCoreFacade,
  leavingFuncSpecView,
} from '@ama-studio/func-spec-core-state';

@Injectable({
  providedIn: 'root',
})
export class FuncSpecDeactivateGuard implements CanDeactivate<boolean> {
  constructor(private funcSpecCoreFacade: FuncSpecCoreFacade) {}

  public canDeactivate(): boolean {
    this.funcSpecCoreFacade.dispatch(leavingFuncSpecView());
    return true;
  }
}
