import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import * as fromFuncSpecText from './func-spec-text.reducer';
import * as FuncSpecTextActions from './func-spec-text.actions';
import { FuncSpecTextService } from '../func-spec-text.service';
import {
  map,
  switchMap,
  withLatestFrom,
  filter,
  catchError,
} from 'rxjs/operators';

import { selectChapter } from '@ama-studio/func-spec-chapter-state';
import { select, Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { ChapterType } from '@ama-studio/shared';
import * as funcSpecCore from '@ama-studio/func-spec-core-state';
import { getTextItems } from './func-spec-text.selectors';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable()
export class FuncSpecTextEffects {
  setTextChapter = createEffect(() =>
    this.actions$.pipe(
      ofType(selectChapter),
      // We're only intrested in text chapter
      filter((x) => x.chapterType === ChapterType.Text),
      switchMap(() => [
        FuncSpecTextActions.clearState(),
        FuncSpecTextActions.loadTexts(),
      ])
    )
  );

  loadTexts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecTextActions.loadTexts),
      withLatestFrom(
        this.coreStore.pipe(select(funcSpecCore.getFuncSpecCoreState))
      ),
      fetch({
        run: (_, state) =>
          this.funcSpecTextService
            .getTexts(state.functionalSpecificationId, state.chapterId)
            .pipe(
              switchMap((chapterListModel: any) => [
                FuncSpecTextActions.loadTextsSuccess({
                  chapterListModel,
                }),
                FuncSpecTextActions.addExpandedTextItem({
                  textItemId: chapterListModel.textItems.children[0].functionalSpecificationTextId
                })
              ])
            ),
        onError: (action, error: HttpErrorResponse | null) => {
          console.error('Error', error);
          return FuncSpecTextActions.loadTextsFailure({
            error: error?.message,
          });
        },
      })
    )
  );

  selectTextAfterLoaded = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecTextActions.loadTextsSuccess),
      withLatestFrom(this.store.pipe(select(getTextItems))),
      map(([, textItems]) => {
        //Text loaded -> select previous one if it exists in query params
        const textItemToSelect =
          this.activatedRoute.snapshot.queryParamMap.get('selectedTextItem');
        const requirementTemplateTextItemId =
          textItems[textItemToSelect]?.requirementTemplateTextItemId || null;
        return FuncSpecTextActions.selectTextItem({
          textItemId: textItemToSelect,
          requirementTemplateTextItemId: requirementTemplateTextItemId,
        });
      })
    )
  );

  loadGuidanceText$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecTextActions.loadGuidanceText),
      withLatestFrom(
        this.coreStore.pipe(select(funcSpecCore.getFuncSpecCoreState))
      ),
      filter(([, state]) => state.hasLicenseToRequirementTemplate),
      fetch({
        run: (action, state) => {
          if (!action.requirementTemplateTextItemId) {
            return FuncSpecTextActions.loadGuidanceTextSuccess({
              guidanceText: null,
            });
          }
          return this.funcSpecTextService
            .getGuidanceText(
              state.requirementTemplateId,
              action.requirementTemplateTextItemId
            )
            .pipe(
              map((response) =>
                FuncSpecTextActions.loadGuidanceTextSuccess({
                  guidanceText: response.body,
                })
              )
            );
        },

        onError: (action, error: HttpErrorResponse | null) =>
          FuncSpecTextActions.loadGuidanceTextFailure({
            error: error?.message,
          }),
      })
    )
  );

  handleTextSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecTextActions.selectTextItem),
      map(action =>
        FuncSpecTextActions.selectedTextItem({
          textItemId: action.textItemId,
          requirementTemplateTextItemId: action.requirementTemplateTextItemId,
        }),
      )
    )
  );

  loadTextItemContentOnSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecTextActions.selectedTextItem),
      map((action) =>
        FuncSpecTextActions.loadTextItemContent({
          functionalSpecificationTextId: action.textItemId,
        })
      )
    )
  );

  loadTextItemContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecTextActions.loadTextItemContent),
      withLatestFrom(
        this.coreStore.pipe(select(funcSpecCore.getFuncSpecCoreState))
      ),
      fetch({
        run: (action, state) =>
          this.funcSpecTextService
            .getTextItemContent(
              state.functionalSpecificationId,
              action.functionalSpecificationTextId
            )
            .pipe(
              map((response) =>
                FuncSpecTextActions.loadTextItemContentSuccess({
                  textItemContent: response,
                })
              )
            ),

        onError: (action, error: HttpErrorResponse | null) =>
          FuncSpecTextActions.loadTextItemContentFailure({
            error: error?.message,
          }),
      })
    )
  );

  updateTextContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecTextActions.updateTextContent),
      withLatestFrom(
        this.coreStore.pipe(select(funcSpecCore.getFunctionalSpecificationId))
      ),
      switchMap(([action, funcSpecId]) =>
        this.funcSpecTextService
          .updateText({
            content: action.updatedText,
            functionalSpecificationTextId: action.functionalSpecificationTextId,
            id: funcSpecId,
          })
          .pipe(
            switchMap((textItemModel) => [
              FuncSpecTextActions.updateTextContentSuccess({
                textItemModel,
              }),
            ]),
            catchError((error: HttpErrorResponse | null) => {
              console.error(error);
              return of(
                FuncSpecTextActions.updateTextContentError({
                  error: error?.message,
                })
              );
            })
          )
      )
    )
  );


  constructor(
    private actions$: Actions,
    private funcSpecTextService: FuncSpecTextService,
    private store: Store<fromFuncSpecText.FuncSpecTextPartialState>,
    private coreStore: Store<funcSpecCore.FuncSpecCorePartialState>,
    private activatedRoute: ActivatedRoute,
  ) {}
}
