import { NgModule } from '@angular/core';
import { CustomErrorStateMatcherDirective } from './custom-error-state-matcher.directive';
import { NgrxMatSelectViewAdapter } from './mat-select-view.directive';

@NgModule({
  imports: [],
  declarations: [CustomErrorStateMatcherDirective, NgrxMatSelectViewAdapter],
  exports: [CustomErrorStateMatcherDirective, NgrxMatSelectViewAdapter],
})
export class DirectivesModule {}
