import { createAction, props } from '@ngrx/store';
import { APIStructures } from '@ama-studio/shared';

export const clearState = createAction('[FuncSpecStructure] Clear State');

export const loadStructureCanvas = createAction(
  '[FuncSpecStructure] Load Structure Canvas'
);

export const loadStructureCanvasSuccess = createAction(
  '[FuncSpecStructure] Structure Structure Canvas Loaded',
  props<{
    structureCanvasModel: APIStructures.IFunctionalSpecificationStructureItemViewModel;
  }>()
);

export const loadStructureCanvasFailure = createAction(
  '[FuncSpecStructure] Structure Structure Canvas Failure',
  props<{ error: string | null }>()
);

export const loadStructureCanvasItem = createAction(
  '[FuncSpecStructure] Load Structure Canvas Item',
  props<{ structureId: string }>()
);

export const loadStructureCanvasItemSuccess = createAction(
  '[FuncSpecStructure] Load Structure Canvas Item Success',
  props<{
    structureCanvasItemModel: APIStructures.IFunctionalSpecificationStructureItemViewModel;
  }>()
);

export const loadStructureCanvasItemFailure = createAction(
  '[FuncSpecStructure] Load Structure Canvas Item Failure',
  props<{ error: string | null }>()
);

export const updateChildListForCanvasItem = createAction(
  '[FuncSpecStructure] Update Child List For Canvas Item',
  props<{ structureId: string }>()
);

export const updateChildListForCanvasItemSuccess = createAction(
  '[FuncSpecStructure] Update Child List For Canvas Item Success',
  props<{
    structureCanvasItemModel: APIStructures.IFunctionalSpecificationStructureItemViewModel;
  }>()
);

export const updateChildListForCanvasItemFailure = createAction(
  '[FuncSpecStructure] Update Child List For Canvas Item Failure',
  props<{ error: string | null }>()
);

export const deleteStructure = createAction(
  '[FuncSpecStructure] Delete Structure',
  props<{ structureId: string }>()
);

export const deleteStructureSuccess = createAction(
  '[FuncSpecStructure] Delete Structure Success',
  props<{
    deleteStructureId: string;
  }>()
);

export const deleteStructureFailure = createAction(
  '[FuncSpecStructure] Delete Structure Failure',
  props<{ error: string | null }>()
);

export const updateStructureItemValues = createAction(
  '[FuncSpecStructure] Update Structure Values',
  props<{
    structureCanvasItemModel: APIStructures.IFunctionalSpecificationStructureItemViewModel;
  }>()
);

export const addExpandedStructureItem = createAction(
  '[FuncSpecStructure] Add Expanded Structure Item',
  props<{ structureItemId: string }>()
);

export const removeExpandedStructureItem = createAction(
  '[FuncSpecStructure] Remove Expanded Structure Item',
  props<{ structureItemId: string }>()
);

export const hideExpandedChildren = createAction(
  '[FuncSpecStructure] Hide Expanded Children',
  props<{ structureItemId: string }>()
);

export const hideExpandedSiblings = createAction(
  '[FuncSpecStructure] Hide Expanded Siblings',
  props<{ structureItemId: string }>()
);

export const loadStructureCanvasAncestorsItem = createAction(
  '[FuncSpecStructure] Load Structure Canvas Item Ancestors',
  props<{ itemId: string; selectStructureAfterLoad: boolean }>()
);

export const loadStructureCanvasAncestorsItemSuccess = createAction(
  '[FuncSpecStructure] Load Structure Canvas Item Ancestors Success',
  props<{
    structureItems: APIStructures.IFunctionalSpecificationStructureItemViewModel;
    topNodeItemId: string;
  }>()
);

export const loadStructureCanvasAncestorsItemFailure = createAction(
  '[FuncSpecStructure] Load Structure Canvas Item Ancestors Failure',
  props<{ error: string | null }>()
);

export const clearSelectedStructureItemInCanvas = createAction(
  '[FuncSpecStructure] Clear Selected Structure Item In Canvas'
);

export const saveStructureItemInCanvasAndSelect = createAction(
  '[FuncSpecStructure] Save Structure Item And Select it',
  props<{
    structureItem: APIStructures.IFunctionalSpecificationStructureItemViewModel;
  }>()
);

export const selectedStructureItemInCanvas = createAction(
  '[FuncSpecStructure] Selected Structure Item In Canvas',
  props<{
    structureItemId: string;
  }>()
);

export const selectedStructureItemInAddMode = createAction(
  '[FuncSpecStructure] Selected Structure Item In Add Mode',
  props<{
    structureItemId: string;
  }>()
);

export const selectedStructureItemInNavigation = createAction(
  '[FuncSpecStructure] Selected Structure Item In Navigation',
  props<{
    classificationId: string;
  }>()
);

export const setIsAddingStructureToCanvas = createAction(
  '[FuncSpecStructure] Set Is Adding Structure To Canvas',
  props<{
    isAddingStructureToCanvas: boolean;
  }>()
);

export const highlightItemInCanvas = createAction(
  '[FuncSpecStructure] Highlight Item In Canvas',
  props<{
    highlightedItemId: string;
  }>()
);

export const highlightStructureItemSection = createAction(
  '[FuncSpecStructure] Highlight Structure Item Section',
  props<{
    structureItemId: string;
    section: APIStructures.IStructureItemSection;
  }>()
);

export const unHighlightStructureItemSection = createAction(
  '[FuncSpecStructure] UnHighlight Structure Item Section',
  props<{
    structureItemId: string;
  }>()
);

export const propertiesClicked = createAction(
  '[FuncSpecStructure] Properties Clicked',
  props<{
    structureId: string;
  }>()
);

export const showProperties = createAction(
  '[FuncSpecStructure] Show Properties'
);

export const hideProperties = createAction(
  '[FuncSpecStructure] Hide Properties'
);

export const addCommentToSelectedStructure = createAction(
  '[FuncSpecStructure] Add Comment To Selected Structure'
);

export const subtractCommentCount = createAction(
  '[FuncSpecStructure] Subtract Comment Count',
  props<{
    structureId: string;
  }>()
);

export const setIsStructureRootItemExists = createAction(
  '[FuncSpecStructure] set isStructureRootItemExists',
  props<{
    isStructureRootItemExists: boolean;
  }>()
);

export const showNavigator = createAction('[FuncSpecStructure] Show Navigator');
