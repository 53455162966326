/* eslint-disable @typescript-eslint/naming-convention */
export namespace APIExport {
  export enum ExportStatus {
    Pending = 'Pending',
    Failed = 'Failed',
  }

  export interface IExportPDFCommand {
    id: string;
    chapterNumber: number;
    structureItemId?: string;
  }

  export interface IFullExportCommand {
    id: string;
    email: string;
  }

  export interface IPDFModel {
    id: string;
    chapterNumber: number;
    pdfId: string;
  }

  export interface ICheckPDFStatusCommand {
    id: string;
    pdfId: string;
  }

  export interface IPDFExportStatusModel {
    id: string;
    pdfId: string;
    exportStatus: ExportStatus;
  }
}
