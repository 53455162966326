import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { APIChapters } from '@ama-studio/shared';
import * as ChaptersActions from './chapters.actions';

export const CHAPTERS_FEATURE_KEY = 'chapters';

export interface State
  extends EntityState<APIChapters.IRequirementTemplateTextItemViewModel> {
  loaded: boolean; // has the Chapters list been loaded
  error?: string | null; // last known error (if any)

  functionalSpecificationId: string;
  selectedChapterId: string;
  selectedChapterNumber: number;
}

export interface ChaptersPartialState {
  readonly [CHAPTERS_FEATURE_KEY]: State;
}

export const chaptersAdapter: EntityAdapter<APIChapters.IRequirementTemplateTextItemViewModel> =
  createEntityAdapter<APIChapters.IRequirementTemplateTextItemViewModel>({
    selectId: (chapter) => chapter.chapterNumber,
  });

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export const initialState: State = chaptersAdapter.getInitialState({
  // set initial required properties

  loaded: false,
  error: null,

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  readingInstructions: null,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  functionalSpecificationName: null,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  functionalSpecificationId: null,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  selectedChapterId: null,
  selectedChapterNumber: 0,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  requirementTemplateName: null,
});

const chaptersReducer = createReducer(
  initialState,
  on(ChaptersActions.clearState, () => initialState),
  on(ChaptersActions.loadChapters, (state, { funcSpecId }) => ({
    ...state,
    error: null,
    functionalSpecificationId: funcSpecId,
  })),
  on(ChaptersActions.loadChaptersSuccess, (state, { overviewModel }) =>
    chaptersAdapter.setAll(overviewModel?.chapters || [], {
      ...state,
      loaded: true,
      error: null,
    })
  ),
  on(ChaptersActions.loadChaptersFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(ChaptersActions.selectChapter, (state, { chapterId, chapterNumber }) => ({
    ...state,
    selectedChapterId: chapterId,
    selectedChapterNumber: chapterNumber,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return chaptersReducer(state, action);
}
