import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IncidentWidgetComponent } from './incident-widget.component';

@NgModule({
  imports: [CommonModule],
  declarations: [IncidentWidgetComponent],
  exports: [IncidentWidgetComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class IncidentWidgetModule {}
