import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FUNCSPECCORE_FEATURE_KEY,
  State,
} from './func-spec-core.reducer';

export const getFuncSpecCoreState = createFeatureSelector<
  State
>(FUNCSPECCORE_FEATURE_KEY);

export const getFuncSpecError = createSelector(
  getFuncSpecCoreState,
  (state: State) => state.error
);

export const getFuncSpec = createSelector(
  getFuncSpecCoreState,
  (state: State) => state.funcSpec
);

export const getInFuncSpecView = createSelector(
  getFuncSpecCoreState,
  (state: State) => state.inFuncSpecView
);

export const getFunctionalSpecificationId = createSelector(
  getFuncSpecCoreState,
  (state: State) => state.functionalSpecificationId
);

export const getProjectModel = createSelector(
  getFuncSpecCoreState,
  (state: State) => state.projectModel
);

export const getProjectId = createSelector(
  getFuncSpecCoreState,
  (state: State) => state.projectId
);

export const getChapterId = createSelector(
  getFuncSpecCoreState,
  (state: State) => state.chapterId
);

export const getRequirementTemplateId = createSelector(
  getFuncSpecCoreState,
  (state: State) => state.requirementTemplateId
);

export const getRequirementTemplateName = createSelector(
  getFuncSpecCoreState,
  (state: State) => state.requirementTemplateName
);

export const getHasLicenseToRequirementTemplate = createSelector(
  getFuncSpecCoreState,
  (state: State) => state.hasLicenseToRequirementTemplate
);

export const getSelectedRightNavTab = createSelector(
  getFuncSpecCoreState,
  (state: State) => state.selectedRightNavTab
);

export const getRightNavTabHeadline = createSelector(
  getFuncSpecCoreState,
  (state: State) => state.rightNavTabHeadline
);

export const getShowCommentTab = createSelector(
  getFuncSpecCoreState,
  (state: State) => state.showCommentTab
);

export const getShowSearchResultTab = createSelector(
  getFuncSpecCoreState,
  (state: State) => state.showSearchResultTab
);

export const getChapterType = createSelector(
  getFuncSpecCoreState,
  (state: State) => state.chapterType
);

export const getCoClassVersion = createSelector(
  getFuncSpecCoreState,
  (state: State) => state.funcSpec?.coClassVersion
);

export const getApproverUserId = createSelector(
  getFuncSpecCoreState,
  (state: State) => state.funcSpec?.approverUserId
);
