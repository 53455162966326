<aside id="feedback">
  <button
    class="btn btn-feedback"
    [ngClass]="isVisible() ? 'btn-feedback--active' : null"
    (click)="handleClick($event, 'feedback-action')"
  >
    <span class="material-icons feedback-chat" *ngIf="!isVisible()">chat</span>
    <span class="feedback-help" *ngIf="!isVisible()">?</span>
    <span class="material-icons feedback-close" *ngIf="isVisible()">close</span>
  </button>
</aside>

<sbui-feedback-action
  [isVisible]="modalVisibility['feedback-action']"
  (modalVisibilityChanged)="handleModalVisibilityChanged($event)"
></sbui-feedback-action>
<sbui-feedback-form
  [isVisible]="modalVisibility['feedback-form']"
  (modalVisibilityChanged)="handleModalVisibilityChanged($event)"
  (submitFeedback)="handleSubmit($event)"
></sbui-feedback-form>
