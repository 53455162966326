<div class="vh-100 pt-4">
  <h2>{{ heading }}</h2>
  <div class="container text-center bg-white p-4">
    <h3>{{ subHeading }}</h3>
    <div>
      <ng-content></ng-content>
    </div>
    <div class="m-auto mb-0">
      <ng-content select=".user-message-cta"></ng-content>
    </div>
  </div>
</div>
