import { Component } from '@angular/core';

@Component({
  selector: 'ama-studio-func-spec-export',
  templateUrl: './func-spec-export.component.html',
  styleUrls: ['./func-spec-export.component.scss']
})
export class FuncSpecExportComponent {
  constructor() {}
}
