import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FEEDBACK_FEATURE_KEY,
  State,
} from './feedback.reducer';

export const getFeedbackState =
  createFeatureSelector< State>(FEEDBACK_FEATURE_KEY);

export const getFeedbackError = createSelector(
  getFeedbackState,
  (state: State) => state.error
);
