import { Action, createReducer, on } from '@ngrx/store';
import { APIStatus } from '@ama-studio/shared'
import * as FuncSpecStatusActions from './func-spec-status.actions';
export const FUNCSPECSTATUS_FEATURE_KEY = 'funcSpecStatus';

export enum ReadyForReviewResult {
  none = 'None',
  success = 'Success',
  error = 'Error',
}

export interface State {
  status: APIStatus.Status | null;
  confirmationStatus: APIStatus.Status | null;
  readyForReviewResult: ReadyForReviewResult;
  error?: string | null;
  showBanner: boolean;
}

export interface FuncSpecStatusPartialState {
  readonly [FUNCSPECSTATUS_FEATURE_KEY]: State;
}

export const initialState: State = {
  status: null,
  confirmationStatus: null,
  readyForReviewResult: ReadyForReviewResult.none,
  error: null,
  showBanner: false
}

const funcSpecStatusReducer = createReducer(
  initialState,
  on(FuncSpecStatusActions.readyForReviewSuccess, (state) => ({
    ...state,
    readyForReviewResult: ReadyForReviewResult.success
  })),
  on(FuncSpecStatusActions.updateStatus, (state, { status }) => ({
    ...state,
    status
  })),
  on(FuncSpecStatusActions.readyForReviewFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(FuncSpecStatusActions.clearReadyForReviewResult, (state) => ({
    ...state,
    readyForReviewResult: ReadyForReviewResult.none
  })),
  on(FuncSpecStatusActions.showBanner, state => ({
    ...state,
    showBanner: true
  })),
  on(FuncSpecStatusActions.hideBanner, state => ({
    ...state,
    showBanner: false
  })),
  on(FuncSpecStatusActions.approveFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(FuncSpecStatusActions.disapproveFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(FuncSpecStatusActions.setConfirmationStatus, (state, { status }) => ({
    ...state,
    confirmationStatus: status
  })),

);

export function reducer(state: State | undefined, action: Action) {
  return funcSpecStatusReducer(state, action);
}
