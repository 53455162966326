import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Route } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromFuncSpecOverviewState from './+state/func-spec-overview.reducer';
import { FuncSpecOverviewEffects } from './+state/func-spec-overview.effects';
import { FuncSpecOverviewFacade } from './+state/func-spec-overview.facade';

export const funcSpecOverviewStateRoutes: Route[] = [];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    StoreModule.forFeature(
      fromFuncSpecOverviewState.FUNCTIONALSPECIFICATIONDETAIL_FEATURE_KEY,
      fromFuncSpecOverviewState.reducer
    ),
    EffectsModule.forFeature([FuncSpecOverviewEffects]),
  ],
  providers: [FuncSpecOverviewFacade],
})
export class FuncSpecOverviewStateModule {}
