import { FuncSpecStatusFacade } from '@ama-studio/func-spec-status';
import { AnalyticsService } from '@ama-studio/shared';
import { Component } from '@angular/core';
import { take } from 'rxjs/operators';


@Component({
  selector: 'ama-studio-func-spec-export-icon',
  templateUrl: './func-spec-export-icon.component.html',
  styleUrls: ['./func-spec-export-icon.component.scss']
})
export class FuncSpecExportIconComponent {

  constructor(
    private statusFacade: FuncSpecStatusFacade,
    private analyticsService: AnalyticsService
  ) {}

  trackExport() {
    this.statusFacade.isApproved$.pipe(take(1)).subscribe(approved => {
      if(approved) {
        this.analyticsService.approvedExport('icon');
      } else {
        this.analyticsService.unapprovedExport('icon');
      }
    });
  }

}
