import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  APIFeedback,
  BaseHttpService,
  EnvironmentConfig,
} from '@ama-studio/shared';

@Injectable({ providedIn: 'root' })
export class FeedbackService extends BaseHttpService {
  constructor(http: HttpClient, envConfig: EnvironmentConfig) {
    super(http, envConfig);
  }

  public createFeedback(createCommand: APIFeedback.ICreateFeedbackCommand) {
    return this.http.post<void>(
      `${this.envConfig.baseApiUrl}/feedback`,
      { ...createCommand },
      this.headers
    );
  }
}
