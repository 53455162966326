import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FUNCSPECCOMMENTS_FEATURE_KEY,
  State,
  FuncSpecCommentsPartialState,
  funcSpecCommentsAdapter,
} from './func-spec-comments.reducer';

// Lookup the 'FuncSpecComments' feature state managed by NgRx
export const getFuncSpecCommentsState = createFeatureSelector<
  State
>(FUNCSPECCOMMENTS_FEATURE_KEY);

const { selectAll, selectEntities } = funcSpecCommentsAdapter.getSelectors();

export const getFuncSpecCommentsLoaded = createSelector(
  getFuncSpecCommentsState,
  (state: State) => state.loaded
);

export const getFuncSpecCommentsError = createSelector(
  getFuncSpecCommentsState,
  (state: State) => state.error
);

export const getAllFuncSpecComments = createSelector(
  getFuncSpecCommentsState,
  (state: State) => selectAll(state)
);

export const getFuncSpecCommentsEntities = createSelector(
  getFuncSpecCommentsState,
  (state: State) => selectEntities(state)
);

export const getSelectedItemId = createSelector(
  getFuncSpecCommentsState,
  (state: State) => state.selectedItemId
);

export const getModalComment = createSelector(
  getFuncSpecCommentsState,
  (state: State) => state.modalComment
);

export const getFormState = createSelector(
  getFuncSpecCommentsState,
  (state: State) => state.formState
);

export const getCreateCommentType = createSelector(
  getFuncSpecCommentsState,
  (state: State) => state.createCommentType
);

export const getShowLoadingSpinner = createSelector(
  getFuncSpecCommentsState,
  (state: State) => state.showLoadingSpinner
);

export const getCommentType = createSelector(
  getFuncSpecCommentsState,
  (state: State) => state.createCommentType
);

export const isCommentSidepanelCollapsed = createSelector(
  getFuncSpecCommentsState,
  (state: State) => state.commentSidepanelCollapsed
);

export const getDeleteCommentResult = createSelector(
  getFuncSpecCommentsState,
  (state: State) => state.deleteCommentResult
);

export const getSelectedCommentId = createSelector(
  getFuncSpecCommentsState,
  (state: State) => state.selectedComment?.id
);

export const getSelectedComment = createSelector(
  getFuncSpecCommentsState,
  (state: State) => state.selectedComment
);
