import { Injectable } from '@angular/core';
import {
  EnvironmentConfig,
  BaseHttpService,
  APIStructures,
} from '@ama-studio/shared';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FuncSpecStructureService extends BaseHttpService {
  constructor(http: HttpClient, envConfig: EnvironmentConfig) {
    super(http, envConfig);
  }

  private editStructureUrl = `${this.envConfig.baseApiUrl}/functionalspecifications`;

  private getStructureCanvasUrl(funcSpecId: string) {
    return `${this.editStructureUrl}/${funcSpecId}/structures`;
  }

  private getStructureCanvasItemUrl(funcSpecId: string, structureId: string) {
    return `${this.editStructureUrl}/${funcSpecId}/structures/${structureId}`;
  }

  private getDeleteStructureUrl(funcSpecId: string, structureId: string) {
    return `${this.editStructureUrl}/${funcSpecId}/structures/${structureId}`;
  }

  private getStructureCanvasItemAncestorsUrl(
    funcSpecId: string,
    itemId: string
  ): string {
    return `${this.editStructureUrl}/${funcSpecId}/structures/${itemId}/ancestors`;
  }

  public getStructureCanvas(funcSpecId: string) {
    return this.http.get<APIStructures.IFunctionalSpecificationStructureItemViewModel>(
      this.getStructureCanvasUrl(funcSpecId),
      this.headers
    );
  }

  public getStructureCanvasItem(funcSpecId: string, structureId: string) {
    return this.http.get<APIStructures.IFunctionalSpecificationStructureItemViewModel>(
      this.getStructureCanvasItemUrl(funcSpecId, structureId),
      this.headers
    );
  }

  public deleteStructure(funcSpecId: string, structureId: string) {
    return this.http.delete(
      this.getDeleteStructureUrl(funcSpecId, structureId),
      this.headers
    );
  }

  public getStructureCanvasItemAcenstors(funcSpecId: string, itemId: string) {
    return this.http.get<APIStructures.IFunctionalSpecificationStructureItemViewModel>(
      this.getStructureCanvasItemAncestorsUrl(funcSpecId, itemId),
      this.headers
    );
  }
}
