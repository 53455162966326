import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  TemplateRef,
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  GlobalStateFacade,
  onSideMenuCollapse,
  setMainTitle,
} from '@ama-studio/fs-global-state';
import { FuncSpecStatusFacade } from '@ama-studio/func-spec-status';

@Component({
  selector: 'ama-studio-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent implements OnInit {
  mainTitle$: Observable<string>;
  globalMenuCollapsed$: Observable<boolean>;
  sideMenuCollapsed = new BehaviorSubject<boolean>(false);

  bodyTemplateRef: TemplateRef<unknown>;
  showBanner$: Observable<boolean>;

  constructor(
    private globalStateFacade: GlobalStateFacade,
    private statusFacade: FuncSpecStatusFacade
  ) {}

  ngOnInit(): void {
    this.mainTitle$ = this.globalStateFacade.mainTitle$;
    this.showBanner$ = this.statusFacade.showBanner$;
    this.globalStateFacade.dispatch(
      setMainTitle({ mainTitle: 'AMA Funktion' })
    );

    this.globalMenuCollapsed$ = this.globalStateFacade.sideMenuCollapsed$;
    this.globalMenuCollapsed$.subscribe((res) =>
      this.sideMenuCollapsed.next(res)
    );

  }

  onMenuCollapse() {
    this.globalStateFacade.dispatch(
      onSideMenuCollapse({ sideMenuCollapsed: this.sideMenuCollapsed.value })
    );
  }
}
