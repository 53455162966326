import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TOAST_NOTIFICATIONS_FEATURE_KEY, State } from './toast.reducer';

// Lookup the 'Toast' feature state managed by NgRx
export const getToastState = createFeatureSelector<State>(
  TOAST_NOTIFICATIONS_FEATURE_KEY
);

export const getMessage = createSelector(
  getToastState,
  (state: State) => state.message
);

export const getErrorMessage = createSelector(
  getToastState,
  (state: State) => state.errorMessage
);
