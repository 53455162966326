import { APIFeedback } from '@ama-studio/shared';
import { createReducer, on } from '@ngrx/store';
import * as FeedbackActions from './feedback.actions';

export interface State {
  feedback: APIFeedback.ICreateFeedbackCommand;
  error?: string | null;
  showSpinner: boolean;
}

const initialState: State = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore */
  feedback: null,
  showSpinner: false,
};

export const FEEDBACK_FEATURE_KEY = 'feedback';

export interface FeedbackPartialState {
  readonly [FEEDBACK_FEATURE_KEY]: State;
}

export const reducer = createReducer(
  initialState,
  on(FeedbackActions.createFeedback, (state, action) => ({
    ...state,
    feedback: action.feedback,
    showSpinner: true,
  })),
  on(FeedbackActions.createFeedbackFailure, (state, { error }) => ({
    ...state,
    error,
    showSpinner: false,
  })),
  on(FeedbackActions.createFeedbackSuccess, (state) => ({
    ...state,
    showSpinner: false,
  }))
);
