import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import * as fromFeedback from './feedback.reducer';
import * as FeedbackSelectors from './feedback.selectors';

@Injectable()
export class FeedbackFacade {
  constructor(private store: Store<fromFeedback.FeedbackPartialState>) {}

  feedback$ = this.store.pipe(select(FeedbackSelectors.getFeedbackState));

  error$ = this.store.pipe(select(FeedbackSelectors.getFeedbackError));

  dispatch(action: Action) {
    this.store.dispatch(action);
  }
}
