import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import {
  map,
  switchMap,
  withLatestFrom,
  filter,
  catchError,
} from 'rxjs/operators';
import { selectChapter } from '@ama-studio/func-spec-chapter-state';
import * as funcSpecCore from '@ama-studio/func-spec-core-state';
import * as fromFuncSpecStructure from './func-spec-structure-state.reducer';
import * as FuncSpecStructureActions from './func-spec-structure-state.actions';
import { ChapterType } from '@ama-studio/shared';
import { select, Store, Action } from '@ngrx/store'
import { FuncSpecStructureService } from '../func-spec-structure-state.service';
import { getFunctionalSpecificationId } from '@ama-studio/func-spec-core-state';
import { of } from 'rxjs';
import { getStructureItem } from './func-spec-structure-state.selectors';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class FuncSpecStructureEffects {
  setStructureChapter = createEffect(() =>
    this.actions$.pipe(
      ofType(selectChapter),
      filter((x) => x.chapterType === ChapterType.Structure),
      map(() => FuncSpecStructureActions.loadStructureCanvas())
    )
  );

  loadStructureCanvas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecStructureActions.loadStructureCanvas),
      withLatestFrom(this.coreStore.pipe(select(getFunctionalSpecificationId))),
      fetch({
        run: (_, funcSpecId) =>
          this.funcSpecStructureService.getStructureCanvas(funcSpecId).pipe(
            switchMap((structureCanvasModel) => {
              const actions: Action[] = [
                FuncSpecStructureActions.loadStructureCanvasSuccess({
                  structureCanvasModel,
                }),
              ];
              if (structureCanvasModel.hasChildren) {
                actions.push(
                  FuncSpecStructureActions.addExpandedStructureItem({
                    structureItemId: structureCanvasModel.id
                  })
                );
              }
              return actions;
            })
          ),
        onError: (action, error: HttpErrorResponse | null) => {
          console.error('Error', error);
          return FuncSpecStructureActions.loadStructureCanvasFailure({
            error: error?.message,
          });
        },
      })
    )
  );

  loadStructureCanvasItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecStructureActions.loadStructureCanvasItem),
      withLatestFrom(this.coreStore.pipe(select(getFunctionalSpecificationId))),
      fetch({
        run: (action, funcSpecId) =>
          this.funcSpecStructureService
            .getStructureCanvasItem(funcSpecId, action.structureId)
            .pipe(
              map((structureCanvasItemModel) =>
                FuncSpecStructureActions.loadStructureCanvasItemSuccess({
                  structureCanvasItemModel,
                })
              )
            ),
        onError: (action, error: HttpErrorResponse | null) => {
          console.error('Error', error);
          return FuncSpecStructureActions.loadStructureCanvasItemFailure({
            error: error?.message,
          });
        },
      })
    )
  );

  updateChildListForCanvasItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecStructureActions.updateChildListForCanvasItem),
      withLatestFrom(this.coreStore.pipe(select(getFunctionalSpecificationId))),
      fetch({
        run: (action, funcSpecId) =>
          this.funcSpecStructureService
            .getStructureCanvasItem(funcSpecId, action.structureId)
            .pipe(
              switchMap((structureCanvasItemModel) => {
                const actions: Action[] = [
                  FuncSpecStructureActions.updateChildListForCanvasItemSuccess({
                    structureCanvasItemModel,
                  })
                ];
                if (!structureCanvasItemModel.children.length) {
                  actions.push(
                    FuncSpecStructureActions.removeExpandedStructureItem({
                      structureItemId: structureCanvasItemModel.id
                    })
                  );
                }
                return actions;
              })
            ),
        onError: (action, error: HttpErrorResponse | null) => {
          console.error('Error', error);
          return FuncSpecStructureActions.updateChildListForCanvasItemFailure({
            error: error?.message,
          });
        },
      })
    )
  );

  deleteStructure = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecStructureActions.deleteStructure),
      withLatestFrom(this.coreStore.pipe(select(getFunctionalSpecificationId))),
      switchMap(([action, funcSpecId]) =>
        // When deleting an item, update the parent in case the child-arrow needs
        // to be updated (when all children are deleted for the item)
        of(action).pipe(
          withLatestFrom(
            this.structureStore.pipe(
              select(getStructureItem(action.structureId))
            )
          ),
          switchMap(([, item]) =>
            this.funcSpecStructureService
              .deleteStructure(funcSpecId, action.structureId)
              .pipe(
                switchMap(() => [
                  FuncSpecStructureActions.hideExpandedChildren({
                    structureItemId: item.parentId
                  }),
                  FuncSpecStructureActions.deleteStructureSuccess({
                    deleteStructureId: action.structureId,
                  }),
                  FuncSpecStructureActions.updateChildListForCanvasItem({
                    structureId: item.parentId,
                  }),
                  FuncSpecStructureActions.clearSelectedStructureItemInCanvas()
                ]),
                catchError((error: HttpErrorResponse | null) =>
                  of(
                    FuncSpecStructureActions.deleteStructureFailure({
                      error: error?.message,
                    })
                  )
                )
              )
          )
        )
      )
    )
  );

  loadStructureCanvasAncestorsItem = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecStructureActions.loadStructureCanvasAncestorsItem),
      withLatestFrom(this.coreStore.pipe(select(getFunctionalSpecificationId))),
      switchMap(([action, funcSpecId]) =>
        this.funcSpecStructureService
          .getStructureCanvasItemAcenstors(funcSpecId, action.itemId)
          .pipe(
            switchMap((structureItems) => {
              const actionsToDispatch = [];
              if(structureItems) {
                actionsToDispatch.push(
                  FuncSpecStructureActions.loadStructureCanvasAncestorsItemSuccess(
                    {
                      structureItems,
                      topNodeItemId: action.itemId,
                    }
                  ),
                  FuncSpecStructureActions.highlightItemInCanvas({
                    highlightedItemId: action.itemId,
                  }),
                  FuncSpecStructureActions.showProperties()
                );
                if (action.selectStructureAfterLoad) {
                  actionsToDispatch.push(
                    FuncSpecStructureActions.selectedStructureItemInCanvas({
                      structureItemId: action.itemId,
                    })
                  );
                }
              }
              return actionsToDispatch;
            }),
            catchError((error: HttpErrorResponse | null) =>
              of(
                FuncSpecStructureActions.loadStructureCanvasAncestorsItemFailure(
                  { error: error?.message }
                )
              )
            )
          )
      )
    )
  );

  saveStructureItemAndSelectIt = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecStructureActions.saveStructureItemInCanvasAndSelect),
      fetch({
        run: (action) =>
          FuncSpecStructureActions.selectedStructureItemInCanvas({
            structureItemId: action.structureItem.id,
          }),
      })
    )
  );

  constructor(
    private actions$: Actions,
    private funcSpecStructureService: FuncSpecStructureService,
    private coreStore: Store<funcSpecCore.FuncSpecCorePartialState>,
    private structureStore: Store<fromFuncSpecStructure.FuncSpecStructurePartialState>
  ) {}
}
