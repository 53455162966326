import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromFuncSpecText from './+state/func-spec-text.reducer';
import { FuncSpecTextEffects } from './+state/func-spec-text.effects';
import { FuncSpecTextFacade } from './+state/func-spec-text.facade';

export * from './func-spec-text.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromFuncSpecText.FUNCSPECTEXT_FEATURE_KEY,
      fromFuncSpecText.reducer
    ),
    EffectsModule.forFeature([FuncSpecTextEffects]),
  ],
  providers: [FuncSpecTextFacade],
})
export class FuncSpecTextStateModule {}
