import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FeedbackModule, UiModule } from '@ama-studio/ui';
import { AppComponent } from './app.component';
import {
  StoreRouterConnectingModule,
  NavigationActionTiming,
  DefaultRouterStateSerializer,
} from '@ngrx/router-store';
import { environment } from '../environments/environment';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/* Locales */
import { registerLocaleData } from '@angular/common';
import localeSe from '@angular/common/locales/sv';
registerLocaleData(localeSe, 'sv-SE');

import { EffectsModule } from '@ngrx/effects';
import { NxModule } from '@nrwl/angular';
import { AuthFacade, AuthInterceptor } from '@ama-studio/login';
import { NavbarModule } from '@ama-studio/navbar';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { EnvironmentConfig } from '@ama-studio/shared';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { FuncSpecCoreStateModule } from '@ama-studio/func-spec-core-state';
import { MainComponent } from './layout/main.component';
import { FeatureFlagsService } from '@ama-studio/featureflags';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FsGlobalStateModule } from '@ama-studio/fs-global-state';
import { FuncSpecChapterModule } from '@ama-studio/func-spec-chapter';
import {
  ChaptersFacade,
  ChaptersResolver,
} from '@ama-studio/func-spec-chapter-state';
import {
  ToastNotificationsErrorInterceptor,
  ToastNotificationsModule,
} from '@ama-studio/toast-notifications';
import { FuncSpecStatusModule } from '@ama-studio/func-spec-status';
import { IncidentWidgetModule } from '@ama-studio/incident-widget';

@NgModule({
  declarations: [AppComponent, MainComponent],
  imports: [
    BrowserModule,
    NavbarModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NxModule.forRoot(),
    StoreRouterConnectingModule.forRoot({
      serializer: DefaultRouterStateSerializer,
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
    HttpClientModule,
    UiModule,
    FsGlobalStateModule,
    FuncSpecCoreStateModule,
    ToastNotificationsModule,
    StoreDevtoolsModule.instrument({ maxAge: 50 }),
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
          strictActionTypeUniqueness: true,
          strictActionWithinNgZone: true,

          //NX navigation requires DefaultRouterStateSerializer, so these cannot be strict
          strictActionSerializability: false,
          strictStateSerializability: false,
        },
      }
    ),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    ModalModule.forRoot(),
    FuncSpecChapterModule,
    FeedbackModule,
    FuncSpecStatusModule,
    IncidentWidgetModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ToastNotificationsErrorInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'sv' },
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
    { provide: LOCALE_ID, useValue: 'sv-SE' },
    {
      provide: FeatureFlagsService,
      useFactory: (envConfig: EnvironmentConfig, authFacade: AuthFacade) =>
        new FeatureFlagsService(envConfig, authFacade.userId$),
      deps: [EnvironmentConfig, AuthFacade],
    },
    {
      provide: 'googleTagManagerId',
      useFactory: (envConfig: EnvironmentConfig) => envConfig.trackingId,
        deps: [EnvironmentConfig],
    },
    {
      provide: ErrorHandler
    },
    ChaptersFacade,
    ChaptersResolver,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
