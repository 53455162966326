/* eslint-disable @typescript-eslint/naming-convention */
import { IAuthConfig } from './../interfaces/IAuthConfig';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthConfig implements IAuthConfig {
  authority!: string;
  client_id!: string;
  redirect_uri!: string;
  response_type!: string;
  scope!: string;
  post_logout_redirect_uri!: string;
  silent_redirect_uri!: string;
  silentRequestTimeout!: number;
  automaticSilentRenew!: boolean;
}
