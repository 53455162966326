import { Injectable } from '@angular/core';
import {
  EnvironmentConfig,
  BaseHttpService,
  APIFunctionalSpecifications,
  APIProjects,
} from '@ama-studio/shared';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FuncSpecCoreService extends BaseHttpService {
  constructor(http: HttpClient, envConfig: EnvironmentConfig) {
    super(http, envConfig);
  }

  private apiUrl = `${this.envConfig.baseApiUrl}/functionalspecifications`;
  private projectUrl = `${this.envConfig.baseApiUrl}/projects`;

  private getFuncSpecURL(funcSpecId: string) {
    return `${this.apiUrl}/${funcSpecId}`;
  }

  private getProjectUrl(projectId: string) {
    return `${this.projectUrl}/${projectId}`;
  }

  public getFuncSpec(funcSpecId: string) {
    return this.http.get<APIFunctionalSpecifications.IFunctionalSpecificationOverviewContainerViewModel>(
      this.getFuncSpecURL(funcSpecId),
      this.headers
    );
  }

  public getProject(projectId: string) {
    return this.http.get<APIProjects.IProjectViewModel>(
      this.getProjectUrl(projectId),
      this.headers
    );
  }

}
