import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig } from '../injectables';
import { BaseHttpService } from './base-http.service';

@Injectable({
  providedIn: 'root',
})
export class LicenseService extends BaseHttpService {
  constructor(http: HttpClient, envConfig: EnvironmentConfig) {
    super(http, envConfig);
  }

  apiUrl = `${this.envConfig.baseApiUrl}/licences`;

  getHasLicenseUrl() {
    return `${this.apiUrl}/haslicence`;
  }

  getRequirementTemplateLicenseUrl(requirementTemplateId: string) {
    return `${this.apiUrl}/requirementtemplates/${requirementTemplateId}/haslicence`;
  }

  public hasLicense() {
    return this.http.get<boolean>(this.getHasLicenseUrl(), this.headers);
  }

  public hasRequirementTemplateLicens(requirementTemplateId: string) {
    return this.http.get<boolean>(
      this.getRequirementTemplateLicenseUrl(requirementTemplateId),
      this.headers
    );
  }
}
