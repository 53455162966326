import { LoginService } from './../services/login.service';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(private loginService: LoginService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let params: { [key: string]: string } | undefined = undefined;
    let requestedUrl = state.url;
    state.root.queryParamMap.keys.forEach((queryKey: string) => {
      if (queryKey === 'redirectUrl') {
        requestedUrl = state.root.queryParamMap.get(queryKey) ?? '';
      } else {
        const value = state.root.queryParamMap.get(queryKey);
        if (value !== null) {
          params = {
            [queryKey]: value,
          };
        }
      }
    });
    return this.loginService.canActivate(requestedUrl, params);
  }
}
