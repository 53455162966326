import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FsSidepanelComponent } from './fs-sidepanel/fs-sidepanel.component';

@NgModule({
  imports: [CommonModule],
  declarations: [FsSidepanelComponent],
  exports: [FsSidepanelComponent],
})
export class FsSidepanelModule {}
