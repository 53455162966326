import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ToastFacade } from '../+state/toast.facade';
import { removeToastError, removeToastMessage } from '../+state/toast.actions';
import { IToastErrorMessage, IToastMessage } from '../interfaces';

interface IModal {
  show: () => void;
  hide: () => void;
}

@Component({
  selector: 'ama-studio-toast-notifications-message',
  templateUrl: './toast-notifications-message.component.html',
  styleUrls: ['./toast-notifications-message.component.scss'],
})
export class ToastNotificationsMessageComponent
  implements OnInit, AfterViewInit
{
  modal!: IModal;
  modalElement!: ElementRef<HTMLElement>;

  @ViewChild('modal') set setModal(modal: ElementRef<HTMLElement>) {
    this.modalElement = modal;
    this.modal = new this.window.bootstrap.Modal(modal.nativeElement);
  }

  @Input() message!: IToastMessage | IToastErrorMessage;

  public messageType!: { icon: string; iconClass: string };

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject('Window') private window: any,
    private toastFacade: ToastFacade
  ) {}

  ngOnInit() {
    if (this.message) {
      this.mapMessageType();
    }
  }

  mapMessageType() {
    switch (this.message.type) {
      case 'success':
        this.messageType = {
          icon: 'done',
          iconClass: 'text-green',
        };
        break;
      case 'locked':
        this.messageType = {
          icon: 'lock',
          iconClass: 'text-blue',
        };
        break;
      case 'warning':
        this.messageType = {
          icon: 'warning',
          iconClass: 'text-warning',
        };
        break;
      case 'error':
        this.messageType = {
          icon: 'warning',
          iconClass: 'text-danger',
        };
        break;
    }
  }

  ngAfterViewInit(): void {
    if (this.messageType) {
      this.modal.show();
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutsideComponent(event: Event) {
    if (!this.modalElement.nativeElement.contains(event.target as Node)) {
      this.modal.hide();
      this.closeMessage();
    }
  }

  closeMessage() {
    // Remove it with a delay to let the animation finish
    setTimeout(() => {
      if (this.message.type === 'error') {
        this.toastFacade.dispatch(removeToastError());
      } else {
        this.toastFacade.dispatch(removeToastMessage());
      }
    }, 500);
  }
}
