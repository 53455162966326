import { Injectable } from '@angular/core';
import { Store, Action, select } from '@ngrx/store';
import { FuncSpecExportPartialState } from './func-spec-export.reducer';
import * as FuncSpecExportSelectors from './func-spec-export.selectors';

@Injectable()
export class FuncSpecExportFacade {

  constructor(
    private store: Store<FuncSpecExportPartialState>
  ) {}

  exportResult$ = this.store.pipe(
    select(FuncSpecExportSelectors.getExportResult)
  );

  isObjectSelectionMode$ = this.store.pipe(
    select(FuncSpecExportSelectors.getSelectionMode)
  );

  formState$ = this.store.pipe(
    select(FuncSpecExportSelectors.getFormState)
  );

  dispatch(action: Action) {
    this.store.dispatch(action);
  }
}
