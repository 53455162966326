import { createReducer, on, Action } from '@ngrx/store';
import {
  ChapterType,
  APIFunctionalSpecifications,
  APIProjects,
} from '@ama-studio/shared';

import * as FuncSpecCoreActions from './func-spec-core.actions';

export const FUNCSPECCORE_FEATURE_KEY = 'funcSpecCore';

/* eslint-disable @typescript-eslint/naming-convention */
export enum RightNavTab {
  Guidance,
  Comment,
  Search,
}
/* eslint-enable @typescript-eslint/naming-convention */

export interface State {
  error?: string | null; // last known error (if any)

  inFuncSpecView: boolean;

  funcSpec: APIFunctionalSpecifications.IFunctionalSpecificationOverviewContainerViewModel;
  functionalSpecificationId: string;
  projectModel: APIProjects.IProjectViewModel;
  projectId: string | null;
  chapterId: string | null;
  chapterNumber: number;
  requirementTemplateId: string | null;
  requirementTemplateName: string | null;
  hasLicenseToRequirementTemplate: boolean | null;

  selectedRightNavTab: RightNavTab;
  rightNavTabHeadline: string;
  showCommentTab: boolean | null;
  showSearchResultTab: boolean | null;
  chapterType: ChapterType;
}

export interface FuncSpecCorePartialState {
  readonly [FUNCSPECCORE_FEATURE_KEY]: State;
}

export const initialState: State = {
  inFuncSpecView: false,

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  funcSpec: null,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  functionalSpecificationId: null,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  projectModel: null,
  projectId: null,
  chapterId: null,
  chapterNumber: 0,
  requirementTemplateId: null,
  requirementTemplateName: null,
  hasLicenseToRequirementTemplate: null,

  selectedRightNavTab: RightNavTab.Guidance,
  rightNavTabHeadline: 'Vägledning',
  showCommentTab: null,
  showSearchResultTab: null,
  chapterType: ChapterType.Text,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
};

const funcSpecCoreReducer = createReducer(
  initialState,
  on(FuncSpecCoreActions.clearState, () => initialState),
  on(FuncSpecCoreActions.inFuncSpecView, (state) => ({
    ...state,
    inFuncSpecView: true,
  })),
  on(FuncSpecCoreActions.leavingFuncSpecView, (state) => ({
    ...state,
    inFuncSpecView: false,
  })),
  on(
    FuncSpecCoreActions.setFuncSpec,
    (state, { functionalSpecificationId }) => ({
      ...state,
      functionalSpecificationId,
    })
  ),
  on(FuncSpecCoreActions.setProjectId, (state, { projectId }) => ({
    ...state,
    projectId,
  })),
  on(
    FuncSpecCoreActions.setChapter,
    (state, { chapterId, chapterNumber, chapterType }) => ({
      ...state,
      chapterId,
      chapterNumber,
      chapterType,
    })
  ),
  on(FuncSpecCoreActions.selectTab, (state, { tab }) => ({
    ...state,
    selectedRightNavTab: tab,
  })),
  on(
    FuncSpecCoreActions.setRequirementTemplate,
    (state, { requirementTemplateId }) => ({
      ...state,
      requirementTemplateId,
    })
  ),
  on(FuncSpecCoreActions.loadFuncSpecSuccess, (state, { funcSpec }) => ({
    ...state,
    funcSpec,
  })),
  on(FuncSpecCoreActions.loadFuncSpecFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(FuncSpecCoreActions.loadProjectSuccess, (state, { projectModel }) => ({
    ...state,
    projectModel,
  })),
  on(FuncSpecCoreActions.loadProjectFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(
    FuncSpecCoreActions.setRightNavTabHeadline,
    (state, { rightNavTabHeadline }) => ({
      ...state,
      rightNavTabHeadline,
    })
  ),
  on(FuncSpecCoreActions.setShowCommentTab, (state, { showCommentTab }) => ({
    ...state,
    showCommentTab,
  })),
  on(
    FuncSpecCoreActions.setShowSearchResultTab,
    (state, { showSearchResultTab }) => ({
      ...state,
      showSearchResultTab,
    })
  ),
  on(
    FuncSpecCoreActions.setHasLicenseToRequirementTemplateSuccess,
    (state, { hasLicenseToRequirementTemplate }) => ({
      ...state,
      hasLicenseToRequirementTemplate,
    })
  ),
  on(
    FuncSpecCoreActions.setHasLicenseToRequirementTemplateFailure,
    (state, { error }) => ({
      ...state,
      error,
    })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return funcSpecCoreReducer(state, action);
}
