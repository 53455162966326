import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaxLengthInputComponent } from './max-length-input/max-length-input.component';
import { NgrxFormsModule } from 'ngrx-forms';
import { MatInputModule } from '@angular/material/input';
import { DirectivesModule } from '@ama-studio/shared';

@NgModule({
  imports: [CommonModule, MatInputModule, NgrxFormsModule, DirectivesModule],
  declarations: [MaxLengthInputComponent],
  exports: [MaxLengthInputComponent],
})
export class MaxLengthInputModule {}
