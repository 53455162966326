import { createReducer, on } from '@ngrx/store';
import {
  createFormGroupState,
  FormGroupState,
  onNgrxForms,
  updateGroup,
  validate,
  wrapReducerWithFormStateUpdate,
} from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';

import * as FuncSpecExportActions from './func-spec-export.actions';

export const FUNCSPECEXPORT_FEATURE_KEY = 'funcSpecExport';

export interface FuncSpecExportForm {
  selectedStructureItem: string;
}

export interface ExportResult {
  result: Result;
  fullExport: boolean;
}

export enum Result {
  none = 'None',
  success = 'Success',
  error = 'Error',
}

export interface State {
  queue: string[];
  exportResult: ExportResult;
  exportSelectionMode: boolean;
  formState: FormGroupState<FuncSpecExportForm>;
}

export interface FuncSpecExportPartialState {
  readonly [FUNCSPECEXPORT_FEATURE_KEY]: State;
}

export const initialFormState = createFormGroupState<FuncSpecExportForm>(
  FUNCSPECEXPORT_FEATURE_KEY,
  {
    selectedStructureItem: '',
  }
);

export const validateAndUpdateForm = updateGroup<FuncSpecExportForm>({
  selectedStructureItem: validate(required),
});

export const initialState: State = {
  queue: [],
  exportResult: {
    result: Result.none,
    fullExport: false,
  },
  exportSelectionMode: false,
  formState: initialFormState,
};

const funcSpecExportReducer = createReducer(
  initialState,
  on(FuncSpecExportActions.fullExportSuccess, (state) => ({
    ...state,
    exportResult: {
      result: Result.success,
      fullExport: true,
    },
  })),
  on(FuncSpecExportActions.exportPdfSuccess, (state, { pdfId }) => ({
    ...state,
    queue: [...state.queue, pdfId],
    exportResult: {
      result: Result.success,
      fullExport: false,
    },
    formState: initialFormState,
  })),
  on(FuncSpecExportActions.downloadPdf, (state, { pdfId }) => ({
    ...state,
    queue: removeItem(state.queue, pdfId),
    formState: initialFormState,
  })),
  on(FuncSpecExportActions.exportFailed, (state, { pdfId }) => ({
    ...state,
    queue: removeItem(state.queue, pdfId),
    formState: initialFormState,
    exportResult: {
      result: Result.error,
      fullExport: false,
    },
  })),
  on(FuncSpecExportActions.fullExportFailed, (state) => ({
    ...state,
    exportResult: {
      result: Result.error,
      fullExport: true,
    },
  })),
  on(FuncSpecExportActions.clearExportResult, (state) => ({
    ...state,
    exportResult: {
      result: Result.none,
      fullExport: false,
    },
  })),
  on(FuncSpecExportActions.selectionForExport, (state, { selectionMode }) => ({
    ...state,
    exportSelectionMode: selectionMode,
    formState: clearFormState(state.formState, selectionMode),
  })),
  onNgrxForms()
);

function removeItem(queue: string[], pdfId: string) {
  const newQueue = [...queue];
  const index = queue.findIndex((i) => i === pdfId);
  newQueue.splice(index, 1);
  return newQueue;
}

function clearFormState(
  formState: FormGroupState<FuncSpecExportForm>,
  selectionMode: boolean
) {
  if (!selectionMode) {
    formState = initialFormState;
  }
  return formState;
}

export const reducer = wrapReducerWithFormStateUpdate(
  funcSpecExportReducer,
  (state) => state.formState,
  validateAndUpdateForm
);
