<ul class="nav flex-column d-flex" id="menu">
  <div
    class="chapter-list-item w-100"
    *ngFor="let chapter of allChapters$ | async; let i = index"
  >
    <ama-studio-func-spec-chapter-route-item
      [chapter]="chapter"
      [chapterNumber]="i"
    >
      <ama-studio-func-spec-chapter-menu-item
        [chapterNumber]="i"
        title="{{ chapter.title }}"
        [isSelected]="chapterNumberIsSelected$(i) | async"
      ></ama-studio-func-spec-chapter-menu-item>
    </ama-studio-func-spec-chapter-route-item>
  </div>
</ul>
