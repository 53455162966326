import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromChapters from './chapters.reducer';
import * as ChaptersSelectors from './chapters.selectors';

@Injectable()
export class ChaptersFacade {
  loaded$ = this.store.pipe(select(ChaptersSelectors.getChaptersLoaded));
  allChapters$ = this.store.pipe(select(ChaptersSelectors.getAllChapters));

  exportableChapters$ = this.store.pipe(
    select(ChaptersSelectors.getExportableChapters)
  );

  functionalSpecificationId$ = this.store.pipe(
    select(ChaptersSelectors.getFunctionalSpecificationId)
  );

  selectedChapterId$ = this.store.pipe(
    select(ChaptersSelectors.getSelectedChapterId)
  );

  selectedChapterNumber$ = this.store.pipe(
    select(ChaptersSelectors.getSelectedChapterNumber)
  );

  chapterIdIsSelected$ = (chapterId: string) =>
    this.store.pipe(
      select(ChaptersSelectors.getChapterIdIsSelected(chapterId))
    );

  chapterNumberIsSelected$ = (chapterNumber: number) =>
    this.store.pipe(
      select(ChaptersSelectors.getChapterNumberIsSelected(chapterNumber))
    );

  constructor(private store: Store<fromChapters.ChaptersPartialState>) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }
}
