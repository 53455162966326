import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  TemplateRef,
} from '@angular/core';
import { ChaptersFacade } from '@ama-studio/func-spec-chapter-state';
import { clearState } from '@ama-studio/func-spec-chapter-state';
import { Observable } from 'rxjs';
import { APIChapters, ChapterType, NonChapter } from '@ama-studio/shared';

@Component({
  selector: 'ama-studio-func-spec-chapter-navigator',
  templateUrl: './func-spec-chapter-navigator.component.html',
  styleUrls: ['./func-spec-chapter-navigator.component.scss'],
})
export class FuncSpecChapterNavigatorComponent implements OnInit, OnDestroy {
  allChapters$: Observable<APIChapters.IRequirementTemplateTextItemViewModel[]>;
  chapterIdIsSelected$: (chapterId: string) => Observable<boolean>;
  chapterNumberIsSelected$: (chapterNumber: number) => Observable<boolean>;
  funcSpecId$: Observable<string>;
  nonChapter = NonChapter;
  chapterType = ChapterType;

  @Input()
  bodyTemplateRef: TemplateRef<unknown>;

  constructor(
    private chaptersFacade: ChaptersFacade
  ) {}

  ngOnInit(): void {
    this.allChapters$ = this.chaptersFacade.allChapters$;
    this.chapterIdIsSelected$ = this.chaptersFacade.chapterIdIsSelected$;
    this.chapterNumberIsSelected$ =
      this.chaptersFacade.chapterNumberIsSelected$;
    this.funcSpecId$ = this.chaptersFacade.functionalSpecificationId$;
  }

  ngOnDestroy(): void {
    this.chaptersFacade.dispatch(clearState());
  }
}
