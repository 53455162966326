import { ChaptersFacade } from '@ama-studio/func-spec-chapter-state';
import { FuncSpecCoreFacade } from '@ama-studio/func-spec-core-state';
import {
  AnalyticsService,
  APIChapters,
  APIStatus,
  ChapterType,
} from '@ama-studio/shared';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {
  clearExportResult,
  fullExport,
  selectionForExport,
} from '../+state/func-spec-export.actions';
import { FuncSpecExportFacade } from '../+state/func-spec-export.facade';
import { Result } from '../+state/func-spec-export.reducer';
import { FuncSpecStatusFacade } from '@ama-studio/func-spec-status';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'ama-studio-func-spec-export-modal',
  templateUrl: './func-spec-export-modal.component.html',
  styleUrls: ['./func-spec-export-modal.component.scss'],
})
export class FuncSpecExportModalComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('exportModal')
  exportModal: ElementRef<HTMLElement>;

  @ViewChild('exportSuccess', { static: false })
  exportSuccess: ModalDirective;

  @ViewChild('fullExportSuccess', { static: false })
  fullExportSuccess: ModalDirective;

  @ViewChild('exportFailed', { static: false })
  exportFailed: ModalDirective;

  chapters$: Observable<APIChapters.IRequirementTemplateTextItemViewModel[]>;
  chapterIndex: number;
  private _expanded$ = new BehaviorSubject<number>(-1);
  expanded$: Observable<number>;
  chapterType = ChapterType;
  subscriptions: Subscription[] = [];
  funcSpecId$: Observable<string>;

  status$: Observable<APIStatus.Status | null>;
  statusEnum = APIStatus.Status;

  _emailInputExpanded$ = new BehaviorSubject<boolean>(false);
  emailInputExpanded$: Observable<boolean>;
  email: FormControl;
  isFullExport: boolean;

  constructor(
    private chapterFacade: ChaptersFacade,
    private exportFacade: FuncSpecExportFacade,
    private statusFacade: FuncSpecStatusFacade,
    private coreFacade: FuncSpecCoreFacade,
    private router: Router,
    private analyticsService: AnalyticsService
  ) {
    this.expanded$ = this._expanded$.asObservable();
    this.emailInputExpanded$ = this._emailInputExpanded$.asObservable();
    this.subscriptions.push(
      this.exportFacade.exportResult$.subscribe((exportResult) => {
        if (exportResult.result === Result.success) {
          this.email.reset();
          if (exportResult.fullExport) {
            this.fullExportSuccess.show();
          } else {
            this.exportSuccess.show();
          }
        } else if (exportResult.result === Result.error) {
          this.exportFailed.show();
        }
      })
    );
    this.funcSpecId$ = this.coreFacade.functionalSpecificationId$;
    this.email = new FormControl('', Validators.email);
  }

  ngOnInit(): void {
    this.chapters$ = this.chapterFacade.exportableChapters$;
    this.chapterIndex = -1;
    this.status$ = this.statusFacade.status$;
  }

  ngAfterViewInit() {
    this.exportModal.nativeElement.addEventListener('hide.bs.modal', () => {
      setTimeout(() => this.handleExpandedChange(-1), 150);
    });
  }

  handleExpandedChange(expandedIndex: number) {
    this.chapterIndex = expandedIndex;
    this._expanded$.next(expandedIndex);
  }

  handleEmailInputExpandedChange(value: boolean) {
    this._emailInputExpanded$.next(value);
  }

  navigateToChapter(
    funcSpecId: string,
    chapter: APIChapters.IRequirementTemplateTextItemViewModel
  ) {
    this.exportFacade.dispatch(selectionForExport({ selectionMode: true }));
    this.router.navigate([
      'functionalspecifications',
      funcSpecId,
      'chapters',
      chapter.chapterNumber,
      chapter.id,
      chapter.chapterType,
    ]);
  }

  closeExportSuccess(): void {
    this.exportSuccess.hide();
    this.fullExportSuccess.hide();
    this.exportFacade.dispatch(clearExportResult());
  }

  closeExportFailed(): void {
    this.exportFailed.hide();
    this.exportFacade.dispatch(clearExportResult());
  }

  fullExport() {
    this.exportFacade.dispatch(fullExport({ email: this.email.value }));
    this.analyticsService.fullExport();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
