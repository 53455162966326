import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import { FeedbackFacade } from '../+state/feedback.facade';
import { State as FeedbackState } from '../+state/feedback.reducer';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';

@Component({
  selector: 'sbui-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: [
    '../styles/feedback.scss',
    '../styles/modal.scss',
    '../styles/button.scss',
  ],
})
export class FeedbackFormComponent implements OnInit, OnChanges {
  message = 'Hej, ';

  @Input()
  isVisible!: boolean;

  @ViewChild('dialog')
  dialog!: ElementRef<HTMLElement>;

  @ViewChild('textarea')
  textarea!: ElementRef<HTMLTextAreaElement>;

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  @Output()
  modalVisibilityChanged = new EventEmitter<{
    action: string;
    value: boolean;
  }>();

  @Output()
  submitFeedback = new EventEmitter<string>();
  feedbackState$!: Observable<FeedbackState>;

  constructor(private feedbackFacade: FeedbackFacade, private ngZone: NgZone) {}

  handleClose(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.modalVisibilityChanged.emit({ action: 'feedback-form', value: false });
  }

  onSubmit(value: { message: string }): void {
    this.submitFeedback.emit(value.message);
    this.message = '';
  }

  onRetry(value: { message: string }, event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.onSubmit(value);
  }

  isDescendant(child: HTMLElement | null, parent: HTMLElement | null): boolean {
    let element = child;
    while (element !== null) {
      if (element.isSameNode(parent)) {
        return true;
      }

      element = element.parentElement;
    }
    return false;
  }

  isClickOnDialogComponent(
    dialog: HTMLElement | null,
    ev: MouseEvent
  ): boolean {
    return (
      ev.target === dialog ||
      this.isDescendant(ev.target as HTMLElement, dialog) === true
    );
  }

  onHideHandler(ev: MouseEvent) {
    if (!this.isVisible) {
      return;
    }

    if (!this.isClickOnDialogComponent(this.dialog.nativeElement, ev)) {
      this.modalVisibilityChanged.emit({ action: 'all', value: false });
    }
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this.ngZone.onStable
      .pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  ngOnInit(): void {
    this.feedbackState$ = this.feedbackFacade.feedback$;
  }

  ngOnChanges(): void {
    if (this.isVisible === true) {
      window.addEventListener('click', this.onHideHandler.bind(this));
    }
  }
}
