import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { FuncSpecCoreFacade } from './+state/func-spec-core.facade';
import { EffectsModule } from '@ngrx/effects';
import * as fromFuncSpecCore from './+state/func-spec-core.reducer';
import { FuncSpecCoreEffects } from './+state/func-spec-core.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromFuncSpecCore.FUNCSPECCORE_FEATURE_KEY,
      fromFuncSpecCore.reducer
    ),
    EffectsModule.forFeature([FuncSpecCoreEffects]),
  ],
  providers: [FuncSpecCoreFacade],
})
export class FuncSpecCoreStateModule {}
