import { createAction, props } from '@ngrx/store';
import { APIChapters, ChapterType } from '@ama-studio/shared';

export const loadChapters = createAction(
  '[Chapters] Load Chapters',
  props<{ funcSpecId: string }>()
);

export const loadChaptersSuccess = createAction(
  '[Chapters] Load Chapters Success',
  props<{
    overviewModel: APIChapters.IRequirementTemplateTextsOverviewViewModel;
  }>()
);

export const loadChaptersFailure = createAction(
  '[Chapters] Load Chapters Failure',
  props<{ error?: string }>()
);

export const selectChapter = createAction(
  '[Chapters] Select Chapter',
  props<{
    funcSpecId: string;
    chapterId: string;
    chapterNumber: number;
    chapterType: ChapterType;
  }>()
);

export const clearState = createAction('[Chapters] Clear State');
