import { APIStatus } from '@ama-studio/shared';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FuncSpecStatusFacade } from '../+state/func-spec-status.facade';

@Component({
  selector: 'ama-studio-func-spec-status-banner',
  templateUrl: './func-spec-status-banner.component.html',
  styleUrls: ['./func-spec-status-banner.component.scss']
})
export class FuncSpecStatusBannerComponent implements OnInit {

  status$: Observable<APIStatus.Status | null>;
  showBanner$: Observable<boolean>;
  statusEnum = APIStatus.Status;
  statusClassEnum = APIStatus.StatusClass;

  constructor(
    private statusFacade: FuncSpecStatusFacade
  ) { }

  ngOnInit(): void {
    this.status$ = this.statusFacade.status$;
    this.showBanner$ = this.statusFacade.showBanner$;
  }

}
