import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigatorItemComponent } from './navigator-item/navigator-item.component';
import { NavigatorComponent } from './navigator/navigator.component';

@NgModule({
  declarations: [NavigatorComponent, NavigatorItemComponent],
  exports: [NavigatorComponent, NavigatorItemComponent],
  imports: [CommonModule],
})
export class NavigatorModule {}
