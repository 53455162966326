import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ToastFacade } from '../+state/toast.facade';
import { IToastErrorMessage, IToastMessage } from '../interfaces';

@Component({
  selector: 'ama-studio-toast-notifications-container',
  templateUrl: './toast-notifications-container.component.html',
  styleUrls: ['./toast-notifications-container.component.scss'],
})
export class ToastNotificationsContainerComponent implements OnInit {
  message$!: Observable<IToastMessage | null>;
  errorMessage$!: Observable<IToastErrorMessage | null>;

  constructor(private toastFacade: ToastFacade) {}

  ngOnInit(): void {
    this.message$ = this.toastFacade.message$;
    this.errorMessage$ = this.toastFacade.errorMessage$;
  }
}
