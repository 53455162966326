export namespace APIStatus {

  /* eslint-disable @typescript-eslint/naming-convention */
  export enum Status {
    InProgress = 'InProgress',
    ReadyForReview = 'ReadyForReview',
    NotApproved = 'NotApproved',
    Approved = 'Approved',
    RevisionInProgress = 'RevisionInProgress',
    Replaced = 'Replaced',
    RevisionReadyForReview = 'RevisionReadyForReview',
    RevisionApproved = 'RevisionApproved',
    RevisionNotApproved = 'RevisionNotApproved'
  }

  export enum StatusClass {
    InProgress = 'under-arbete',
    ReadyForReview = 'ready-for-review',
    Approved = 'approved',
    NotApproved = 'not-approved',
    RevisionInProgress = 'revision-in-progress',
    RevisionReadyForReview = 'ready-for-review',
    RevisionNotApproved = 'not-approved',
    RevisionApproved = 'approved',
  }

  export enum StatusName {
    InProgress = 'Under arbete',
    ReadyForReview = 'För granskning',
    Approved = 'Godkänd',
    NotApproved = 'Inte godkänd',
    RevisionInProgress = 'Under revidering',
    RevisionReadyForReview = 'För granskning',
    RevisionNotApproved = 'Inte godkänd',
    RevisionApproved = 'Godkänd',
    Replaced = 'Ersatt'
  }

  export interface ReadyForReviewCommand {
    id: string;
    userId: number;
  }

}
