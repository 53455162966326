import { Injectable } from '@angular/core';
import {
  EnvironmentConfig,
  BaseHttpService,
  APIExport,
} from '@ama-studio/shared';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FuncSpecExportService extends BaseHttpService {
  constructor(http: HttpClient, envConfig: EnvironmentConfig) {
    super(http, envConfig);
  }

  private apiUrl = `${this.envConfig.baseApiUrl}/functionalspecifications`;

  private exportPdfUrl(id: string) {
    return `${this.apiUrl}/${id}/exports/pdf`;
  }

  private fullExportUrl(id: string) {
    return `${this.apiUrl}/${id}/exports/pdf/full`;
  }

  private checkPdfStatusUrl(id: string, pdfId: string) {
    return `${this.apiUrl}/${id}/exports/pdf/status/${pdfId}`;
  }

  public exportPdf(
    exportPDFCommand: APIExport.IExportPDFCommand
  ): Observable<APIExport.IPDFModel> {
    return this.http.post<APIExport.IPDFModel>(
      this.exportPdfUrl(exportPDFCommand.id),
      { ...exportPDFCommand },
      this.headers
    );
  }

  public fullExport(fullExportCommand: APIExport.IFullExportCommand) {
    return this.http.post(
      this.fullExportUrl(fullExportCommand.id),
      { ...fullExportCommand },
      this.headers
    );
  }

  public checkPdfStatus(
    checkPdfStatusCommand: APIExport.ICheckPDFStatusCommand
  ): Observable<APIExport.IPDFExportStatusModel> {
    return this.http.get<APIExport.IPDFExportStatusModel>(
      this.checkPdfStatusUrl(
        checkPdfStatusCommand.id,
        checkPdfStatusCommand.pdfId
      ),
      this.headers
    );
  }
}
