import { LogoutComponent } from './components/logout/logout.component';
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component';
import { LoginComponent } from './components/login/login.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Route } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import * as fromAuth from './+state/auth.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './+state/auth.effects';
import { UserMessageModule } from '@ama-studio/ui';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RedirectSpinnerComponent } from './components/redirect-spinner/redirect-spinner.component';

export const loginRoutes: Route[] = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'auth-callback',
    component: AuthCallbackComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    UserMessageModule,
    MatProgressSpinnerModule,
    StoreModule.forFeature(fromAuth.authFeatureKey, fromAuth.reducer),
    EffectsModule.forFeature([AuthEffects]),
    RouterModule.forChild(loginRoutes),
  ],
  declarations: [
    LoginComponent,
    LogoutComponent,
    AuthCallbackComponent,
    RedirectSpinnerComponent,
  ],
  exports: [RouterModule],
})
export class LoginModule {}
