<div
  [class.show]="isVisible ? 'show' : null"
  class="modal fade modal--feedback-action"
  id="feedback-action"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
  data-bs-backdrop="false"
  #modal
>
  <div
    class="modal-dialog modal-dialog-bottom modal-dialog--feedback-action"
    #dialog
  >
    <div class="modal-content modal-content--feedback-action">
      <div class="modal-header modal-header--feedback-action">
        <div class="d-flex flex-column">
          <h3 class="modal-title modal-title--feedback-action">AMA Funktion</h3>
          <p class="modal-header__text">
            Vi finns här för att hjälpa dig, kontakta kundservice eller lämna
            dina synpunkter. Vad kan vi göra för dig?
          </p>
        </div>
      </div>
      <div class="modal-body modal-body--feedback-action">
        <div class="feedback-actions">
          <div class="feedback-actions__item">
            <h4 class="feedback-actions__item__title">Kontakta kundservice</h4>
            <a
              href="https://byggtjanst.se/kontakta-oss"
              target="_blank"
              rel="noreferrer"
              class="btn btn-outline-primary btn--feedback-button"
              ><span>Kontakta oss</span></a
            >
          </div>
          <div class="feedback-actions__item">
            <h4 class="feedback-actions__item__title">Lämna dina synpunkter</h4>
            <p class="feedback-actions__item__text">
              Hjälp oss att bli bättre.
            </p>
            <button
              class="btn btn-outline-primary btn--feedback-button"
              (click)="toggleModal($event)"
            >
              <span class="me-2">Lämna synpunkter</span>
              <span class="material-icons">send</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
