import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FUNCSPECEXPORT_FEATURE_KEY, State } from './func-spec-export.reducer';

export const getFuncSpecExportState = createFeatureSelector<State>(
  FUNCSPECEXPORT_FEATURE_KEY
);

export const getQueue = createSelector(
  getFuncSpecExportState,
  (state: State) => state.queue
);

export const getExportResult = createSelector(
  getFuncSpecExportState,
  (state: State) => state.exportResult
);

export const getSelectionMode = createSelector(
  getFuncSpecExportState,
  (state: State) => state.exportSelectionMode
);

export const getFormState = createSelector(
  getFuncSpecExportState,
  (state: State) => state.formState
);
