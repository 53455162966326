import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackComponent } from './feedback-component/feedback.component';
import { FeedbackActionComponent } from './feedback-action/feedback-action.component';
import { FeedbackFormComponent } from './feedback-form/feedback-form.component';
import { FormsModule } from '@angular/forms';
import { TextFieldModule } from '@angular/cdk/text-field';
import { FeedbackFacade } from './+state/feedback.facade';
import { FeedbackEffects } from './+state/feedback.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromFeedback from './+state/feedback.reducer';

@NgModule({
  declarations: [
    FeedbackComponent,
    FeedbackActionComponent,
    FeedbackFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    StoreModule.forFeature(
      fromFeedback.FEEDBACK_FEATURE_KEY,
      fromFeedback.reducer
    ),
    EffectsModule.forFeature([FeedbackEffects]),
    TextFieldModule,
  ],
  exports: [FeedbackComponent],
  providers: [FeedbackFacade],
})
export class FeedbackModule {}
