import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from './auth.reducer';

export const selectAuthState = createFeatureSelector<fromAuth.State>(
  fromAuth.authFeatureKey
);

export const getIsPending = createSelector(
  selectAuthState,
  (state) => state.isPending
);

export const getIsLoggedIn = createSelector(
  selectAuthState,
  (state) => state.isLoggedIn
);

export const getAccessToken = createSelector(
  selectAuthState,
  (state) => `${state.tokenType} ${state.accessToken}`
);
export const getEmail = createSelector(selectAuthState, (state) => state.email);
export const getFullName = createSelector(selectAuthState, (state) => {
  const { firstName, lastName } = state;
  return `${firstName} ${lastName}`;
});
export const getInitials = createSelector(selectAuthState, (state) => {
  const { firstName, lastName } = state;
  return `${firstName[0]?.toUpperCase() || ''}${
    lastName[0]?.toUpperCase() || ''
  }`;
});

export const getHasValidLicense = createSelector(
  selectAuthState,
  (state) => state.hasValidLicense
);

export const getUserId = createSelector(
  selectAuthState,
  (state) => state.userId
);

export const getRole = createSelector(
  selectAuthState,
  (state) => state.role
);
