import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromFuncSpecInvite from './func-spec-invite.reducer';
import * as FuncSpecInviteSelectors from './func-spec-invite.selectors';

@Injectable()
export class FuncSpecInviteFacade {
  loaded$ = this.store.pipe(
    select(FuncSpecInviteSelectors.getFuncSpecInviteLoaded)
  );

  error$ = this.store.pipe(
    select(FuncSpecInviteSelectors.getFuncSpecInviteError)
  );

  funcSpecId$ = this.store.pipe(select(FuncSpecInviteSelectors.getFuncSpecId));

  roles$ = this.store.pipe(select(FuncSpecInviteSelectors.getRoles));

  participants$ = this.store.pipe(
    select(FuncSpecInviteSelectors.getParticipants)
  );

  inviteFormActivity$ = this.store.pipe(
    select(FuncSpecInviteSelectors.getInviteFormActivity)
  );

  invitedStatus$ = this.store.pipe(
    select(FuncSpecInviteSelectors.getInvitedStatus)
  );

  roleDefinitions$ = this.store.pipe(
    select(FuncSpecInviteSelectors.getRoleDefinitions)
  );

  editingParticipant$ = this.store.pipe(
    select(FuncSpecInviteSelectors.getEditingParticipant)
  );

  constructor(
    private store: Store<fromFuncSpecInvite.FuncSpecInvitePartialState>
  ) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }
}
