import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { BaseHttpService, EnvironmentConfig, APINotifications } from '@ama-studio/shared'

@Injectable({
  providedIn: 'root'
})
export class FuncSpecNotificationsService extends BaseHttpService {

  constructor(http: HttpClient, envConfig: EnvironmentConfig) {
    super(http, envConfig);
  }

  private apiUrl = `${this.envConfig.baseApiUrl}`;

  private getUnreadNotificationsCountUrl(funcSpecId: string) {
    return `${this.apiUrl}/notifications/${funcSpecId}/unread/count`;
  }

  private getLatestNotificationsUrl(funcSpecId: string) {
    return `${this.apiUrl}/notifications/${funcSpecId}/latest`;
  }

  private getAllNotificationsUrl(funcSpecId: string) {
    return `${this.apiUrl}/notifications/${funcSpecId}/all`;
  }

  private markNotificationsAsReadUrl(funcSpecId: string) {
    return `${this.apiUrl}/notifications/${funcSpecId}/read`;
  }

  public getUnreadNotificationsCount(funcSpecId: string) {
    return this.http.get<APINotifications.INotificationsUnreadCountModel>(
      this.getUnreadNotificationsCountUrl(funcSpecId),
      this.headers
    );
  }

  public getLatestNotifications(funcSpecId: string) {
    return this.http.get<APINotifications.INotificationsModel>(
      this.getLatestNotificationsUrl(funcSpecId),
      this.headers
    );
  }

  public getAllNotifications(funcSpecId: string) {
    return this.http.get<APINotifications.INotificationsModel>(
      this.getAllNotificationsUrl(funcSpecId),
      this.headers
    );
  }

  public markNotificationsAsRead(funcSpecId: string) {
    return this.http.post<APINotifications.INotificationsModel>(
      this.markNotificationsAsReadUrl(funcSpecId),
      this.headers
    );
  }

}
