import { UserViewModel } from './models/sharedModels';

export namespace APITexts {
  export interface ITextItemViewModel {
    functionalSpecificationsId: string;
    functionalSpecificationTextId: string;
    requirementTemplateTextItemId: string;
    requirementId: string;
    parentId: string | null;
    headingNumber: string;
    title: string;
    content: string;
    created: UserViewModel;
    updated: UserViewModel;
    children: string[];
    totalComments: number;
    code: string;
  }

  export interface ITextItemContentViewModel {
    functionalSpecificationTextId: string;
    content: string;
  }

  export interface IChapterListViewModel {
    textItems: ITextItemViewModel;
  }

  export interface IAvailableChapterTextItemViewModel {
    textItemId: string;
    parentId: string;
    headingNumber: string;
    title: string;
  }

  export interface IPatchTextCommand {
    id: string;
    functionalSpecificationTextId: string;
    content: string;
  }

  export interface IPatchActiveTextCommand {
    functionalSpecificationTextId: string;
    isActive: boolean;
  }

  export enum ITextItemSection {
    none = 'None',
    info = 'Information',
    content = 'Content',
  }
}
