<div class="d-flex cursor-pointer"
  [ngClass]="{ 'aligned': (unreadCount$ | async) > 0 }">
  <i class="material-icons icon">
    notifications
  </i>
  <div class="counter" *ngIf="(unreadCount$ | async) > 0">
    <span class="text-small fw-bold count">
      {{ unreadCount$ | async }}
    </span>
  </div>
</div>
