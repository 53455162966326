import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { FunctionSpecificationInviteService } from '../func-spec-invite.service';

import * as fromFuncSpecInvite from './func-spec-invite.reducer';
import * as FuncSpecInviteActions from './func-spec-invite.actions';
import {
  catchError,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  getFuncSpecId,
  getFuncSpecInviteState,
} from './func-spec-invite.selectors';
import { select, Store } from '@ngrx/store';
import { APIFunctionalSpecifications } from '@ama-studio/shared';
import { HttpErrorResponse } from '@angular/common/http';
import { EMPTY } from 'rxjs';
import { showSuccessToastMessage } from '@ama-studio/toast-notifications';

@Injectable()
export class FuncSpecInviteEffects {
  loadAccessibleRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecInviteActions.loadAccessibleRoles),
      fetch({
        run: (action) =>
          this.inviteService.getAccessibleRoles(action.projectId).pipe(
            map((accessibleRoles) =>
              FuncSpecInviteActions.loadAccessibleRolesSuccess({
                accessibleRoles,
              })
            )
          ),
        onError: (action, error: HttpErrorResponse | null) => {
          console.error('Error', error);
          return FuncSpecInviteActions.loadAccessibleRolesFailure({
            error: error?.message,
          });
        },
      })
    )
  );

  startEditParticipantRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecInviteActions.startEditParticipantRole),
      withLatestFrom(this.store.pipe(select(getFuncSpecInviteState))),
      switchMap(([action, state]) =>
        state.accessibleRoles.length === 0
          ? [
              FuncSpecInviteActions.loadAccessibleRoles({
                projectId: action.projectId,
              }),
            ]
          : EMPTY
      )
    )
  );

  inviteParticipant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecInviteActions.inviteParticipant),
      withLatestFrom(this.store.pipe(select(getFuncSpecInviteState))),
      fetch({
        run: (action, state) => {
          if (!state) throw new Error('Argument should not be null');

          const { roleId, email } = action

          // Check duplicate. Emails should always be lowercase.
          if (
            Object.prototype.hasOwnProperty.call(
              state.entities,
              email.toLowerCase()
            )
          ) {
            return FuncSpecInviteActions.setInvitationDuplicateError();
          }

          return this.inviteService
            .inviteParticipant({ id: state.funcSpecId, roleId, email })
            .pipe(
              switchMap(inviteInfo => [
                FuncSpecInviteActions.addParticipantToState({
                  participant: inviteInfo,
                }),
                FuncSpecInviteActions.clearInvitation(),
                FuncSpecInviteActions.inviteParticipantSuccess()
              ]),
              catchError(response => {
                const props = response?.error;
                if (props?.errors.Account) {
                  return [
                    FuncSpecInviteActions.invitationFailed({
                      invitedStatus: APIFunctionalSpecifications.IInvitedParticipantStatus.missingAccount
                    })
                  ];
                } else if (props?.errors.User) {
                  return [
                    FuncSpecInviteActions.invitationFailed({
                      invitedStatus: APIFunctionalSpecifications.IInvitedParticipantStatus.duplicateAccount
                    }),
                  ];
                } else if (props?.errors.Licence) {
                  return [
                    FuncSpecInviteActions.invitationFailed({
                      invitedStatus: APIFunctionalSpecifications.IInvitedParticipantStatus.missingLicense,
                    }),
                  ];
                } else {
                  return [
                    FuncSpecInviteActions.invitationFailed({
                      invitedStatus: APIFunctionalSpecifications.IInvitedParticipantStatus.serverError,
                    }),
                  ];
                }
              })
            );
        },
        onError: (action, error: HttpErrorResponse | null) =>
          FuncSpecInviteActions.inviteParticipantError({
            error: error?.message,
          }),
      })
    )
  );

  deleteParticipant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecInviteActions.deleteParticipant),
      withLatestFrom(this.store.pipe(select(getFuncSpecId))),
      fetch({
        run: (action, funcSpecId) => {
          if (!funcSpecId) throw new Error('Argument cannot be null');

          return this.inviteService
            .deleteParticipantRole(
              funcSpecId,
              action.participant.userId,
              action.participant.roleId
            )
            .pipe(
              map(() =>
                FuncSpecInviteActions.deleteParticipantSuccess({
                  email: action.participant.email,
                })
              )
            );
        },
        onError: (action, error: HttpErrorResponse | null) =>
          FuncSpecInviteActions.deleteParticipantFailure({
            error: error?.message,
          }),
      })
    )
  );

  editParticipantRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecInviteActions.editParticipantRole),
      withLatestFrom(this.store.pipe(select(getFuncSpecId))),
      fetch({
        run: (action, funcSpecId) => {
          if (!funcSpecId) throw new Error('Argument cannot be null');

          return this.inviteService
            .editParticipantRole({
              id: funcSpecId,
              userId: action.userId,
              roleId: action.roleId,
            })
            .pipe(
              map((participant) =>
                FuncSpecInviteActions.editParticipantRoleSuccess({
                  participant,
                })
              )
            );
        },
        onError: (action, error: HttpErrorResponse | null) =>
          FuncSpecInviteActions.editParticipantRoleFailure({
            error: error?.message,
          }),
      })
    )
  );

  showInvitedSuccessMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecInviteActions.inviteParticipantSuccess),
      map(() => showSuccessToastMessage())
    )
  );

  showSuccessMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        FuncSpecInviteActions.editParticipantRoleSuccess,
        FuncSpecInviteActions.deleteParticipantSuccess
      ),
      map(() => showSuccessToastMessage())
    )
  );

  loadRoleDefinitions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecInviteActions.loadRoleDefinitions),
      fetch({
        run: () =>
          this.inviteService.getRoleDefinitions().pipe(
            map((roleDefinitions) =>
              FuncSpecInviteActions.loadRoleDefinitionsSuccess({
                roleDefinitions,
              })
            )
          ),
        onError: (action, error: HttpErrorResponse | null) => {
          console.error('Error', error);
          return FuncSpecInviteActions.loadRoleDefinitionsFailure({
            error: error?.message,
          });
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private inviteService: FunctionSpecificationInviteService,
    private store: Store<fromFuncSpecInvite.FuncSpecInvitePartialState>
  ) {}
}
