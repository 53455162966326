import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromFuncSpecStructureProperties from './func-spec-structure-properties.reducer';
import * as FuncSpecStructurePropertiesSelectors from './func-spec-structure-properties.selectors';
import { APIStructureProperties } from '@ama-studio/shared';

@Injectable()
export class FuncSpecStructurePropertiesFacade {
  loaded$ = this.store.pipe(
    select(
      FuncSpecStructurePropertiesSelectors.getFuncSpecStructurePropertiesLoaded
    )
  );

  structureProperties$ = this.store.pipe(
    select(
      FuncSpecStructurePropertiesSelectors.getAllFuncSpecStructureProperties
    )
  );

  activeStructureProperties$ = this.store.pipe(
    select(FuncSpecStructurePropertiesSelectors.getActiveStructureProperties)
  );

  expandedId$ = this.store.pipe(
    select(FuncSpecStructurePropertiesSelectors.getExpandedId)
  );

  activeStructurePropertiesCount$ = this.store.pipe(
    select(
      FuncSpecStructurePropertiesSelectors.getActiveStructurePropertiesCount
    )
  );

  inactiveStructureProperties$ = this.store.pipe(
    select(FuncSpecStructurePropertiesSelectors.getInactiveStructureProperties)
  );

  inactiveStructurePropertiesCount$ = this.store.pipe(
    select(
      FuncSpecStructurePropertiesSelectors.getInactiveStructurePropertiesCount
    )
  );

  selectedId$ = this.store.pipe(
    select(FuncSpecStructurePropertiesSelectors.getSelectedId)
  );

  selectedFuncSpecStructureProperties$ = this.store.pipe(
    select(FuncSpecStructurePropertiesSelectors.getSelected)
  );

  inheritedRequirementId$ = this.store.pipe(
    select(FuncSpecStructurePropertiesSelectors.getInheritedRequirementId)
  );

  color$ = this.store.pipe(
    select(FuncSpecStructurePropertiesSelectors.getColor)
  );

  addedId$ = this.store.pipe(
    select(FuncSpecStructurePropertiesSelectors.getAddedId)
  );

  propertyIsExpanded$ = (property: APIStructureProperties.IPropertyViewModel) =>
    this.store.pipe(
      select(
        FuncSpecStructurePropertiesSelectors.getPropertyIsExpanded(property)
      )
    );

  propertySectionHighlighted$ = (propertyId: string) =>
    this.store.pipe(
      select(
        FuncSpecStructurePropertiesSelectors.getPropertySectionHighlighted(
          propertyId
        )
      )
    );

  property$ = (id: string) =>
    this.store.pipe(
      select(FuncSpecStructurePropertiesSelectors.getProperty(id))
    );

  constructor(
    private store: Store<fromFuncSpecStructureProperties.FuncSpecStructurePropertiesPartialState>
  ) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }
}
