import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { ChaptersFacade, loadChapters } from '..';

@Injectable()
export class ChaptersResolver implements Resolve<boolean> {
  constructor(private facade: ChaptersFacade, private store: Store) {}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    const funcSpecId =
      route.paramMap.get('id') ?? route.paramMap.get('funcSpecId');

    if (!funcSpecId) throw new Error('Argument should not be null');

    this.facade.dispatch(
      loadChapters({
        funcSpecId: funcSpecId,
      })
    );

    return this.waitForLoading().pipe(switchMap(() => this.waitForLoading()));
  }

  waitForLoading(): Observable<boolean> {
    return this.facade.loaded$.pipe(
      filter((loaded) => loaded),
      take(1)
    );
  }
}
