import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from './avatar-component/avatar.component';
import { UserInitialsModule } from '../../pipes';

@NgModule({
  declarations: [AvatarComponent],
  imports: [CommonModule, UserInitialsModule],
  exports: [AvatarComponent],
})
export class AvatarModule {}
