import { APITexts } from '@ama-studio/shared';
import { normalize, schema } from 'normalizr';

function normalizeNestedTextItems(
  nestedStructures: APITexts.ITextItemViewModel
) {
  const item = new schema.Entity<APITexts.ITextItemViewModel>(
    'items',
    undefined,
    { idAttribute: 'functionalSpecificationTextId' }
  );
  item.define({
    children: [item],
  });

  return normalize(nestedStructures, item);
}

export function normalizeTextItems(rootTextModel: APITexts.ITextItemViewModel) {
  const normalized = normalizeNestedTextItems(rootTextModel);

  // return an object that can be directly put on the state.
  return {
    textItems: normalized.entities.items,
    textRootId: normalized.result as string,
  };
}

export function normalizeUpdatedTextItem(
  textModel: APITexts.ITextItemViewModel
) {
  const normalizedItem = normalizeNestedTextItems(textModel);
  const itemId = normalizedItem.result;
  const newItem = normalizedItem.entities.items[
    itemId
  ] as APITexts.ITextItemViewModel;

  const updatedTextItem = {
    [itemId]: newItem,
  };

  return updatedTextItem;
}
