import { Injectable } from '@angular/core';
import {
  EnvironmentConfig,
  BaseHttpService,
  APIComments,
} from '@ama-studio/shared';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FuncSpecCommentsService extends BaseHttpService {
  constructor(http: HttpClient, envConfig: EnvironmentConfig) {
    super(http, envConfig);
  }

  private apiUrl = `${this.envConfig.baseApiUrl}/functionalspecifications`;

  private getAllCommentsUrl(funcSpecId: string, chapterId: string) {
    return `${this.apiUrl}/${funcSpecId}/chapters/${chapterId}/comments`;
  }

  private getCommentsForItemUrl(funcSpecId: string, itemId: string) {
    return `${this.apiUrl}/${funcSpecId}/comments/${itemId}`;
  }

  private getCreateCommentUrl(funcSpecId: string, chapterId: string) {
    return `${this.apiUrl}/${funcSpecId}/chapters/${chapterId}/comments`;
  }

  private getDeleteCommentUrl(funcSpecId: string, commentId: string) {
    return `${this.apiUrl}/${funcSpecId}/comments/${commentId}`;
  }

  public getAllComments(funcSpecId: string, chapterId: string) {
    return this.http.get<APIComments.ICommentViewModel[]>(
      this.getAllCommentsUrl(funcSpecId, chapterId),
      this.headers
    );
  }

  public getCommentsForItem(
    funcSpecId: string,
    itemId: string,
    subItemId?: string
  ) {
    return this.http.get<APIComments.ICommentViewModel[]>(
      this.getCommentsForItemUrl(funcSpecId, itemId),
      {
        params: subItemId ? { subItemId: subItemId } : null,
        ...this.headers,
      }
    );
  }

  public createComment(command: APIComments.ICreateCommentCommand) {
    return this.http.post<APIComments.ICommentViewModel>(
      this.getCreateCommentUrl(command.id, command.chapterId),
      { ...command },
      this.headers
    );
  }

  public deleteComment(funcSpecId: string, commentId: string) {
    return this.http.delete(
      this.getDeleteCommentUrl(funcSpecId, commentId),
      this.headers
    );
  }
}
