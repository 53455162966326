import { createReducer, on, Action } from '@ngrx/store';

import * as GlobalStateActions from './global-state.actions';

export const GLOBALSTATE_FEATURE_KEY = 'globalState';

/* eslint-disable @typescript-eslint/naming-convention */
export enum Section {
  Other = 0,
  FuncSpec = 1,
  Overview,
  Content,
}
/* eslint-enable @typescript-eslint/naming-convention */

export interface State {
  mainTitle: string;
  activeSection: Section;
  sideMenuCollapsed: boolean;
}

export interface GlobalStatePartialState {
  readonly [GLOBALSTATE_FEATURE_KEY]: State;
}

export const initialState: State = {
  // set initial required properties
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  mainTitle: null,
  activeSection: Section.Other,
  sideMenuCollapsed: false,
};

const globalStateReducer = createReducer(
  initialState,
  on(GlobalStateActions.setMainTitle, (state, { mainTitle }) => ({
    ...state,
    mainTitle,
  })),
  on(GlobalStateActions.setActiveSection, (state, { section }) => ({
    ...state,
    activeSection: section,
  })),
  on(GlobalStateActions.clearActiveSection, (state) => ({
    ...state,
    activeSection: Section.Other,
  })),
  on(GlobalStateActions.onSideMenuCollapse, (state, { sideMenuCollapsed }) => ({
    ...state,
    sideMenuCollapsed: !sideMenuCollapsed,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return globalStateReducer(state, action);
}
