import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FUNCSPECSTRUCTUREPROPERTIES_FEATURE_KEY,
  State,
  FuncSpecStructurePropertiesPartialState,
  funcSpecStructurePropertiesAdapter,
} from './func-spec-structure-properties.reducer';
import { APIStructureProperties } from '@ama-studio/shared';

// Lookup the 'FuncSpecStructureProperties' feature state managed by NgRx
export const getFuncSpecStructurePropertiesState = createFeatureSelector<
  State
>(FUNCSPECSTRUCTUREPROPERTIES_FEATURE_KEY);

const { selectAll, selectEntities } =
  funcSpecStructurePropertiesAdapter.getSelectors();

export const getFuncSpecStructurePropertiesLoaded = createSelector(
  getFuncSpecStructurePropertiesState,
  (state: State) => state.loaded
);

export const getFuncSpecStructurePropertiesError = createSelector(
  getFuncSpecStructurePropertiesState,
  (state: State) => state.error
);

export const getAllFuncSpecStructureProperties = createSelector(
  getFuncSpecStructurePropertiesState,
  (state: State) => selectAll(state)
);

export const getActiveStructureProperties = createSelector(
  getAllFuncSpecStructureProperties,
  (state) => state.filter((x) => x.isActive)
);

export const getActiveStructurePropertiesCount = createSelector(
  getAllFuncSpecStructureProperties,
  (state) => state.filter((x) => x.isActive).length
);

export const getInactiveStructureProperties = createSelector(
  getAllFuncSpecStructureProperties,
  (state) => state.filter((x) => !x.isActive)
);

export const getInactiveStructurePropertiesCount = createSelector(
  getAllFuncSpecStructureProperties,
  (state) => state.filter((x) => !x.isActive).length
);

export const getFuncSpecStructurePropertiesEntities = createSelector(
  getFuncSpecStructurePropertiesState,
  (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getFuncSpecStructurePropertiesState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getFuncSpecStructurePropertiesEntities,
  getSelectedId,
  (entities, selectedId) => selectedId && entities[selectedId]
);

export const getExpandedId = createSelector(
  getFuncSpecStructurePropertiesState,
  (state: State) => state.expandedId
);

export const getPropertyIsExpanded = (
  property: APIStructureProperties.IPropertyViewModel
) =>
  createSelector(
    getExpandedId,
    (expandedId: string) => expandedId === property.classificationId
  );

export const getPropertySectionHighlighted = (propertyId: string) =>
  createSelector(
    getFuncSpecStructurePropertiesState,
    (state: State) => state.highlightedPropertySection?.[propertyId]
  );

export const getProperty = (id: string) =>
  createSelector(
    getFuncSpecStructurePropertiesState,
    (state: State) => state.entities[id]
  );

export const getPropertiesId = createSelector(
  getFuncSpecStructurePropertiesState,
  (state: State) => state.propertiesId
);

export const getInheritedRequirementId = createSelector(
  getFuncSpecStructurePropertiesState,
  (state: State) => state.inheritedRequirementId
);

export const getColor = createSelector(
  getFuncSpecStructurePropertiesState,
  (state: State) => state.color
);

export const getAddedId = createSelector(
  getFuncSpecStructurePropertiesState,
  (state: State) => state.addedId
);
