import { Injectable } from '@angular/core';
import {
  APIChapter,
  APITexts,
  EnvironmentConfig,
  BaseHttpService,
} from '@ama-studio/shared';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FuncSpecTextService extends BaseHttpService {
  constructor(http: HttpClient, envConfig: EnvironmentConfig) {
    super(http, envConfig);
  }

  private functionalSpecificationsUrl = `${this.envConfig.baseApiUrl}/functionalspecifications`;
  private requirementTemplateUrl = `${this.envConfig.baseApiUrl}/requirementtemplates`;

  private getGuidanceUrl(requirementTemplateId: string, chapterId: string) {
    return `${this.requirementTemplateUrl}/${requirementTemplateId}/texts/${chapterId}`;
  }

  private getListTextsUrl(
    functionalSpecificationTextId: string,
    requirementTemplateTextItemId: string
  ) {
    return `${this.functionalSpecificationsUrl}/${functionalSpecificationTextId}/texts/${requirementTemplateTextItemId}`;
  }

  private getTextItemContentUrl(funcSpecId: string, itemId: string) {
    return `${this.functionalSpecificationsUrl}/${funcSpecId}/texts/${itemId}/content`;
  }

  private getUpdateTextUrlV2(funcSpecId: string, itemId: string) {
    return `${this.functionalSpecificationsUrl}/${funcSpecId}/texts/${itemId}/content/v2`;
  }

  public getGuidanceText(
    requirementTemplateId: string,
    chapterId: string
  ): Observable<HttpResponse<APIChapter.IGuidanceWindowTextItemViewModel>> {
    return this.http.get<APIChapter.IGuidanceWindowTextItemViewModel>(
      this.getGuidanceUrl(requirementTemplateId, chapterId),
      {
        headers: this.headers.headers,
        observe: 'response',
      }
    );
  }

  public getTexts(
    functionalSpecificationTextId: string,
    requirementTemplateTextItemId: string
  ) {
    return this.http.get<APITexts.IChapterListViewModel>(
      this.getListTextsUrl(
        functionalSpecificationTextId,
        requirementTemplateTextItemId
      ),
      this.headers
    );
  }

  public getTextItemContent(
    functionalSpecificationId: string,
    functionalSpecificationTextId: string
  ): Observable<APITexts.ITextItemContentViewModel> {
    return this.http.get<APITexts.ITextItemContentViewModel>(
      this.getTextItemContentUrl(
        functionalSpecificationId,
        functionalSpecificationTextId
      ),
      this.headers
    );
  }

  public updateText(
    updateTextCommand: APITexts.IPatchTextCommand
  ): Observable<APITexts.ITextItemViewModel> {
    return this.http.patch<APITexts.ITextItemViewModel>(
      this.getUpdateTextUrlV2(
        updateTextCommand.id,
        updateTextCommand.functionalSpecificationTextId
      ),
      { ...updateTextCommand },
      this.headers
    );
  }
}
