import { Inject, Injectable } from '@angular/core';

export const toastCacheKeys = {
  funcSpecLocked: 'toast-message-fs-locked',
};

@Injectable({ providedIn: 'root' })
export class ToastNotificationsCacheService {
  constructor(@Inject('Window') private window: Window) {}

  hasFuncSpecLockedMessageBeenViewed(funcSpecId: string): boolean {
    const cachedItem = this.window.sessionStorage.getItem(
      toastCacheKeys.funcSpecLocked
    );
    if (cachedItem === null) {
      this.window.sessionStorage.setItem(
        toastCacheKeys.funcSpecLocked,
        JSON.stringify([funcSpecId])
      );
      return false;
    }

    const lockedFuncSpecs = <Array<string>>JSON.parse(cachedItem);
    const cacheExists = lockedFuncSpecs.some((fsId) => fsId === funcSpecId);
    if (!cacheExists) {
      this.window.sessionStorage.setItem(
        toastCacheKeys.funcSpecLocked,
        JSON.stringify([...lockedFuncSpecs, funcSpecId])
      );
    }

    return cacheExists;
  }
}
