/*
This file is copied from AMA.Web 2020-10-01
at commit #148bff11bb6fb9b79488099da29e9ebfc11ac64c
*/

import { Subscription, of } from 'rxjs';
import { Router } from '@angular/router';
import { AuthFacade } from '@ama-studio/login';
import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  HostBinding,
} from '@angular/core';
import {
  EnvironmentConfig,
} from '@ama-studio/shared';
import { switchMap, filter, take } from 'rxjs/operators';

@Component({
  selector: 'ama-studio-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class StartComponent implements OnInit, OnDestroy {
  public showLoginLink = false;
  public statusMsg: string;
  private subscriptions: Subscription[] = [];

  @HostBinding('class') classes = 'd-flex flex-fill';

  constructor(
    private authFacade: AuthFacade,
    private router: Router,
    private cdr: ChangeDetectorRef,    
    private envConfig: EnvironmentConfig
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.authFacade.isLoggedIn$.subscribe((isLoggedIn) => {
        if (isLoggedIn) {
          this.showLoginLink = false;
          window.location.href = this.envConfig.studioUrl;
        }
      })
    );
    this.subscriptions.push(
      this.authFacade.isPendingLogin$
        .pipe(
          take(1),
          switchMap((isPendingLogin) => {
            if (!isPendingLogin) {
              this.statusMsg = 'Loggar in, var god vänta...';
              this.cdr.detectChanges();
              return this.authFacade.loginInBackground(false);
            }
            return of(null);
          }),
          filter((isPendingLogin) => isPendingLogin !== null)
        )
        .subscribe((loginSuccess) => {
          if (!loginSuccess) {
            this.statusMsg =
              'Kunde inte logga in automatiskt, klicka nedan för att logga in manuellt.';
            this.showLoginLink = true;
            this.cdr.detectChanges();
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
