import { APIFunctionalSpecifications } from '@ama-studio/shared';
import { createAction, props } from '@ngrx/store';

export const logIn = createAction(
  '[Auth] Log In',
  props<{
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    tokenType: string;
    accessToken: string;
  }>()
);

export const logOut = createAction('[Auth] Log Out');

export const setAccessToken = createAction(
  '[Auth] Set Access Token',
  props<{
    accessToken: string;
  }>()
);

export const setIsPending = createAction(
  '[Auth] Set Is Pending Login',
  props<{
    isPending: boolean;
  }>()
);

export const checkHasValidLicense = createAction(
  '[Auth] Check Has Valid License'
);

export const setHasValidLicense = createAction(
  '[Auth] Set Has Valid License',
  props<{
    hasValidLicense: boolean;
  }>()
);

export const getCurrentParticipantRole = createAction(
  '[Auth] Get Current Participant Role'
);

export const getCurrentParticipantRoleSuccess = createAction(
  '[Auth] Get Current Participant Role Success',
  props<{
    role: APIFunctionalSpecifications.IUserRoleImportanceDuplicate;
  }>()
);

export const getCurrentParticipantRoleFailure = createAction(
  '[Auth] Get Current Participant Role Failure',
  props<{ error: string | null }>()
);
