import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastNotificationsCacheService } from '../toast-notifications-cache.service';
import * as ToastActions from './toast.actions';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class ToastEffects {
  showFuncSpecLockedToastMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToastActions.showFuncSpecLockedToastMessage),
      filter(
        ({ funcSpecId }) =>
          this.toastNotificationsCacheService.hasFuncSpecLockedMessageBeenViewed(
            funcSpecId
          ) === false
      ),
      map(() =>
        ToastActions.showToastMessage({
          message: {
            message:
              'Det går bra att läsa funktionsbeskrivningen, men den är låst för redigering.',
            type: 'locked',
          },
        })
      )
    )
  );

  showSuccessToastMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToastActions.showSuccessToastMessage),      
      map(() =>
        ToastActions.showToastMessage({
          message: {
            message:
              'Det lyckades',
            type: 'success',
          },
        })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private toastNotificationsCacheService: ToastNotificationsCacheService
  ) {}
}
