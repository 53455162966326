import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FUNCTIONALSPECIFICATIONDETAIL_INVITE_KEY,
  State,
  inviteAdapter,
} from './func-spec-invite.reducer';

export const getFuncSpecInviteState = createFeatureSelector<
  State
>(FUNCTIONALSPECIFICATIONDETAIL_INVITE_KEY);

const { selectAll } = inviteAdapter.getSelectors();

export const getFuncSpecId = createSelector(
  getFuncSpecInviteState,
  (state: State) => state.funcSpecId
);

export const getFuncSpecInviteLoaded = createSelector(
  getFuncSpecInviteState,
  (state: State) => state.loaded
);

export const getFuncSpecInviteError = createSelector(
  getFuncSpecInviteState,
  (state: State) => state.error
);

export const getRoles = createSelector(
  getFuncSpecInviteState,
  (state: State) => state.accessibleRoles
);

export const getParticipants = createSelector(
  getFuncSpecInviteState,
  (state: State) => selectAll(state)
);

export const getInviteFormActivity = createSelector(
  getFuncSpecInviteState,
  (state: State) => state.inviteFormActivity
);

export const getInvitedStatus = createSelector(
  getFuncSpecInviteState,
  (state: State) => state.invitedStatus
);

export const getRoleDefinitions = createSelector(
  getFuncSpecInviteState,
  (state: State) => state.roleDefinitions
);

export const getEditingParticipant = createSelector(
  getFuncSpecInviteState,
  (state: State) => state.startEditingParticipantRole
);
