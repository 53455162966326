import { AnalyticsService, APINotifications } from '@ama-studio/shared';
import { Component, Input } from '@angular/core';
import { FuncSpecNotificationsFacade } from '../+state/func-spec-notifications.facade';
import { selectNotification } from '../+state/func-spec-notifications.actions';

@Component({
  selector: 'ama-studio-func-spec-notification-item',
  templateUrl: './func-spec-notification-item.component.html',
  styleUrls: ['./func-spec-notification-item.component.scss']
})
export class FuncSpecNotificationItemComponent {

  @Input() notification!: APINotifications.INotificationViewModel;

  constructor(
    private notificationsFacade: FuncSpecNotificationsFacade,
    private analyticsService: AnalyticsService
  ) {}

  selectNotification() {
    this.notificationsFacade.dispatch(
      selectNotification({ notification: this.notification })
    )
    this.analyticsService.clickNotification();
  }

}
