import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials',
})
export class InitialsPipe implements PipeTransform {
  transform(value: string): string {
    let initials = '';
    const items = value.split(' ');
    items.forEach((i: string) => {
      initials += i[0]?.toUpperCase()
    });
    return initials;
  }
}
