import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromFuncSpecText from './func-spec-text.reducer';
import * as FuncSpecTextSelectors from './func-spec-text.selectors';

@Injectable()
export class FuncSpecTextFacade {
  loaded$ = this.store.pipe(
    select(FuncSpecTextSelectors.getFuncSpecTextLoaded)
  );

  updateTextContentLoading$ = this.store.pipe(
    select(FuncSpecTextSelectors.getUpdateTextContentLoading)
  );

  guidanceText$ = this.store.pipe(
    select(FuncSpecTextSelectors.getGuidanceText)
  );

  selectedRequirementTemplateTextItemId$ = this.store.pipe(
    select(FuncSpecTextSelectors.getSelectedRequirementTemplateTextItemId)
  );

  chapterListModel$ = this.store.pipe(
    select(FuncSpecTextSelectors.getChapterListModel)
  );

  selectedTextItemId$ = this.store.pipe(
    select(FuncSpecTextSelectors.getSelectedTextItemId)
  );

  selectedTextItem$ = this.store.pipe(
    select(FuncSpecTextSelectors.getSelectedTextItem)
  );

  textRootId$ = this.store.pipe(select(FuncSpecTextSelectors.getTextRootId));

  textRootItems$ = this.store.pipe(
    select(FuncSpecTextSelectors.getTextRootItems)
  );

  textItemContentLoaded$ = this.store.pipe(
    select(FuncSpecTextSelectors.getTextItemContentLoaded)
  );

  getTextItem$ = (id: string) =>
    this.store.pipe(select(FuncSpecTextSelectors.getTextItem(id)));

  getTextItemContent$ = (id: string) =>
    this.store.pipe(select(FuncSpecTextSelectors.getTextItemContent(id)));

  textItemIsExpanded$ = (structureItemId: string) =>
    this.store.pipe(
      select(FuncSpecTextSelectors.getTextItemIsExpanded(structureItemId))
    );

  textItemIsSelected$ = (textItemId: string) =>
    this.store.pipe(
      select(FuncSpecTextSelectors.getTextItemIsSelected(textItemId))
    );

  getHighlightedTextItemSection$ = (textItemId: string) =>
    this.store.pipe(
      select(FuncSpecTextSelectors.getHighlightedTextItemSection(textItemId))
    );

  constructor(
    private store: Store<fromFuncSpecText.FuncSpecTextPartialState>
  ) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }
}
