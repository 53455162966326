import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch, optimisticUpdate } from '@nrwl/angular';

import * as fromFuncSpecOverview from './func-spec-overview.reducer';
import * as FuncSpecOverviewActions from './func-spec-overview.actions';
import * as funcSpecCore from '@ama-studio/func-spec-core-state';

import {
  map,
  mergeMap,
  withLatestFrom,
  switchMap,
} from 'rxjs/operators';
import { MarkAsTouchedAction, SetValueAction } from 'ngrx-forms';
import { Store, select } from '@ngrx/store';
import { FuncSpecDetailForm } from './func-spec-overview.reducer';
import {
  getFormState,
  getFunctionalSpecification,
  getFuncSpecOverviewState,
} from './func-spec-overview.selectors';
import { FuncSpecOverviewService } from '../func-spec-overview.service';
import * as fromInvite from '@ama-studio/func-spec-invite';
import { HttpErrorResponse } from '@angular/common/http';
import {
  getFunctionalSpecificationId,
  loadFuncSpecSuccess,
} from '@ama-studio/func-spec-core-state';
import { selectChapter } from '@ama-studio/func-spec-chapter-state';
import { ChapterType, NonChapter } from '@ama-studio/shared';
import {
  showSuccessToastMessage,
} from '@ama-studio/toast-notifications';
import { loadRoleDefinitions } from '@ama-studio/func-spec-invite';
@Injectable()
export class FuncSpecOverviewEffects {
  onSelectChapter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectChapter),
      map(() => FuncSpecOverviewActions.loadFuncSpecOverviewParticipants())
    )
  );

  loadFuncSpecOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecOverviewActions.loadFuncSpecOverview),
      fetch({
        run: (action) =>
          this.funcSpecOverviewService
            .getFuncSpecOverview(action.funcSpecId)
            .pipe(
              switchMap((funcSpecOverview) => [
                FuncSpecOverviewActions.loadFuncSpecOverviewSuccess({
                  functionalSpecification: {
                    ...funcSpecOverview,
                  },
                }),
                funcSpecCore.loadProject({
                  projectId: funcSpecOverview.projectId,
                }),
                selectChapter({
                  chapterId: '',
                  chapterNumber: NonChapter.overview,
                  funcSpecId: action.funcSpecId,
                  chapterType: ChapterType.Overview,
                }),
                fromInvite.setFuncSpecId({ funcSpecId: action.funcSpecId }),
                funcSpecCore.setFuncSpec({
                  functionalSpecificationId: action.funcSpecId,
                }),
                funcSpecCore.setChapter({
                  chapterId: '',
                  chapterNumber: NonChapter.overview,
                  chapterType: ChapterType.Overview,
                }),
                loadRoleDefinitions()
              ])
            ),
        onError: (action, error: HttpErrorResponse | null) => {
          console.error('Error', error);
          return FuncSpecOverviewActions.loadFuncSpecOverviewFailure({
            error: error?.message,
          });
        },
      })
    )
  );

  loadFuncSpecOverviewParticipants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecOverviewActions.loadFuncSpecOverviewParticipants),
      withLatestFrom(this.coreStore.pipe(select(getFunctionalSpecificationId))),
      fetch({
        run: (_, funcSpecId) =>
          this.funcSpecOverviewService.getParticipants(funcSpecId).pipe(
            map((participants) =>
              FuncSpecOverviewActions.loadFuncSpecOverviewParticipantsSuccess({
                participants,
              })
            )
          ),
        onError: (_, error: HttpErrorResponse | null) => {
          console.error('Error', error);
          return FuncSpecOverviewActions.loadFuncSpecOverviewParticipantsFailure(
            {
              error: error?.message,
            }
          );
        },
      })
    )
  );

  loadFuncSpec$ = createEffect(() =>
    this.actions$.pipe(
      ofType(funcSpecCore.loadFuncSpecSuccess),
      switchMap((action) => [
        FuncSpecOverviewActions.loadFuncSpecOverviewSuccess({
          functionalSpecification: action.funcSpec,
        })
      ])
    )
  );

  loadProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecOverviewActions.loadProject),
      fetch({
        run: (action) =>
          this.funcSpecOverviewService.getProject(action.projectId).pipe(
            switchMap((projectModel) => [
              FuncSpecOverviewActions.loadProjectSuccess({
                projectModel,
              }),
              funcSpecCore.setProjectId({
                projectId: projectModel.projectId,
              }),
            ])
          ),
        onError: (action, error: HttpErrorResponse | null) => {
          console.error('Error', error);
          return FuncSpecOverviewActions.loadProjectFailure({
            error: error?.message,
          });
        },
      })
    )
  );

  /**
   * Updates the form state with the latest functional specification
   */
  syncFormState = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecOverviewActions.syncFormState),
      withLatestFrom(this.store.pipe(select(getFunctionalSpecification))),
      mergeMap(([, funcSpec]) => [
        new SetValueAction(fromFuncSpecOverview.FUNC_SPEC_DETAIL_FORM_ID, <
          FuncSpecDetailForm
        >{
          name: funcSpec.name,
          description: funcSpec.description,
          contactPerson: funcSpec.contactPerson || {
            email: '',
            name: '',
            phone: '',
          },
        }),
      ])
    )
  );

  syncFormFieldValue = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecOverviewActions.syncFormField),
      withLatestFrom(
        this.store.pipe(select(getFunctionalSpecification)),
        this.store.pipe(select(getFormState))
      ),
      mergeMap(([action, funcSpec, formState]) => {
        let newValue: unknown;
        switch (action.field) {
          case formState.controls.description.id:
            newValue = funcSpec.description;
            break;
          case formState.controls.name.id:
            newValue = action.value ?? funcSpec?.name;
            break;
          case formState.controls.contactPerson.id:
            newValue = {
              name: funcSpec.contactPerson?.name,
              email: funcSpec.contactPerson?.email,
              phone: funcSpec.contactPerson?.phone,
            };
            break;
        }
        return [
          new SetValueAction(action.field, newValue),
          new MarkAsTouchedAction(action.field),
        ];
      })
    )
  );

  updateOverviewValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecOverviewActions.updateOverviewValue),
      withLatestFrom(this.store.pipe(select(getFuncSpecOverviewState))),
      optimisticUpdate({
        run: (action, state) => {
          if (!state) throw new Error('Argument should not be null');

          const field = action.field;
          const newValues = {
            id: state?.functionalSpecification.id,
            name: state?.functionalSpecification.name,
            description: state?.functionalSpecification.description,
            contactPerson: state?.functionalSpecification.contactPerson,
          };

          switch (field) {
            case state?.formState.controls.description.id:
              newValues.description =
                state?.formState.controls.description.value;
              break;
            case state?.formState.controls.name.id:
              newValues.name = state?.formState.controls.name.value;
              break;
            case state?.formState.controls.contactPerson.id:
              newValues.contactPerson = {
                name:
                  state?.formState.controls.contactPerson.value.name ?? null,
                email:
                  state?.formState.controls.contactPerson.value.email ?? null,
                phone:
                  state?.formState.controls.contactPerson.value.phone ?? null,
              };
              break;
          }

          return this.funcSpecOverviewService
            .updateFunctionSpecificationDetail(newValues)
            .pipe(
              switchMap((functionalSpecification) => [
                FuncSpecOverviewActions.updateOverviewValueSuccess({
                  field: action.field,
                  functionalSpecification,
                }),
                loadFuncSpecSuccess({
                  funcSpec: functionalSpecification,
                }),
              ])
            );
        },
        undoAction: (action, error) => {
          console.error('Error', error);
          return FuncSpecOverviewActions.updateOverviewValueFailure({
            error: error?.message,
          });
        },
      })
    )
  );

  showOverviewSuccessMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecOverviewActions.updateOverviewValueSuccess),
      map(() => showSuccessToastMessage())
    )
  );

  addParticipant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromInvite.addParticipantToState),
      map((action) =>
        FuncSpecOverviewActions.addParticipant({
          participant: action.participant,
        })
      )
    )
  );

  editParticipantRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromInvite.editParticipantRoleSuccess),
      map((action) =>
        FuncSpecOverviewActions.editParticipant({
          participant: action.participant,
        })
      )
    )
  );

  deleteParticipant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromInvite.deleteParticipantSuccess),
      map((action) =>
        FuncSpecOverviewActions.deleteParticipant({
          email: action.email,
        })
      )
    )
  );

  deleteFuncSpec$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FuncSpecOverviewActions.deleteFuncSpec),
      withLatestFrom(this.store.pipe(select(getFunctionalSpecification))),
      optimisticUpdate({
        run: (action, funcSpec) =>
          funcSpec &&
          this.funcSpecOverviewService
            .delete({
              id: funcSpec.id,
            })
            .pipe(
              switchMap((functionalSpecification) => [
                FuncSpecOverviewActions.deleteFuncSpecSuccess({
                  functionalSpecification,
                }),
              ])
            ),
        undoAction: (action, error) => {
          console.error('Error', error);
          return FuncSpecOverviewActions.deleteFuncSpecFailure({
            error: error?.message,
          });
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private funcSpecOverviewService: FuncSpecOverviewService,
    private store: Store<fromFuncSpecOverview.FuncSpecOverviewPartialState>,
    private coreStore: Store<funcSpecCore.FuncSpecCorePartialState>
  ) {}
}
