import { LoginService } from './../services/login.service';
import * as authQuery from './auth.selectors';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { State as AuthState } from './auth.reducer';

@Injectable({ providedIn: 'root' })
export class AuthFacade {
  public isPendingLogin$ = this.store.pipe(select(authQuery.getIsPending));
  public isLoggedIn$ = this.store.pipe(select(authQuery.getIsLoggedIn));
  public accessToken$ = this.store.pipe(select(authQuery.getAccessToken));
  public email$ = this.store.pipe(select(authQuery.getEmail));
  public fullName$ = this.store.pipe(select(authQuery.getFullName));
  public initials$ = this.store.pipe(select(authQuery.getInitials));
  public hasValidLicense$ = this.store.pipe(
    select(authQuery.getHasValidLicense)
  );
  public userId$ = this.store.pipe(select(authQuery.getUserId));
  public authState$ = this.store.pipe(select(authQuery.selectAuthState));
  public role$ = this.store.pipe(select(authQuery.getRole));

  constructor(
    private store: Store<AuthState>,
    private loginService: LoginService
  ) {}

  public login(requestedUrl: string, params?: { [key: string]: string }) {
    return this.loginService.login(requestedUrl, params);
  }
  public loginInBackground(sendToLogin: boolean) {
    return this.loginService.loginInBackground(sendToLogin);
  }
}
