import { CdkAccordionItem } from '@angular/cdk/accordion';
import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'sbui-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  exportAs: 'accordion',
})
export class AccordionComponent implements AfterViewInit, OnDestroy {
  @Output() expandedChange = new EventEmitter<boolean>();

  @ContentChild('accordionItemClick', { read: ElementRef })
  accordionItemClick!: ElementRef<HTMLElement>;

  @ViewChild('accordionItem') accordionItem!: CdkAccordionItem;

  constructor() {}

  onExpandedChange(expanded: boolean) {
    this.expandedChange.emit(expanded);
  }

  toggle() {
    this.accordionItem.toggle();
  }

  close() {
    this.accordionItem.close();
  }

  ngAfterViewInit(): void {
    this.accordionItemClick.nativeElement.addEventListener(
      'click',
      this.toggle.bind(this)
    );
  }

  ngOnDestroy(): void {
    this.accordionItemClick.nativeElement.removeEventListener(
      'click',
      this.toggle.bind(this)
    );
  }
}
