import { optimisticUpdate } from '@nrwl/angular';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { map, tap } from 'rxjs/operators';

import * as FeedbackActions from './feedback.actions';
import { FeedbackService } from './feedback.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class FeedbackEffects {
  createFeedback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedbackActions.createFeedback),
      optimisticUpdate({
        run: (action) =>
          this.feedbackService.createFeedback(action.feedback).pipe(
            tap(() => console.log(`action: `, action.feedback)),
            map(() =>
              FeedbackActions.createFeedbackSuccess({
                feedback: action.feedback,
              })
            )
          ),
        undoAction: (action, error: HttpErrorResponse) => {
          console.error(error);
          return FeedbackActions.createFeedbackFailure({
            error: error?.message,
          });
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private feedbackService: FeedbackService
  ) {}
}
