import { Injectable } from '@angular/core';

import { Action, select, Store } from '@ngrx/store';

import * as ToastSelectors from './toast.selectors';

@Injectable()
export class ToastFacade {
  message$ = this.store.pipe(select(ToastSelectors.getMessage));

  errorMessage$ = this.store.pipe(select(ToastSelectors.getErrorMessage));

  constructor(private store: Store) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }
}
